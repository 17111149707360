import Vue from 'vue';
import moment from 'moment';

Vue.filter('dateWithTime', value => {
	moment.locale('en-gb');

	const date = moment(value).format('llll');
	return date;
});

Vue.filter('dateBus', value => {
	moment.locale('en-gb');

	const date = moment.utc(value).format('llll');
	return date;
});

Vue.filter('dateWithoutDayTimeFull', value => {
	moment.locale('en-gb');

	const Q = 'MMMM Do - LT';
	const date = moment(value).format(Q) + ' CET';
	return date;
});

Vue.filter('dateWithTimeFull', value => {
	moment.locale('en-gb');

	const Q = 'dddd, MMMM Do - LT';
	const date = moment(value).format(Q) + ' CET';
	return date;
});

Vue.filter('dateFull', value => {
	moment.locale('en-gb');

	const date = moment(value).format('LL');
	return date;
});

Vue.filter('formatTime', time => {
	const hrs = Math.floor(time / 3600);
	time %= 3600;
	const min = Math.floor(time / 60);
	time %= 60;
	const sec = Math.floor(time);

	let timeArr = hrs > 0 ? [hrs, min, sec] : [min, sec];

	timeArr.forEach((part, index) => {
		if (index > 0 && String(part).length < 2) {
			timeArr[index] = '0' + part;
		}
	});

	return timeArr.join(':');
});

Vue.filter('date', value => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	const date = new Date(value);
	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	return `${day} ${months[monthIndex]}, ${year}`;
});

Vue.filter('dateShort', value => {
	const months = ['jan', 'feb', 'mar', 'apr', 'may', 'june', 'july', 'aug', 'sep', 'oct', 'nov', 'dec'];

	const date = new Date(value);
	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	return `${day}-${months[monthIndex]}-${year}`;
});

Vue.filter('formatPrice', price => {
	const numPrice = Number(price);
	let strPrice = String(price);

	if (strPrice === '') return strPrice;

	if (strPrice.includes('.')) {
		return '€' + String(numPrice.toFixed(2)).replace('.', ',');
	} else {
		return '€' + strPrice + ',00';
	}
});

Vue.filter('number', value => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('UCFirst', value => {
	if (!value) {
		return value;
	}
	return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('truncate', (text, length, suffix) => {
	if (!text) {
		return text;
	}

	if (text.length > length) {
		return text.substring(0, length) + suffix;
	} else {
		return text;
	}
});

Vue.filter('stringSlug', value => {
	return value.replace(/\s+/g, '-').toLowerCase();
});
