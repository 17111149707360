<template>
	<div class="select-simple" :class="{ 'select-simple--disabled': isDisabled }">
		<label class="select-simple__label">{{ selectedLabel }}</label>

		<select
			class="select-simple__select"
			:value="value"
			:disabled="isDisabled"
			@change="$emit('input', $event.target.value)"
		>
			<option
				v-for="(option, index) in data.options"
				:key="option.label + option.value"
				:value="option.value"
				:disabled="!data.firstOptionEnabled && index === 0"
			>
				{{ option.label }}
			</option>
		</select>
	</div>
</template>

<script>
export default {
	name: 'SelectFieldSimple',

	props: {
		value: [String, Number],
		data: Object,
		isDisabled: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		selectedLabel() {
			const selectedOption = this.data.options.find(option => option.value === this.value);
			return (
				(selectedOption && selectedOption.label) ||
				(this.data.firstOptionEnabled
					? this.data.options[0].label
					: (console.log(`Error: no label for ${this.value}`), this.data.options[0].label))
			);
		}
	}
};
</script>

<style lang="scss">
$component: 'select-simple';

.#{$component} {
	position: relative;

	&--disabled {
		.#{$component} {
			&__label {
				color: $color-grey;
				cursor: default;

				&:after {
					display: none;
				}
			}
		}
	}

	&__select {
		position: absolute;

		top: 0;

		width: 100%;
		height: 100%;

		opacity: 0;

		cursor: pointer;
	}

	&__label {
		position: relative;
		display: block;

		overflow: hidden;

		height: 50px;

		padding: 0 15px;
		line-height: 50px;
		font-size: 16px;

		background-image: linear-gradient(
			180deg,
			var(--content-background-dark) 0%,
			var(--content-background-light-2) 100%
		);
		border: 1px solid #5e5e66;
		border-radius: 2px;
		box-shadow: inset 0px 0px 0px 2px $color-black;

		color: var(--primary-body-color);
		text-shadow: 0 1px 0 var(--primary-body-color-dark);

		&::placeholder {
			color: $color-white;
		}

		&:after {
			content: '';

			display: block;
			position: absolute;

			right: calc($global-spacing / 2) + 5;
			top: 48%;
			transform: translateY(-50%) rotate(45deg);

			border: solid var(--primary-body-color);
			border-width: 0 1px 1px 0;
			padding: 5px;
		}
	}
}
</style>
