<template>
	<div class="upsell-item-info">
		<transition duration="2000">
			<div v-if="info && showInfo" v-on-clickaway="onClickOutside" class="upsell-item-info__desc">
				<div class="upsell-item-info__desc-inner" v-html="info"></div>
			</div>
		</transition>
		<div
			v-if="info"
			class="upsell-item-info__btn"
			:class="{ 'upsell-item-info__btn--active': showInfo }"
			@click="onClickInfo"
		>
			<IconInfo />
		</div>
	</div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
	name: 'UpsellItemInfo',

	directives: {
		onClickaway: onClickaway
	},

	props: {
		info: String
	},

	data() {
		return {
			showInfo: false
		};
	},

	methods: {
		onClickInfo() {
			this.showInfo = !this.showInfo;
		},

		onClickOutside() {
			this.showInfo = false;
		}
	}
};
</script>

<style lang="scss">
$component: 'upsell-item-info';

.#{$component} {
	display: none;

	div[class$='--upsell'] & {
		display: block;
	}

	&__btn {
		position: absolute;
		bottom: 7px;
		left: 7px;

		line-height: 0;
		color: rgba(var(--primary-color), 1);

		cursor: pointer;

		html.no-touchevents & {
			transition: opacity 0.3s $ease-out-cubic;

			&:hover {
				opacity: 0.4;
			}
		}

		svg {
			width: 20px;
			height: 20px;
		}

		&--active {
			opacity: 0.4;
			html.no-touchevents &:hover {
				opacity: 1;
			}
		}
	}

	&__desc {
		position: absolute;
		left: 0;
		top: 0;

		width: 100%;
		height: 100%;

		padding: 5px;

		font-size: 14px;
		line-height: 1.4;

		background-color: rgba(white, 0.9);

		transform-origin: right bottom;

		&.v-enter,
		&.v-leave-to {
			opacity: 0;
		}
		&.v-enter-active {
			transition: opacity 0.3s $ease-in-out-sine;
		}
		&.v-leave-active {
			transition: opacity 0.3s $ease-in-out-sine;
		}
	}

	&__desc-inner {
		height: 100%;
		overflow: auto;
	}
}
</style>
