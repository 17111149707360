<template>
	<div class="input-field">
		<label class="input-field__label" for="input">
			<slot></slot>
			
		</label>

		<div class="input-field__wrapper">
			<input
				class="input-field__input"
				type="input"
				:name="name"
				:id="name"
				v-on:input="updateValue({ value: $event.target.value, key: $event.target.name })"
				v-model="selectInternalModel"
				:readonly="readonly"
			/>
			<!-- <div class="input-field__tooltip-marker" v-if="readonly"></div>
			<div class="input-field__tooltip" v-if="readonly">
				If you want to use a different email address,
				<br>
				<span v-on:click="handleLogout()">log out</span> and log in with your correct account.
			</div> -->
		</div>
		<slot name="error"></slot>
	</div>
</template>

<script>
export default {
	name: 'Input',

	data() {
		return {
			value: '',
			selectInternalModel: '',
			readonly: false
		};
	},

	props: {
		name: null,
		preselected: String
	},

	methods: {
		updateValue(val) {
			this.$emit('input', val);
		},

		setDefaults() {
			if (this.preselected) {
				this.selectInternalModel = this.preselected;
				this.updateValue({ value: this.preselected, key: this.name });
				this.readonly = this.name === 'email';
			}
		},

		handleLogout() {
			this.readonly = false;
			this.userLogout();
			this.userLogin();
		}
	},

	mounted() {
		this.setDefaults();
	}
};
</script>

<style lang="scss">
$component: 'input-field';

.#{$component} {
	display: block;

	&__label {
		display: block;

		padding-bottom: 10px;
	}

	&__input {
		position: relative;
		display: block;

		overflow: hidden;

		height: 50px;
		width: 100%;

		padding: 0 15px;
		line-height: 50px;
		font-size: 16px;

		background-image: linear-gradient(
			180deg,
			var(--content-background-dark) 0%,
			var(--content-background-light-2) 100%
		);
		border: 1px solid #5e5e66;
		border-radius: 2px;
		box-shadow: inset 0px 0px 0px 2px $color-black;

		color: var(--primary-body-color);
		text-shadow: 0 1px 0 var(--primary-body-color-dark);

		&::placeholder {
			color: $color-white;
		}
		&:read-only {
			cursor: not-allowed;
			color: rgba(var(--primary-body-color), 0.5);
		}
	}

	&__tooltip-marker {
		position: absolute;
		top: 0.5rem;
		right: 0;
		height: 1.25rem;
		width: 1.25rem;
		border: 1px solid white;
		border-radius: 50%;
		background-color: var(--primary-body-color-dark);

		&:after {
			content: 'i';
			position: absolute;
			font-size: 12px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__tooltip {
		opacity: 0;
		visibility: hidden;
		font-size: 12px;
		text-align: center;
		position: absolute;
		width: 18rem;
		left: 50%;
		top: -2rem;
		transform: translate(-50%, 1rem);
		background-color: var(--primary-body-color-dark);
		border: 1px solid #5e5e66;
		padding: 0.75rem 0;
		z-index: 99;
		transition: 0.3s ease all;
		span {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	&__tooltip-marker:hover + &__tooltip,
	&__tooltip:hover {
		transform: translate(-50%, 0);
		visibility: visible;
		pointer-events: all;
		opacity: 1;
	}
}
</style>
