<template>
	<div class="checkout-form" :key="key">
		<Grid v-for="(fields, index) in checkoutFields.data" :key="index" full-width no-margins>
			<Column>
				<div class="checkout-form__section">
					<span>{{ $t(`form.${fields.name}`) }}</span>
				</div>
			</Column>

			<Column
				v-show="hideGender(item)"
				class="checkout-form__col"
				:s="6"
				:m="3"
				v-for="(item, index) in fields.fields"
				:key="index"
			>
				<component
					v-if="item.type === 'InputField'"
					:is="item.type"
					:name="item.name"
					:preselected="getPrefilledData(item.model)"
					v-model="form[item.model]"
					:class="{
						'form--error': (errors && errors[item.model]) || (showEmailWarning && item.name === 'email')
					}"
				>
					<span>{{ $t(`form.${item.name.toLowerCase()}`) }}</span>

					<template v-slot:error>
						<p
							class="form--error checkout-form--error-small"
							v-show="item.name == 'email' && showEmailWarning"
						>
							{{ checkyourEmail }}
						</p>
					</template>
				</component>

				<template v-else-if="item.type === 'DateField'">
					<label class="input-field__label" :class="{ 'form--error': errors && errors[item.model] }">
						{{ $t(`form.${item.name.toLowerCase()}`) }}
					</label>

					<component
						:label="item.label"
						:is="item.type"
						:initial-date="birthdate"
						v-model="form[item.model]"
					/>
				</template>

				<template v-else-if="item.type === 'SelectField'">
					<label class="input-field__label" :class="{ 'form--error': errors && errors[item.model] }">
						{{ $t(`form.${item.name.toLowerCase()}.label`) }}
					</label>

					<component
						:label="item.label"
						:is="item.type"
						:name="item.name"
						:preselected="getPrefilledData(item.model)"
						:valueFromDataAttr="true"
						v-model="form[item.model]"
					>
						<option
							v-for="option in item.slot"
							:key="option.type"
							:value="option.type"
							:name="option.type"
							v-bind:data-id="option.value"
						>
							{{ $t(`form.${item.name.toLowerCase()}.${option.value.toLowerCase()}`) }}
						</option>
					</component>
				</template>

				<template v-else-if="item.type === 'SelectFieldCountry'">
					<label class="input-field__label" :class="{ 'form--error': errors && errors[item.model] }">
						{{ $t(`form.${item.name.toLowerCase()}.label`) }}
					</label>

					<component
						:label="item.label"
						:is="'SelectField'"
						:name="item.name"
						:preselected="getPrefilledData(item.model)"
						:valueFromDataAttr="true"
						v-model="form[item.model]"
					>
						<option
							v-for="option in item.slot"
							:key="option.type"
							:value="option.type"
							:name="option.type"
							v-bind:data-id="option.value"
						>
							{{ option.type }}
						</option>
					</component>
				</template>

				<template v-else-if="item.type === 'SelectFieldCode'">
					<label class="input-field__label" :class="{ 'form--error': errors && errors[item.model] }">
						{{ $t(`form.${item.name.toLowerCase()}.label`) }}
					</label>

					<component
						:label="item.label"
						:is="`SelectField`"
						:name="item.name"
						:preselected="getPrefilledData(item.model)"
						:valueFromDataAttr="true"
						v-model="form[item.model]"
					>
						<option
							v-for="option in item.slot"
							:key="option.type"
							:value="`+${option.value}`"
							:name="option.type"
							v-bind:data-id="option.value"
						>
							{{ option.type }}
						</option>
					</component>
				</template>

				<template v-else-if="item.type === 'SelectFieldState' && requireState">
					<label class="input-field__label" :class="{ 'form--error': errors && errors[item.model] }">
						{{ $t(`form.${item.name.toLowerCase()}.label`) }}
					</label>

					<component
						:label="item.label"
						:is="'SelectField'"
						:name="item.name"
						:valueFromDataAttr="true"
						v-model="form[item.model]"
					>
						<option
							v-for="option in stateNames"
							:key="option"
							:value="option"
							:name="option"
							v-bind:data-id="option"
						>
							{{ option }}
						</option>
					</component>
				</template>
			</Column>
		</Grid>

		<!-- <Grid :centered="false" is-full-width no-margins>
			<Column>
				<Checkbox :data="{ value: 'newsletter' }" v-model="newsletter">
					<span v-html="'Newsletter signup'" />
				</Checkbox>
			</Column>
		</Grid> -->

		<Grid full-width no-margins>
			<Column :mOffset="7" class="checkout-form__buttons">
				<button class="checkout-form__back" @click="backtoOrderoverview">
					<Arrow-down class="checkout-form__arrow" />

					<span>{{ $t('label.back') }}</span>
				</button>

				<Button class="button--alternative-second" @click.native="placeOrder" v-if="!loading">
					<span>{{ $t('label.cart.pay') }}</span>
				</Button>

				<Spinner v-else />
			</Column>
		</Grid>

		<Grid v-if="errors" full-width no-margins>
			<Column :mOffset="7" class="checkout-form__error-fields">
				<span>{{ $t('error.form_has_errors') }}</span>
			</Column>
		</Grid>

		<Grid v-if="apiErrorMsg" full-width no-margins>
			<Column :mOffset="7" class="checkout-form__error-fields">
				<span>{{ apiErrorMsg }}</span>
			</Column>
		</Grid>
	</div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox';
import InputField from '@/components/ui/filter/Input';
import SelectField from '@/components/ui/SelectField';
import DateField from '@/components/ui/filter/DateDropdown';

import CheckoutField from '@/static/data/shopCheckoutForm.js';
import CheckoutFieldRequired from '@/static/data/shopCheckoutFormRequired.js';
import CountryList from '@/static/data/countryCodes.json';
import FormValidationRegex from '@/mixins/FormValidationRegex.js';
import moment from 'moment';
import preserveUTMParams from '@/utils/preserveUTMParams';
import sha256 from 'crypto-js/sha256';

export default {
	name: 'CheckoutForm',

	data() {
		return {
			showEmailWarning: false,
			emailInputMistakes: ['.con', '.co', 'gmai', 'gmil', 'hotmai', 'homail'],
			newsletter: false,
			key: 'no-user',
			requireState: false,
			countryList: CountryList,
			form: {
				middleName: ' '
			},
			formValidation: {
				gender: 'radio',
				firstName: 'string',
				lastName: 'string',
				email: 'email',
				city: 'string',
				country: 'string',
				dateOfBirth: 'date',
				phoneNumber: 'string',
				address: 'string',
				countryCode: 'string',
				streetNumber: 'number',
				postalCode: 'string',
				state: 'string'
			},
			errors: null,
			apiErrorMsg: null,
			loading: false,
			showEmailWarning: false
		};
	},

	mixins: [FormValidationRegex],

	components: {
		InputField,
		SelectField,
		DateField,
		Checkbox
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},
		checkyourEmail() {
			return this.$t('checkout.emailWarning.body') != 'checkout.emailWarning.body'
				? this.$t('checkout.emailWarning.body')
				: 'check your email adres';
		},
		genderNeutralLabel() {
			return process.env.SHOPBRAND === 'mysteryland' ? 'Non-binary' : this.$t('form.gender.other');
		},

		brand() {
			return this.$store.getters.getBrand;
		},

		checkoutFields() {
			let fieldsData = CheckoutField;

			if (this.brand === 'aod') {
				fieldsData = CheckoutFieldRequired;
			}

			const fields = {
				...fieldsData
			};

			fields.data
				.find(data => data.name === 'personal_information')
				.fields.find(field => field.model === 'gender')
				.slot.find(slot => slot.value === 'other').type = this.genderNeutralLabel;

			return fields;
		},

		birthdate() {
			if (this.userProfile && this.userProfile.birthdate) {
				const dateParts = this.userProfile.birthdate.split('-');
				return {
					day: dateParts[2],
					month: dateParts[1],
					year: dateParts[0]
				};
			}
			return {
				day: 0,
				month: 0,
				year: 0
			};
		},

		getPrefilledData(value) {
			let mappedKey;

			return value => {
				if (this.userProfile) {
					// Address and gender mapping
					if (['city', 'postalCode', 'address'].includes(value) && this.userProfile.address) {
						mappedKey = {
							city: 'city',
							postalCode: 'postalCode',
							address: 'street'
						}[value];
						return this.userProfile.address[mappedKey];
					}

					if (['streetNumber', 'streetNumberExtension'].includes(value) && this.userProfile.address) {
						let houseNumberParts = null;
						if (this.userProfile.address.houseNumber) {
							houseNumberParts = this.userProfile.address.houseNumber.split('-');
						}
						if (value === 'streetNumber' && houseNumberParts) {
							const regex = /^\d+$/;
							const numberAndLetter = regex.test(houseNumberParts[0]);
							return !numberAndLetter ? '' : houseNumberParts[0];
						}
						// if (value === 'streetNumberExtension' && houseNumberParts && houseNumberParts.length > 1) {
						// 	console.log('2');
						// 	return houseNumberParts.pop();
						// }
					}

					if (value === 'countryCode' && this.prefillCountryData) {
						return this.prefillCountryData.phone;
					}

					if (value === 'country' && this.prefillCountryData) {
						return this.prefillCountryData.type;
					}

					if (value === 'gender') {
						if (this.userProfile.gender) {
							return {
								male: 'Male',
								female: 'Female',
								other: this.genderNeutralLabel
							}[this.userProfile.gender];
						}

						return '';
					}

					if (value === 'phoneNumber' && this.prefillPhoneNumber) {
						return this.prefillPhoneNumber;
					}

					// All other stuff
					mappedKey = {
						firstName: 'givenName',
						lastName: 'familyName',
						gender: 'gender',
						email: 'email'
					}[value];

					return this.userProfile[mappedKey];
				}

				return null;
			};
		},

		prefillPhoneNumber() {
			const regex = new RegExp(/^\+\d{2}/);

			if (regex.test(this.userProfile.phoneNumber))
				this.userProfile.phoneNumber = this.userProfile.phoneNumber.replace(regex, '0');

			return this.userProfile.phoneNumber;
		},

		prefillCountryData() {
			if (this.userProfile && this.userProfile.address) {
				const { countryCode } = this.userProfile.address;
				if (countryCode) {
					const countryCodeLowerCase = countryCode.toLowerCase();
					this.requireState =
						(this.isPaymentProviderAdyen &&
							(countryCodeLowerCase === 'us' || countryCodeLowerCase === 'ca')) ||
						(this.isPaymentProviderPaylogic &&
							(countryCodeLowerCase === 'us' || countryCodeLowerCase === 'br'));

					return this.countryList.find(item => item.value === countryCodeLowerCase);
				}
			}
			return null;
		},

		getShopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		userProfile() {
			return this.userData && this.userData.profile;
		},

		stateNames() {
			const brazile = [
				'AC',
				'AL',
				'AM',
				'AP',
				'BA',
				'CE',
				'DF',
				'ES',
				'GO',
				'MA',
				'MG',
				'MS',
				'MT',
				'PA',
				'PB',
				'PE',
				'PI',
				'PR',
				'RJ',
				'RN',
				'RO',
				'RR',
				'RS',
				'SC',
				'SE',
				'SP',
				'TO'
			];

			const usa = [
				'AK',
				'AL',
				'AR',
				'AZ',
				'CA',
				'CO',
				'CT',
				'DC',
				'DE',
				'FL',
				'GA',
				'HI',
				'IA',
				'ID',
				'IL',
				'IN',
				'KS',
				'KY',
				'LA',
				'MA',
				'MD',
				'ME',
				'MI',
				'MN',
				'MO',
				'MS',
				'MT',
				'NC',
				'ND',
				'NE',
				'NH',
				'NJ',
				'NM',
				'NV',
				'NY',
				'OH',
				'OK',
				'OR',
				'PA',
				'RI',
				'SC',
				'SD',
				'TN',
				'TX',
				'UT',
				'VA',
				'VT',
				'WA',
				'WI',
				'WV',
				'WY'
			];

			const canadaStates = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

			const isUsa =
				(this.form.country && this.form.country.value === 'United States') ||
				(this.form.country && this.form.country.value === 'us');

			const isCA = this.form.country?.value === 'ca' || this.form.country?.value === 'Canada';
			const isBrazil = this.form.country?.value === 'br' || this.form.country?.value === 'Brazil';

			if (this.isPaymentProviderAdyen) {
				// User should be able to select Canada states when payment provider is "adyen" and country is USA or CA
				if (isCA) return canadaStates;
				if (isUsa) return usa;

				return [];
			}

			if (this.isPaymentProviderPaylogic) {
				if (isUsa) return usa;
				if (isBrazil) return brazile;

				return [];
			}

			return [];
		},

		/**
		 * Returns payment provider
		 * @return {"paylogic"|"adyen"}
		 */
		getPaymentProvider() {
			return this.$store.getters['shopconfig/getPaymentProvider'];
		},
		/**
		 * Returns payment provider is Adyen or not
		 * @return {bool}
		 */
		isPaymentProviderAdyen() {
			return this.getPaymentProvider === 'adyen';
		},
		isPaymentProviderPaylogic() {
			return this.getPaymentProvider === 'paylogic';
		},
		getShopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},
		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		}
	},

	watch: {
		form: {
			handler(val) {
				if (val.email) {
					const domain = val.email ? val.email.value.toLowerCase().split('@')[1] : false;
					if (domain) {
						const extension = domain ? `.${domain.split('.')[1]}` : false;
						const domainname = domain ? domain.split('.')[0] : false;
						if (
							this.emailInputMistakes.includes(extension) ||
							this.emailInputMistakes.includes(domainname)
						) {
							this.showEmailWarning = true;
							// this.errors[key] = 'error';
						} else {
							this.showEmailWarning = false;
						}
					}
				}
				if (val.country) {
					this.requireState =
						(this.isPaymentProviderAdyen &&
							(val.country.value === 'us' ||
								val.country.value === 'United States' ||
								val.country.value === 'ca' ||
								val.country.value === 'Canada')) ||
						(this.isPaymentProviderPaylogic &&
							(val.country.value === 'us' ||
								val.country.value === 'United States' ||
								val.country.value === 'br' ||
								val.country.value === 'Brazil'));
				}
			},
			deep: true,
			immediate: true
		}

		/* getUserData(val) {
			this.key = val.UID ? val.UID : 'no-user';

			if (val.birthdate) {
				this.birthdate.day = val.birthdate.split('-')[2];
				this.birthdate.month = val.birthdate.split('-')[1];
				this.birthdate.year = val.birthbirthdateYear.split('-')[0];
			}
		} */
	},

	methods: {
		hideGender(item) {
			if (item.name === 'gender' && this.brand === 'milkshake') {
				return false;
			}
			return true;
		},

		backtoOrderoverview() {
			this.$store.commit('setShowCheckoutForm', false);
		},

		validateForm() {
			let valid = true;

			this.errors = {};

			if (this.brand === 'milkshake') {
				delete this.formValidation.gender;
			}

			Object.keys(this.formValidation).forEach(key => {
				if (key === 'state' && this.requireState) {
					if (!this.form[key] || !this.form[key].value) {
						this.errors[key] = 'error';

						valid = false;
					}
				}

				if (key !== 'state') {
					if (!this.form[key]) {
						this.errors[key] = 'error';
						valid = false;
						return;
					}
					if (!this.form[key].value) {
						this.errors[key] = 'error';
						valid = false;
						return;
					}

					if (this.form[key].value) {
						const item = this.form[key].value;

						if (this.formValidation[key] === 'string' || this.formValidation[key] === 'option') {
							if (!this.valString(item)) {
								this.errors[key] = 'error';
								valid = false;
							}
						}

						if (this.formValidation[key] === 'number') {
							if (!this.valNumber(item)) {
								this.errors[key] = 'error';
								valid = false;
							}
						}

						if (this.formValidation[key] === 'email') {
							if (!this.valEmail(item)) {
								this.errors[key] = 'error';
								valid = false;
							}
						}

						if (this.formValidation[key] === 'date') {
							if (!this.valDate(item)) {
								this.errors[key] = 'error';
								valid = false;
							}
						}
					}
				}
			});

			if (this.brand === 'milkshake') {
				delete this.errors.gender;
			}

			// check date of birth requirements if date is present
			if (this.form.dateOfBirth) {
				/** @type {'Yes, currently 18 years' | 'No' | 'Yes, 18 at event start'} */
				const ageRequirements = this.getShopConfig.shopSettings?.fields?.ageRequirements;
				/** @type {string} */
				const ageRequirementsError = this.getShopConfig.shopSettings?.fields?.ageRequirementsError;
				const dateOfBirth = moment(this.form.dateOfBirth.value, 'YYYY-MM-DD');
				const eventStart = moment(this.getShopConfig.festivalData?.dateStart ?? undefined);

				if (ageRequirements === 'Yes, 18 at event start') {
					const diffInYears = eventStart.diff(dateOfBirth, 'years');
					if (diffInYears < 18) {
						this.$fireErrorEvent(ageRequirementsError);
						this.errors.dateOfBirth = 'error';
						valid = false;
					}
				} else if (ageRequirements === 'Yes, currently 18 years') {
					const diffInYears = moment().diff(dateOfBirth, 'years');
					if (diffInYears < 18) {
						this.$fireErrorEvent(ageRequirementsError);
						this.errors.dateOfBirth = 'error';
						valid = false;
					}
				}
			}

			return valid;
		},

		placeOrder() {
			if (!this.validateForm()) {
				return;
			}

			this.errors = null;
			this.loading = true;
			const data = { ...this.form };

			/*
			 * Format all the data in the correct format for the API
			 */
			Object.keys(data).forEach(key => {
				data[key] = data[key] && data[key].value ? data[key].value : data[key];
			});

			if (this.brand === 'milkshake') {
				data.gender = 'other';
			} else {
				data.gender = {
					Male: 'male',
					male: 'male',
					Female: 'female',
					female: 'female',
					[this.genderNeutralLabel]: 'other',
					other: 'other'
				}[data.gender];
			}

			data.country =
				data.country.length > 2
					? this.countryList.find(item => item.type === data.country).value.toUpperCase()
					: data.country.toUpperCase();
			data.countryCode =
				data.countryCode.length > 2
					? this.countryList.find(item => item.value === data.country.toLowerCase()).phone
					: data.countryCode.toUpperCase();
			data.phoneNumber = '+' + data.countryCode + ' ' + data.phoneNumber;
			data.streetNumberExtension = data.streetNumberExtension ? data.streetNumberExtension : ' ';
			data.streetNumber = parseInt(data.streetNumber);

			/*
			 * State occures when an extension is filled in and then removed again..
			 * Makes sure the api recieves an empty string instead of an object.
			 */
			if (typeof data.streetNumberExtension === 'object') {
				data.streetNumberExtension = ' ';
			}
			/*
			 * The API does not accept this value, so it is removed
			 */
			delete data['countryCode'];
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.getShopCart.id}`,
				params: data,
				paymentStep: true
			};

			if (this.getPaymentProvider === 'adyen') {
				payload.params.redirectUri = preserveUTMParams(
					`${window.location.origin}/${this.getShopConfig.slug}/paymentstatus`
				);
			}

			if (this.newsletter) {
				this.subscribeNewsletterSimple();
			}

			this.$store
				.dispatch('shopapi/putRequest', payload)
				.then(response => {
					const hashedEmail = sha256(this.email).toString();
					localStorage.setItem('datalayer', hashedEmail);
					this.$store.dispatch('dataLayer/beginCheckout', payload.params);

					// Object.keys(this.productsInBasket).forEach(key => {
					// 	const products = this.productsInBasket[key];
					// 	// console.log(products);
					// 	products.forEach(product => {
					// 		const tempData = {};
					// 		if (key !== 'travel' && key !== 'shipping') {
					// 			tempData.name = product.type.name;
					// 			tempData.price = product.type.price;
					// 			tempData.productId = product.type.id;
					// 			tempData.amount = product.numproducts;
					// 			tempData.shop = this.$store.getters['shopconfig/getData'].slug;
					// 			tempData.type = product.type.mappedType;

					// 			const hasVariant = product.type.mappedType == 'merchandise' && product.type.description;
					// 			hasVariant && (tempData.variant = hasVariant);
					// 		} else if (key == 'travel') {
					// 			tempData.name = product.package.name;
					// 			tempData.price = product.totalPrice;
					// 			tempData.productId = `${product.package.eventId}_${product.package.accommodationId}`;
					// 			tempData.amount = 1;
					// 			tempData.shop = this.$store.getters['shopconfig/getData'].slug;
					// 			tempData.type = 'travel';
					// 		}
					// 		productsForCheckout.push(tempData);
					// 	});
					// });
					// console.log(productsForCheckout);
					// this.checkoutDataLayer({
					// 	products: productsForCheckout
					// });

					let query = {
						cart: this.getShopCart.id,
						token: this.getShopConfig.shopToken,
						provider: this.getPaymentProvider
					};
					const seenKeys = new Set();
					for (const key in this.$route.query) {
						if (key.startsWith('utm_') && !seenKeys.has(key)) {
							query[key] = this.$route.query[key];
							seenKeys.add(key);
						}
					}

					this.$router.push({
						name: 'slug-checkout',
						query: query
					});
				})
				.catch(error => {
					console.log(error);
					this.apiErrorMsg =
						error.response &&
						error.response.data &&
						error.response.data.error &&
						error.response.data.error.message;

					const formatedError = this.apiErrorMsg
						? `${error.response.data.error.message}, cartId:${this.getShopCart.id}`
						: `Try again. Refresh this page and, if necessary, join the queue to access the ticket shop again. cartId:${this.getShopCart.id}`;
					this.$fireErrorEvent(formatedError);
					// console.log(error);
					// this.apiErrorMsg = error.response && error.response.data && error.response.data.error.message;
				});
		}
	}
};
</script>

<style lang="scss">
$component: 'checkout-form';

.#{$component} {
	display: block;

	margin-top: $checkout-header-height + 2px;

	padding-bottom: 200px;

	&--error-small {
		padding-top: calc($global-spacing / 2);
		font-size: 14px;
	}

	&__section {
		padding: 20px 30px;

		margin: 20px 0 40px;

		// background-image: linear-gradient(0deg, #080808 0%, #2E2E2E 100%);
		background-image: linear-gradient(
			180deg,
			var(--content-background-dark) 0%,
			var(--content-background-light-2) 100%
		);
		border: 0 solid #545454;
		border: 1px solid #000000;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
		border-radius: 2px;

		font-family: $font-bold;
	}

	&__col {
		padding-bottom: 20px;
	}

	&__buttons {
		display: flex;

		justify-content: center;
		align-items: center;
	}

	&__back {
		display: flex;
		align-items: center;

		text-transform: uppercase;
		font-family: $font-bold;

		margin-right: 20px;

		> svg {
			path {
				fill: var(--primary-body-color);
			}
		}
	}

	&__arrow {
		transform: rotate(90deg);
		width: 20px;

		margin-right: 20px;
	}
	&__error-fields {
		padding-top: 10px;

		text-align: right;

		color: red;
		font-size: 14px;
	}

	&__text-link {
		text-decoration: none;
		color: rgba(var(--primary-color), 0.8);
	}
}
</style>
