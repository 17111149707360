export default {
	computed: {
		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		ticketsInCart() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].ticket;
		}
	},
	methods: {
		removeTravel(travelData) {
			this.$fireErrorEventWithPromis(6001).then(response => {
				if (this.shopConfig.preregistration) {
					const payload = {
						url: '',
						params: {
							hotel: 0
						}
					};
					this.$store.commit('shopconfig/setTravelCart', null);
					this.$store.dispatch('shopapi/fillpreregisterCart', payload);

					const travelTicketInCart =
						!!this.ticketsInCart &&
						this.ticketsInCart.find(item => {
							return (
								item.type.categories &&
								item.type.categories.find(
									ticket => ticket.en === 'TRAVEL TICKETS' || ticket.nl === 'TRAVEL TICKETS'
								)
							);
						});

					const postData = {
						url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
						params: {
							productId: travelTicketInCart.type.id,
							quantity: 0
						}
					};

					this.$store.dispatch('shopapi/putRequest', postData);

					return;
				}

				const travelTicketInCart =
					!!this.ticketsInCart &&
					this.ticketsInCart.find(item => {
						return (
							item.type.categories &&
							item.type.categories.find(
								ticket => ticket.en === 'TRAVEL TICKETS' || ticket.nl === 'TRAVEL TICKETS'
							)
						);
					});

				if (travelTicketInCart) {
					const postData = {
						url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
						params: {
							productId: travelTicketInCart.type.id,
							quantity: 0
						}
					};

					let tempData = {};
					tempData = {
						name: travelTicketInCart.type.meta.name,
						price: travelTicketInCart.type.price,
						productId: travelTicketInCart.type.id,
						amount: travelTicketInCart.numproducts,
						shop: this.$store.getters['shopconfig/getData'].titleFull,
						type: travelTicketInCart.type.mappedType
					};

					// this.removeFromDataLayer({
					// 	products: [tempData]
					// });

					const translationData = this.$store.getters.getTranslationData.data;
					if (
						translationData.popups.travelRemovalWithTickets &&
						translationData.popups.travelRemovalWithTickets.title &&
						translationData.popups.travelRemovalWithTickets.body
					) {
						this.$store.commit('setModalTitle', translationData.popups.travelRemovalWithTickets.title); // title that gets shown by 8003 error modal
						this.$store.commit('setModalMessage', translationData.popups.travelRemovalWithTickets.body); // message that gets shown by 8003 error modal
						this.$fireErrorEvent(8003);
					}

					this.$store.dispatch('shopapi/putRequest', postData);
					this.$store.dispatch('shopapi/deleteTravelCart');
				} else {
					this.$store.dispatch('shopapi/deleteTravelCart');
				}

				let tempData = {};
				if (travelData.package) {
					tempData = {
						name: travelData.package.name,
						price: travelData.totalPrice,
						productId: `${travelData.package.eventId}_${travelData.package.accommodationId}`,
						amount: 1,
						shop: this.$store.getters['shopconfig/getData'].titleFull,
						type: 'travel'
					};
				} else {
					tempData = {
						name: travelData.title,
						price: travelData.price,
						productId: `${travelData.data.package.eventId}_${travelData.data.package.accommodationId}`,
						amount: 1,
						shop: this.$store.getters['shopconfig/getData'].titleFull,
						type: travelData.type
					};
				}

				// this.removeFromDataLayer({
				// 	products: [tempData]
				// });
			});
		}
	}
};
