<template>
	<transition name="fade">
		<div v-if="showUserProfile" class="user-profile" @click="clickHandler" v-click-outside="closeHandler">
			<div class="user-profile__icon">
				<User />
			</div>

			<transition name="fade-fast">
				<ul class="user-profile__options" v-if="showOptions">
					<li
						class="user-profile__option"
						v-for="(option, index) in options"
						:key="index"
						@click="option.action"
					>
						{{ option.label }}
					</li>
				</ul>
			</transition>
		</div>
	</transition>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
	name: 'UserProfile',

	directives: {
		ClickOutside
	},

	data() {
		return {
			showOptions: false
		};
	},

	computed: {
		isSsoAvailable() {
			return this.$store.getters['sso/getIsSsoAvailable'];
		},

		brand() {
			return this.$store.getters.getBrand;
		},

		showUserProfile() {
			// return this.isSsoAvailable && this.$route.name !== 'index';
			return this.isSsoAvailable && this.brand !== 'zwartecross' && this.brand !== 'mananamanana';
		},

		options() {
			const options = [];

			options.push(this.createOption('Account', this.onClickAccount));
			options.push(this.createOption('Log out', this.onClickLogout));

			return options;
		}
	},

	methods: {
		createOption(label, action) {
			return { label, action };
		},

		onClickAccount(e) {
			e.stopPropagation();
			this.editAccount();
			this.showOptions = false;
		},

		onClickLogout(e) {
			e.stopPropagation();
			this.userLogout();
			this.showOptions = false;
		},

		clickHandler() {
			if (this.isUserLoggedIn) {
				this.showOptions = !this.showOptions;
			} else {
				this.userLogin();
			}
		},

		closeHandler() {
			this.showOptions = false;
		}
	}
};
</script>

<style lang="scss">
$component: 'user-profile';

.#{$component} {
	position: relative;
	cursor: pointer;
	display: inline-flex;

	font-size: 0;

	&__icon {
		width: 30px;
		height: 30px;

		padding: 5px;

		svg {
			width: 20px;
			height: 20px;

			defs {
				path {
					fill: var(--navigation-top-bar-links);
				}
			}
		}
	}

	&__options {
		position: absolute;
		top: calc(100% + 10px);
		right: -10px;

		width: 100px;
		padding: 10px;

		box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.2);

		background-color: var(--navbar-text-color);

		&:before {
			position: absolute;
			display: block;
			top: 0;
			right: 15px;

			width: 10px;
			height: 10px;

			border-top: 1px solid #ddd;
			border-left: 1px solid #ddd;

			background-color: var(--navbar-text-color);

			transform: translate(-50%, -50%) rotate(45deg);

			content: '';
		}
	}

	&__option {
		font-size: 12px;
		color: rgba(var(--navbar-bg-color), 1);
		text-align: left;

		&:not(:first-child) {
			margin-top: 5px;
		}

		&:hover {
			opacity: 0.6;
		}
	}

	@include sm {
		&__icon {
			width: 30px;
			height: 30px;

			padding: 7px;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}
</style>
