import Vue from 'vue';
import axios from 'axios';

const travelAxios = axios.create({
	transformRequest: (data, headers) => {
		delete headers.common['x-user-token'];
	}
});

export const state = () => ({
	settings: null,
	packages: null,
	availableRatings: [],
	filtersData: [],
	roomOptions: [],
	roomExtras: [],
	queryingState: '',
	currentOrderId: ''
});

export const actions = {
	loadSettings(context) {
		const apiTravelId = context.rootGetters['shopconfig/getData'].apiTravelId;

		const settingsUrl = `${process.env.TRAVEL_API_BASE}${apiTravelId}/en/settings`;

		return travelAxios
			.get(settingsUrl)
			.then(response => {
				context.commit('setSettings', response.data[0]);
			})
			.catch(error => {
				console.log(error);
			});
	},

	loadFilters(context) {
		// const shopSlug = context.rootGetters['shopconfig/getData'].slug;
		// const filtersJsonUrl = '/data/travelFilter.json';
		// const filtersJsonUrl = `./data/${shopSlug}/travelFilter.json`;
		// const filtersJsonUrl = `${process.env.DATA_FOLDER}travelFilter.json`;

		// return travelAxios
		// 	.get(filtersJsonUrl)
		// 	.then(response => {
		// 		const filters = response.data;
		const filters = [
			{
				title: 'Persons',
				name: 'persons',
				type: 'SelectField',
				value: '',
				data: [
					{
						options: [
							{
								label: 'Number of persons',
								value: ''
							}
						]
					}
				]
			},
			{
				title: 'Rating',
				name: 'rating',
				type: 'RadioButton',
				value: '',
				data: [
					{
						label: 'All',
						value: ''
					}
				]
			}
		];
		const categories = context.getters.getSettings && context.getters.getSettings.category;

		if (categories && categories.length) {
			// Available package categories are merged with static filters from the json file
			filters.splice(1, 0, {
				title: 'Accommodations',
				name: 'category',
				type: 'Checkbox',
				value: [],
				data: categories.map(cat => {
					return {
						label: cat.title,
						value: cat.name
					};
				})
			});
		}

		// Add options for number of guests here. Would be ridiculous to put that in a json. Right?
		const guestsOptions = filters.find(filter => filter.name === 'persons').data[0].options;

		for (let i = 1; i < 51; i++) {
			guestsOptions.push({
				label: `${i} Person${i > 1 ? 's' : ''}`,
				value: `${i}`
			});
		}

		context.commit('setFiltersData', filters);
		// })
		// .catch(error => {
		// 	console.log(error);
		// });
	},

	loadPackages(context, payload = null) {
		payload && payload[0] !== 'date' && context.commit('updateFilters', payload);

		let urlParams = context.getters.getPackagesFilterParams;

		if (payload && payload[0] === 'date') {
			urlParams += `${urlParams.length ? '&' : '?'}${payload[1]}`;
		}

		const apiTravelId = context.rootGetters['shopconfig/getData'].apiTravelId;
		const packagesUrl = `${process.env.TRAVEL_API_BASE}/${apiTravelId}/en/packages${urlParams}`;

		// When not filtering on rating, we receive ALL possible packages. So that's when we can check if we need to limit the rating filter.
		// (no need to be able to filter on 5 stars, if the highest available package is less than 5 stars)

		let doUpdateRatingFilter = '';
		if (context.getters.getFiltersData.length) {
			doUpdateRatingFilter = context.getters.getFiltersData.find(filter => filter.name === 'rating').value === '';
		}

		return travelAxios
			.get(packagesUrl)
			.then(response => {
				if (doUpdateRatingFilter) {
					const availableRatings = [...new Set(response.data.map(pack => pack.rating))].sort();

					context.commit(
						'setAvailableRatings',
						availableRatings.map(rating => {
							return {
								label: `${rating} Star${rating > 1 ? 's' : ''}`,
								value: String(rating)
							};
						})
					);
				}

				const data = response.data;

				data.forEach(item => {
					/* console.log('todo');
					if(item.tickets && item.tickets.length){
						item.tickets[0].
					} */

					item.slug = item.name
						.replace(/[^a-zA-Z0-9 ]/g, '')
						.replace(/\s+/g, '-')
						.toLowerCase();
				});

				// Put soldout package at the end
				let sortedPackages = data.filter(pack => !pack.state.soldout);
				sortedPackages = sortedPackages.concat(data.filter(pack => pack.state.soldout));

				context.commit('setPackages', sortedPackages);

				// return response;
			})
			.catch(error => {
				console.log(error);
			});
	},

	queryRoomOptions(context, payload) {
		context.commit('setQueryingState', 'rooms');
		context.commit('setRoomOptions', []);
		context.commit('setRoomExtras', []);

		const apiTravelId = context.rootGetters['shopconfig/getData'].apiTravelId;
		const url = `${process.env.TRAVEL_API_BASE}/${apiTravelId}/en/packages/${payload.packageId}/options`;

		return travelAxios
			.get(url, { params: payload.params })
			.then(response => {
				context.commit('setRoomOptions', response.data[0].rooms);
				context.commit('setQueryingState', '');
			})
			.catch(error => {
				context.commit('setQueryingState', '');
				console.log(error);
			});
	},

	queryRoomExtras(context, payload) {
		const apiTravelId = context.rootGetters['shopconfig/getData'].apiTravelId;
		const url = `${process.env.TRAVEL_API_BASE}/${apiTravelId}/en/packages/${payload.packageId}/options/extras`;

		const params = {
			packageOptionId: payload.roomId
		};

		travelAxios
			.get(url, { params })
			.then(response => {
				const availableItems = response.data.filter(pack => !pack.soldOut);
				const availableCategories = [...new Set(availableItems.map(item => item.category))];

				const extras = availableCategories.map(catName => {
					const catLabel = {
						extra: "Extra's",
						transfer: 'Transfers',
						merchandise: 'Merchandise'
					}[catName];

					return {
						category: catName,
						label: catLabel,
						items: availableItems.filter(extraItem => extraItem.category === catName)
					};
				});

				context.commit('setRoomExtras', extras);
			})
			.catch(error => {
				console.log('Error queryRoomExtras:', error);
			});
	},

	unloadOrderId(context, payload) {}
};

export const getters = {
	getSettings: state => state.settings,
	getPackages: state => state.packages,
	getRoomoptions: state => state.roomOptions,
	getRoomExtras: state => state.roomExtras,
	getIsQuerying: state => !!state.queryingState,
	getFiltersData: state => {
		return state.filtersData.map(filter => {
			if (filter.name === 'rating') {
				// Add available ratings to default 'All' value
				return {
					...filter,
					data: [...filter.data, ...state.availableRatings]
				};
			} else {
				return filter;
			}
		});
	},

	getPackagesFilterParams: state => {
		let filterUrlParams = '';

		state.filtersData.forEach(filter => {
			if (typeof filter.value === 'string' && filter.value !== '') {
				filterUrlParams += `${filterUrlParams.length ? '&' : '?'}${filter.name}=${filter.value}`;
			} else if (Array.isArray(filter.value) && filter.value.length) {
				filterUrlParams += `${filterUrlParams.length ? '&' : '?'}${filter.name}=${filter.value.join(',')}`;
			}
		});

		return filterUrlParams;
	},

	getCurrentOrderId: state => state.currentOrderId
};

export const mutations = {
	setSettings(state, payload) {
		state.settings = payload;
	},

	setPackages(state, payload) {
		state.packages = payload;
	},

	setFiltersData(state, payload) {
		state.filtersData = payload;
	},

	setAvailableRatings(state, payload) {
		state.availableRatings = payload;
	},

	updateFilters(state, [filterGroupName, filterName, filterValue]) {
		const filterGroup = state.filtersData.find(filterGroup => filterGroup.name === filterGroupName);

		if (filterGroup) {
			if (filterGroup.type === 'Checkbox') {
				const filterIndex = filterGroup.value.indexOf(filterName);

				if (filterIndex === -1 && filterValue) {
					filterGroup.value.push(filterName);
				} else if (filterIndex > -1 && !filterValue) {
					filterGroup.value.splice(filterIndex, 1);
				}

				// } else if (filterGroup.type === 'RadioButton' || filterGroup.type === 'SelectField') {
			} else {
				filterGroup.value = filterValue;
			}
		}
	},

	clearFilters(state) {
		state.filtersData.forEach(filter => {
			filter.value = Array.isArray(filter.value) ? [] : '';
		});
	},

	setQueryingState(state, payload) {
		state.queryingState = payload;
	},

	setRoomOptions(state, payload) {
		state.roomOptions = payload;
	},

	setRoomExtras(state, payload) {
		// Vue.set(state, 'roomExtras', payload);
		state.roomExtras = payload;
	},

	setCurrentOrderId(state, payload) {
		state.currentOrderId = payload;
	}
};
