<template>
	<div class="basket-addons" v-if="addonsInBasket.length > 0">
		<div class="basket-addons__reference-container" v-for="(basketPart, index) in basketItemsData" :key="index">
			<h3 class="basket-addons__reference">#{{ basketPart.reference }} - {{ basketPart.packageName }}</h3>
			<div class="basket-addons__item-container">
				<BasketItem
					v-for="(ticket, index) in basketPart.items"
					:data="ticket"
					:key="`ticket${index}`"
					:data-reference="basketPart.reference"
					@input="onInputChange"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import BasketItem from './BasketItem';

export default {
	name: 'BasketExtras',

	components: { BasketItem },

	data() {
		return {
			currentOrderId: ''
		};
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		addonsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].addon || [];
		},

		remappedAddonsInBasket() {
			const tempData = {};
			// Remapping addons to group for reference
			this.addonsInBasket.forEach(addon => {
				let idStr = Object.keys(addon)[0];
				if (!tempData[idStr]) {
					tempData[idStr] = [];
				}
				tempData[idStr].push({
					...addon[idStr]
				});
			});
			// console.log(tempData)
			return tempData;
		},

		purchasedProductData() {
			return this.$store.getters['shopapi/getPurchasedProducts'].data.data;
		},

		packagesData() {
			return this.purchasedProductData && this.purchasedProductData.upsell.hospitality['add_on'];
		},

		basketItemsData() {
			const data = Object.keys(this.remappedAddonsInBasket).map(reference => {
				const items = this.remappedAddonsInBasket[reference].map(addon => {
					const optionsArr = addon.type.options;
					const prices = optionsArr.slice().map(option => option.priceInCents / 100);
					const minMax = optionsArr.slice().map(option => option.amount);
					const data = {
						id: addon.type.id,
						title: `${addon.quantity}x ${addon.type.name}` || '',
						description: addon.type.content.description,
						numberOfProducts: addon.quantity,
						price: Math.min(...prices),
						stock: addon.type.stock,
						maxAmount: Math.max(Math.min(...minMax), Math.max(...minMax)),
						image: addon.type.image,
						reference,
						icon: this.getIcon(false),
						type: 'addon',
						data: addon
					};
					return data;
				});
				items.sort((a, b) => {
					return b.id - a.id;
				});
				const travelPackage = this.packagesData.find(travelPackage => travelPackage.reference == reference);
				return {
					reference,
					items,
					packageName: travelPackage.cart.package.name
				};
			});
			return data;
		},

		purchasedProductData() {
			return this.$store.getters['shopapi/getPurchasedProducts'].data.data;
		},

		foundPackage() {
			return (
				this.purchasedProductData &&
				this.purchasedProductData.upsell.hospitality['add_on'].find(
					item => item.reference === this.currentOrderId
				)
			);
		},

		packageData() {
			let tempData = {
				...this.foundPackage
			};
			tempData.cart.package.addons = {
				available: this.groupBy(this.foundPackage.extras.available, 'category'),
				booked: this.groupBy(this.foundPackage.extras.booked, 'category')
			};
			return tempData ? tempData.cart.package : null;
		},

		hotelCartId() {
			return this.foundPackage && this.foundPackage.cartId;
		}
	},

	methods: {
		getIcon(cat) {
			if (cat) {
				const icon = {
					lockers: 'icon-lockers',
					'parking-tickets': 'icon-parking'
				}[cat];

				if (icon) {
					return icon;
				}
			}
			return 'IconBuilderExtras';
		},

		async onInputChange({ productId, amount, reference }) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products/hotel/${this.shopConfig.apiTravelId}/add-ons`,
				params: {
					addonId: productId,
					quantity: amount > 0 ? amount : 1
				}
			};

			this.currentOrderId = reference;

			this.hotelCartId && (payload.params['hotelCartId'] = this.hotelCartId);

			let query = amount > 0 ? `shopapi/patchRequest` : `shopapi/deleteRequest`;

			const addonResult = await this.$store.dispatch(query, payload);

			this.$store.dispatch('shopapi/updateCart', { cartId: this.hotelCartId });

			if (!addonResult || !addonResult.status || addonResult.status !== 200) {
				addonResult &&
					(() => {
						if (addonResult.data && addonResult.data.error && addonResult.data.error.message) {
							this.$store.commit('setModalMessage', addonResult.data.error.message); // message that gets shown by 8000 error modal
							this.$fireErrorEvent(8000);
						} else {
							console.log('Unknown error response:', addonResult);
						}
					});
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-addons';

.#{$component} {
	&__reference {
		margin-bottom: 1rem;
	}

	&__item-container {
		padding-left: 2rem;
	}

	@include sm {
		&__item-container {
			padding-left: 0;
		}
		&__reference-container:not(:first-child) {
			.#{$component}__reference {
				margin-top: 3rem;
			}
		}
	}
}
</style>
