import { throttle } from '@/utils/throttle';

/**
 * Middleware function that handles various tasks before and after route navigation.
 * @docs https://v2.nuxt.com/docs/directory-structure/middleware/#router-middleware*
 * @param {Object} options - The options object.
 * @param {Object} options.app - The Vue app instance.
 * @param {Object} options.req - The request object.
 * @param {Object} options.route - The current route object.
 * @param {Object} options.store - The Vuex store instance.
 * @param {Function} options.redirect - The redirect function.
 * @param {Object} options.query - The query parameters.
 * @returns {void}
 */
export default function({ app, req, route, store, redirect, query }) {
	if (req) {
		if (route.query.route) {
			const deeplink = {
				route: route.query.route,
				slug: route.query.slug || null,
				category: route.query.category || null,
				raw: `${route.fullPath.split('?')[1]}`
			};
			app.$cookies.remove(`shop_deeplink`);
			app.$cookies.set(`shop_deeplink`, JSON.stringify(deeplink), {
				path: '/',
				maxAge: 6 * 30 * 24 * 3600 // 6 months
			});
		}
	}

	//If user lands on subpages redirect back to main event page
	route.query.token && store.commit('shopconfig/setPaylogictoken', route.query.token);
	route.query.cartToken && store.commit('shopconfig/setShopToken', route.query.cartToken);
	route.query.policyId && store.commit('shopconfig/setPolicyId', route.query.policyId);
	route.query.cartId && store.commit('setCartId', route.query.cartId);

	if (route.name !== 'slug-checkout' && route.query.cartToken && route.query.cartId) {
		store.commit('shopconfig/setCartFromDeeplink', true);
	}

	store.commit('setBrand', process.env.SHOPBRAND);

	const shopData = store.getters['shopconfig/getData'];
	const isSsoInited = store.getters['sso/getIsSsoInited'];
	const isUserLoggedIn = store.getters['sso/getIsUserLoggedIn'];

	/*
	 * If not membersale prevent user form login-out within active session
	 * If user gets this screen something is really wrong :-)
	 */
	if (
		(shopData && shopData.membersale) ||
		(shopData && shopData.preregistration) ||
		(shopData && shopData.travelsale) ||
		(shopData && shopData.addonShop)
	) {
		if (isSsoInited && !isUserLoggedIn && route.path != '/login') {
			redirect('/login');
			return;
		}
	}

	if (
		req &&
		route.params.slug &&
		!route.params.id &&
		shopData === null &&
		route.path.split('/')[2] != 'paymentstatus' &&
		route.path.split('/')[2] != 'checkout'
	) {
		// const newRoute = route.params.slug ? `/${route.params.slug}` : '/';
		// return redirect(newRoute, route.query);

		/* app.$cookies.set('test', route.query.idt_oss_order_id, {
			path: '/',
			maxAge: 3600
		}); */

		return redirect({
			name: 'slug',
			params: route.params,
			query: route.query
		});
	}
}
