<template>
	<Grid :fullWidth="true" class="prefill-cart-item">
		<template v-if="data.key != 'travel' && data.key != 'insurance'">
			<template v-if="data.key != 'addons'">
				<Column :s="9" :m="6" noMargin>
					<span v-if="data.key == 'transportation'">
						<template v-if="data.data.type.id && data.data.type.id.search('partybussen') == -1">
							<span
								class="prefill-cart-item__list-value"
								v-if="data.data.type.id.split('_')[0] === 'paylogic'"
							>
								{{ getCountry(data.data) }}, {{ data.data.type.name.split('_')[1] }},
								{{ data.data.type.name.split('_')[2] }}
							</span>
						</template>
						<span class="prefill-cart-item__list-value" v-else-if="data.data">{{
							data.data.type.name
						}}</span>
						<span class="prefill-cart-item__list-value" v-else>{{ data.type.name }}</span>
					</span>
					<span v-else-if="data.key != 'reference'">
						<span class="prefill-cart-item__list-value" v-if="data.key != 'merchandise'">{{
							data.data.type.name
						}}</span>
						<span class="prefill-cart-item__list-value" v-else>{{
							getMerchandiseWithSize(data.data)
						}}</span>
					</span>
				</Column>
				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ data.data.numproducts }}x</span>
				</Column>
				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ data.data.type.price | formatPrice }}</span>
				</Column>
				<Column :s="3" :m="2">
					<span class="prefill-cart-item__list-value prefill-cart-item__list-value--white">{{
						(data.data.type.price * data.data.numproducts) | formatPrice
					}}</span>
				</Column>
			</template>

			<template v-if="data.key == 'addons'">
				<Column :s="9" :m="6" noMargin>
					<span class="prefill-cart-item__list-value">
						{{ data.data.title }} for Order Number #{{ data.data.id.split('_')[1] }}
					</span>
				</Column>
				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ data.data.quantity }}x</span>
				</Column>
				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ data.data.price | formatPrice }}</span>
				</Column>
				<Column :s="3" :m="2">
					<span class="prefill-cart-item__list-value prefill-cart-item__list-value--white">{{
						(data.data.price * data.data.quantity) | formatPrice
					}}</span>
				</Column>
			</template>
		</template>
		<template v-else>
			<Column :s="9" :m="6" noMargin>
				<span class="prefill-cart-item__list-value">{{ data.data.title }}</span>
			</Column>
			<Column :sHide="true" :m="2">
				<span class="prefill-cart-item__list-value">{{ data.data.quantity }}x</span>
			</Column>
			<Column :sHide="true" :m="2">
				<span class="prefill-cart-item__list-value">{{ data.data.price | formatPrice }}</span>
			</Column>
			<Column :s="3" :m="2">
				<span class="prefill-cart-item__list-value prefill-cart-item__list-value--white">{{
					data.data.price | formatPrice
				}}</span>
			</Column>

			<Grid
				:fullWidth="true"
				class="prefill-cart-item__alternative"
				v-for="(ticket, index) in travelEntrancetickets"
				:key="'ticket' + index"
			>
				<Column :s="6" :m="4" noMargin>
					<span class="prefill-cart-item__list-value">{{ ticket.type.name }}</span>
				</Column>
				<Column :sHide="true" :s="3" :m="2">
					<!-- <span class="prefill-cart-item__list-value">{{ ticket.numproducts }}x</span> -->
				</Column>
				<Column :sHide="true" :s="3" :m="2">
					<span class="prefill-cart-item__list-value">{{ ticket.numproducts }}x (included)</span>
				</Column>
			</Grid>

			<Grid
				:fullWidth="true"
				class="prefill-cart-item__alternative"
				v-for="item in data.data.extras"
				:key="item.extra.name"
			>
				<Column :s="6" :m="4" noMargin>
					<span class="prefill-cart-item__list-value">{{ item.extra.name }}</span>
				</Column>
				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ item.amount }}x</span>
				</Column>
				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ (item.subtotal / item.amount) | formatPrice }}</span>
				</Column>
				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ item.subtotal | formatPrice }}</span>
				</Column>
			</Grid>
		</template>
	</Grid>
</template>

<script>
import countryList from '@/static/data/countryCodes.json';
import PrefillCartItem from '@/components/ui/PrefillCartItem';

export default {
	name: 'PrefillCartItem',

	data() {
		return {
			countryList: countryList,
			apiResponse: null,
			failedResponse: null,
			hasError: false,
			cartCheckoutErrorSeen: false
		};
	},

	components: {
		PrefillCartItem
	},

	props: {
		data: {
			data: Object,
			key: String,
			sortedProductAddon: Object
		}
	},

	computed: {
		shopUpdateState() {
			return this.$store.getters['shopapi/getShopUpdateState'];
		},

		getMerchandise() {
			return this.$store.getters['shopapi/getProducts'].merchandise;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		isWishlistMode() {
			return this.shopConfig.preregistration;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopTravelCart() {
			return this.$store.getters['shopconfig/getTravelCart'];
		},

		showDetailedServiceFees() {
			return this.shopConfig.showDetailedServiceFees;
		},

		travelTotalPriceWithTickets() {
			const travelProduct = this.data.data;
			const travelTickets = this.travelEntrancetickets[0];
			const totalTicketPrice = travelTickets ? travelTickets.type.price * travelProduct.package.occupation : 0;

			return travelProduct.packageOption.subtotal + totalTicketPrice;
		},

		travelEntrancetickets() {
			const travelTickets =
				!!this.data.sortedProductAddon.ticket &&
				this.data.sortedProductAddon.ticket.find(item => {
					return (
						item.type.categories &&
						item.type.categories.find(
							ticket => ticket.en === 'TRAVEL TICKETS' || ticket.nl === 'TRAVEL TICKETS'
						)
					);
				});

			return travelTickets ? [travelTickets] : [];
		}
	},

	methods: {
		gotoRoute(e, route) {
			if (!route) {
				route = this.data.key === 'ticket' ? 'tickets' : this.data.key;
			}

			this.$store.commit('setModalPrefillCartActive', false);
			this.$store.commit('setShowCheckout', false);

			this.$router.push({
				name: `slug-${route}`,
				params: {
					slug: this.$route.params.slug
				}
			});
		},

		getMerchandiseWithSize(data) {
			const item = this.getMerchandise.find(item => item.id === data.type.id);
			if (item) {
				return `${item.name}: ${item.meta.vendorVariantTitle}`;
			}
		},

		getCountry(item) {
			return this.countryList.find(country => country.value === item.type.name.split('_')[0].toLowerCase()).type;
		}
	},

	mounted() {
		/*
		 * Check if selected travelticket is soldout
		 */
		// console.log(this.data)
		// this.data.key == 'addons' && console.log(this.data)
	}
};
</script>

<style lang="scss">
$component: 'prefill-cart-item';

$height: 35px;

.#{$component} {
	position: relative;
	min-height: $height;

	padding: 5px 0;

	> .grid {
		align-items: center;
	}

	&:nth-child(odd) {
		&:after {
			opacity: 0;
		}
	}

	&:after {
		content: '';
		display: block;
		position: absolute;

		width: calc(100% + 10px);
		height: 100%;

		left: 0;
		top: 0px;
		opacity: 1;

		margin-left: -10px;
		background: var(--content-background-light-2);

		z-index: 0;
	}

	&__spinner {
		height: 20px;

		> .lds-ellipsis {
			height: 20px !important;
			> div {
				top: 3px !important;
			}
		}
	}

	&__alternative {
		position: relative;
		min-height: $height;

		> .grid {
			// align-items: center;
			min-height: $height;
		}
	}

	&__list-value {
		position: relative;
		display: block;
		font-size: 14px;
		color: $color-grey;
		text-align: left;

		// white-space: nowrap;
		// text-overflow: ellipsis;
		// overflow: hidden;

		z-index: 2;

		&--white {
			color: $color-white;
		}

		&--red {
			color: $color-red;
			font-family: $font-medium;
		}

		&--green {
			color: $color-green;
			font-family: $font-medium;
		}
	}

	&__error {
		> span {
			color: $color-red;
			font-family: $font-medium;
		}
	}

	@include sm {
		&__selection {
			&:after {
				left: 10px;
				width: calc(100% + 30px);
			}
		}
	}
}
</style>
