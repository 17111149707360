export default {
	install(Vue) {
		Vue.mixin({
			methods: {
				checkoutDataLayer({ products }) {
					if (window.dataLayer) {
						window.dataLayer.push({ ecommerce: null });

						const data = {
							event: 'checkout',
							ecommerce: {
								checkout: {
									products: [...this.formatDataLayerProducts(products)]
								}
							}
						};
						const user = this.userData && this.userData;
						user && (data.userId = user.id);

						window.dataLayer.push(data);
					} else {
						console.log('no datalayer for checkout');
					}
				},
				addToDataLayer({ products }) {
					if (window.dataLayer) {
						window.dataLayer.push({ ecommerce: null });

						const data = {
							event: 'addToCart',
							ecommerce: {
								currencyCode: 'EUR',
								add: {
									products: [...this.formatDataLayerProducts(products)]
								}
							}
						};
						const user = this.userData && this.userData;
						user && (data.userId = user.id);

						window.dataLayer.push(data);
					} else {
						console.log('no datalayer for addition');
					}
				},
				removeFromDataLayer({ products }) {
					if (window.dataLayer) {
						window.dataLayer.push({ ecommerce: null });

						const data = {
							event: 'removeFromCart',
							ecommerce: {
								remove: {
									products: [...this.formatDataLayerProducts(products)]
								}
							}
						};
						const user = this.userData && this.userData;
						user && (data.userId = user.id);

						window.dataLayer.push(data);
					} else {
						console.log('no datalayer for removal');
					}
				},
				formatDataLayerProducts(products) {
					let cleanProducts = products.map(product => {
						const data = {};

						product.name && (data.name = product.name);
						product.productId && (data.id = product.productId);
						product.price && (data.price = product.price);
						product.shop && (data.brand = product.shop);
						product.type && (data.category = product.type);
						product.amount && (data.quantity = Math.abs(product.amount));
						product.variant && (data.variant = product.variant);
						product.roomPrice && (data.roomPrice = product.roomPrice);

						return data;
					});

					return cleanProducts;
				}
			}
		});
	}
};
