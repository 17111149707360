<template>
	<transition name="fade">
		<div class="save-time-modal" v-if="showModal">
			<div class="save-time-modal__close-container" @click="closeModal">
				<close class="save-time-modal__close" />
			</div>

			<h3 class="save-time-modal__title">{{ $t('modal.save_time.title') }}</h3>

			<p class="save-time-modal__body">{{ $t('modal.save_time.body') }}</p>

			<Button class="button--ini-mini-height" @click.native="login">{{ $t('modal.save_time.cta') }}</Button>
		</div>
	</transition>
</template>

<script>
import close from '../svg/close.vue';
export default {
	name: 'SaveTimeModal',

	component: [close],

	data() {
		return {
			showModal: false,
			initScrollCount: 0
		};
	},

	watch: {
		isUserLoggedIn(val) {
			if (this.isLoginChecked && !val) {
				this.showModal = true;
				return;
			}
			this.showModal = false;
			document.body.classList.remove('save-time-modal-open');
		},
		scrollPosition(val) {
			if (val >= 400) {
				this.showModal = false;
				document.body.classList.remove('save-time-modal-open');
			}
		}
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		}
	},
	methods: {
		login() {
			this.userLogin();
			this.showModal = false;
			document.body.classList.remove('save-time-modal-open');
		},
		closeModal() {
			this.showModal = false;
			document.body.classList.remove('save-time-modal-open');
		}
	},
	mounted() {
		if (
			this.$route.name != 'index' &&
			!this.isUserLoggedIn &&
			this.isLoginChecked &&
			this.shopConfig &&
			this.shopConfig.showSaveTimeModal
		) {
			document.body.classList.add('save-time-modal-open');
			this.showModal = true;
		}
	},
	unmounted() {
		document.body.classList.remove('save-time-modal-open');
	}
};
</script>

<style lang="scss">
$component: 'save-time-modal';

.#{$component} {
	position: fixed;
	bottom: 85px;
	left: 10px;
	width: 200px;
	display: block;
	background-color: var(--card-bg-color);
	color: var(--primary-body-color);
	z-index: 9999;
	padding: 20px;
	opacity: 0.95;

	&__title {
		font-size: 18px;
	}

	&__body {
		font-size: 10px;
		padding: 5px 0;
	}

	&__close-container {
		position: absolute;
		top: 14px;
		right: 20px;
	}

	&__close {
		height: 0.75rem;
		width: 0.75rem;
	}

	@include sm {
		bottom: auto;
		left: 0;
		top: $nav-height-mob;
		width: 100%;
	}
}
</style>
