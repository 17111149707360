<template>

	<Grid class="upsell-check-out" full-width no-margins v-show="filterdProducts.length">

		<Column>

			<h3 class="upsell-check-out__title"> {{ $t('upsellCheckOut.title') }} </h3>

			<div class="upsell-check-out__products-slider">

				<div class="upsell-check-out__nav" v-show="showArrows">

					<iconArrow class="upsell-check-out__arrow" @click.native="goPrev" />

					<iconArrow class="upsell-check-out__arrow upsell-check-out__arrow--right" @click.native="goNext" />

				</div>

				<div class="upsell-check-out__slider" ref="slider">

					<div class="upsell-check-out__slide" v-for="item in sliderItems" :key="item.id">

						<component :is="componentsData(item.type)" :data="item" :type="'checkout'" />

					</div>

				</div>

			</div>

		</Column>

	</Grid>

</template>

<script>
let Flickity;
import MerchandiseItem from '@/components/merchandise/MerchandiseItem';
import ExtraItem from '@/components/extras/ExtraItem';
import TicketItem from '@/components/tickets/TicketItem';
import UpsellTile from '@/components/shop/UpsellTile';
import arraySort from 'array-sort';

export default {
	name: 'UpsellCheckOut',

	data() {
		return {
			expandNotification: false,
			filterdProducts: [],
			sliderWidth: 0
		};
	},

	components: {
		MerchandiseItem,
		ExtraItem,
		TicketItem,
		UpsellTile
	},

	computed: {
		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		products() {
			return this.shopConfig.upsellProductsSummary;
		},

		sliderItems() {
			let items = [];

			if (this.filterdProducts.length) {
				this.filterdProducts.forEach(item => {
					if (item.tileName) {
						items.push({
							...item,
							type: 'upsellTile',
							highLighted: 1,
							isCart: true
						});
					} else {
						let product = this.productMetaData[item.type].find(meta => meta.id === item.id);

						if (product) {
							if (product.type === 'merchandise') {
								const groupId = product.id.split('_')[1];
								product = this.merchandiseGroupedBySizs[groupId];
							}

							if (!items.find(prod => prod.id === product.id)) {
								// No duplicates (from grouped merchandise)

								items.push({
									...product,
									isUpsell: true,
									isHighlight: !!item.highLighted,
									isCart: true
								});
							}
						}
					}
				});

				// arraySort(items, 'highLighted'); //array sorting
			}

			return [...items.filter(item => item.isHighlight), ...items.filter(item => !item.isHighlight)];
		},

		productMetaData() {
			return this.$store.getters['shopapi/getProducts'];
		},

		merchandiseData() {
			let products = [];

			const merchandiseProducts = this.filterdProducts.filter(item => item.type === 'merchandise');

			if (this.productMetaData && this.productMetaData.merchandise) {
				merchandiseProducts.forEach(item => {
					const product = this.productMetaData.merchandise.find(merch => merch.id === item.id);
					product && products.push(product);
				});
			}

			return products;
		},

		merchandiseGroupedBySizs() {
			/*
			 * We need to map the prducts with different sizes into one object with different sizes.
			 * The products comming for the API are not grouped so every prodycts need to be grouped.
			 * The first part of the product ID is uniek for the product, the second part is uniek for the product size (item.id.split('_')[1])
			 */
			let merchandiseProducts = {};

			this.merchandiseData.forEach((item, index) => {
				const id = item.id.split('_')[1];
				const product = merchandiseProducts[id] || { ...item };

				let size;

				if (item.meta && item.meta.vendorVariantTitle && item.meta.vendorVariantTitle != 'Default') {
					size = item.meta.vendorVariantTitle;
				}

				if (size.includes('/')) {
					size = size.replace(/\//g, ':');
				}

				let sizes = {
					id: item.id,
					stock: item.stockAvailable,
					size:
						size.toUpperCase() != this.$t('merchandise.onesize') || size
							? (size.split(':')[1] && size.split(':')[1].replace(/\s/g, '')) || size
							: size
				};

				if (product.sizes) {
					product.sizes.push(sizes);
				} else {
					product.sizes = [sizes];
				}

				merchandiseProducts[id] = product;
			});

			Object.values(merchandiseProducts).forEach(product => {
				arraySort(product.sizes, 'id');
			});

			return merchandiseProducts;
		},

		showArrows() {
			const numItems = this.sliderItems.length;
			return this.sliderWidth < numItems * 200 + (numItems - 1) * 10;
		}
	},

	watch: {
		viewport() {
			this.sliderWidth = this.$refs.slider.offsetWidth;
		}
	},

	methods: {
		componentsData(type) {
			const data = {
				merchandise: 'MerchandiseItem',
				ticket: 'TicketItem',
				upsellTile: 'UpsellTile'
			}[type];
			return data;
		},

		goPrev() {
			this.flickity.previous();
		},

		goNext() {
			this.flickity.next();
		},

		setFilterdProducts() {
			if (this.shopCart && this.shopCart.products) {
				const addedMerchandiseIds = Object.keys(this.shopCart.products).map(id => id.split('_')[1]);

				this.filterdProducts = this.products.filter(item => {
					if (item.type === 'merchandise') {
						const merchItemId = item.id.split('_')[1];

						return !addedMerchandiseIds.includes(merchItemId); // If one size of this product is in cart, hide the whole product. Not just the size in the cart.
					} else {
						return !this.shopCart.products[item.id];
					}
				});
			} else {
				this.filterdProducts = this.products;
			}

			if (!this.flickity) {
				this.$nextTick(() => {
					this.flickity = new Flickity(this.$refs.slider, {
						contain: true,
						cellAlign: 'left',
						prevNextButtons: false,
						pageDots: false,
						dragThreshold: 10,
						initialIndex: 0
					});
				});
			}
		}
	},

	mounted() {
		Flickity = require('flickity');

		this.setFilterdProducts();

		this.$nextTick(() => {
			this.sliderWidth = this.$refs.slider.offsetWidth;
		});
	}
};
</script>

<style lang="scss">
$component: 'upsell-check-out';

.#{$component} {
	margin-top: 20px;
	padding-top: 10px;

	border-top: 1px solid var(--primary-body-color);

	&__title {
		padding-top: $global-spacing;

		font-family: $font-bold;
		font-size: 20px;
		text-transform: uppercase;
	}

	/* &__body {
		padding-bottom: $global-spacing;

		&:after {
			content: '';
			display: block;
			position: relative;

			width: 100%;
			height: 1px;

			margin-top: $global-spacing;

			background: rgba(var(--primary-color), 1);
		}
	} */

	&__slider {
		overflow: hidden;
		position: relative;
		width: 100%;

		min-height: 200px;

		margin: $global-spacing 0;
	}

	&__slide {
		width: 200px;
		margin-right: 10px;

		display: inline-block;
	}

	&__products {
		padding-bottom: $global-spacing;
	}

	&__nav {
		display: flex;
		justify-content: flex-end;
	}

	&__arrow {
		cursor: pointer;

		width: 29px;
		height: 26px;

		padding: 5px 10px;

		&--right {
			transform: rotate(180deg);
		}

		html.no-touchevents &:hover {
			opacity: 0.4;
		}
	}
}
</style>

