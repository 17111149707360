<template>
	<div
		class="cart-notification"
		:class="{ 'cart-notification--expanded': expandNotification }"
		:style="notificationHeight"
		@mouseenter="onMouseEnter"
		@mouseleave="onMouseLeave"
	>
		<div ref="cartInner" class="cart-notification__inner">
			<p class="cart-notification__title">
				<IconCheckRound />
				{{ addedToCart }}
			</p>

			<ButtonSmall
				class="cart-notification__button"
				is-extra-small
				:to="shoppingRoute"
				@click.native="onClickShop"
			>
				{{ $t('label.cart.continue_shopping') }}
			</ButtonSmall>

			<ButtonSmall class="cart-notification__button" is-extra-small is-orange @click.native="onClickCheckout">
				{{ goToCart }}
			</ButtonSmall>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CartNotification',

	data() {
		return {
			isNotificationActive: false,
			isMouseOver: false,
			expandNotification: false,
			timeOut: 0
		};
	},

	computed: {
		goToCart() {
			return this.shopConfig.preregistration ? this.$t('label.go_to_wishlist') : this.$t('label.go_to_checkout');
		},

		addedToCart() {
			return this.shopConfig.preregistration
				? this.$t('label.cart.add_to_wishlist')
				: this.$t('label.cart.add_to_cart');
		},

		notificationHeight() {
			return `max-height: ${
				this.expandNotification ? this.$refs.cartInner.getBoundingClientRect().height : 0
			}px;`;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		totalPrice() {
			return this.shopCart ? this.shopCart.totalAmount : 0;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		paylogicToken() {
			return this.$store.getters['shopconfig/getPaylogictoken'];
		},

		expBuilderRouteName() {
			return `slug`;
		},

		shoppingRoute() {
			return {
				name: this.expBuilderRouteName,
				params: {
					slug: !!this.shopConfig && this.shopConfig.slug
				},
				query: {
					token: this.paylogicToken
				}
			};
		}
	},

	watch: {
		totalPrice(val, oldVal) {
			if (val > oldVal && this.$route.name !== this.expBuilderRouteName) {
				clearTimeout(this.timeOut);
				this.isNotificationActive = true;
				this.expandNotification = true;

				this.timeOut = setTimeout(() => {
					this.isNotificationActive = false;

					if (!this.isMouseOver) {
						this.expandNotification = false;
					}
				}, 3000);
			}
		}
	},

	methods: {
		onClickShop() {
			clearTimeout(this.timeOut);
			this.isNotificationActive = false;
			this.expandNotification = false;
		},

		onClickCheckout() {
			clearTimeout(this.timeOut);
			this.isNotificationActive = false;
			this.expandNotification = false;

			this.$store.commit('setShowCheckout', true);
		},

		onMouseEnter() {
			this.isMouseOver = true;
		},

		onMouseLeave() {
			this.isMouseOver = false;

			if (!this.isNotificationActive) {
				this.expandNotification = false;
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'cart-notification';

.#{$component} {
	overflow: hidden;

	border: 1px solid #5e5e66;
	border-radius: 2px;

	background-image: linear-gradient(
		180deg,
		var(--content-background-dark) 0%,
		var(--content-background-light-2) 100%
	);
	box-shadow: inset 0px 0px 0px 2px $color-black;

	transition: max-height 0.4s $ease-in-out-cubic, opacity 0.3s $ease-in-out-sine;

	color: $color-white;

	&:not(&--expanded) {
		opacity: 0;
		transition: max-height 0.4s $ease-out-cubic, opacity 0.4s $ease-in-out-sine;
	}

	&__inner {
		display: flex;
		align-items: center;
		flex-direction: column;

		padding: 26px 20px;
	}

	&__title {
		margin-bottom: 10px;

		color: #008000;
		font-size: 16px;
		font-family: $font-medium;
		text-align: center;
	}

	&__button.button-small {
		width: 190px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}

	@include sm {
		width: 100%;
	}
}
</style>
