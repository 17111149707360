<template>
	<div class="select-field">
		<label class="select-field__label">{{ value }}</label>

		<select
			class="select-field__select"
			ref="select"
			:name="name"
			v-on:change="updateValue({ value: $event.target.value, key: name }, $event)"
			v-model="selectInternalModel"
			:disabled="disabled"
		>
			<option :selected="true" value="" disabled>{{ label }}</option>

			<slot> </slot>
		</select>
	</div>
</template>

<script>
export default {
	name: 'SelectField',

	props: {
		name: String,
		label: {
			type: String,
			default: 'Make your selection'
		},
		preselected: String,
		valueFromDataAttr: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		data: null
	},

	data() {
		return {
			value: '',
			selectInternalModel: ''
		};
	},

	watch: {
		data() {
			//use data to trigger a refresh of the default values.
			this.setDefaults();
		}
	},

	methods: {
		updateValue(val, raw) {
			this.selectInternalModel = val.value;
			this.value = val.value;

			if (val.value === this.selectDefault) {
				this.$emit('input', { value: 0, key: this.name });
				this.value = val.value;
				return;
			}

			this.value = `${val.value}`;

			if (!this.valueFromDataAttr) {
				this.$emit('input', val);
				return;
			}

			if (!raw) {
				this.$emit('input', {
					value: this.preselected,
					key: this.name
				});
				return;
			}

			this.$emit('input', {
				value: raw.target[raw.target.selectedIndex].getAttribute('data-id'),
				key: this.name
			});
		},

		setDefaults() {
			this.value = this.preselected
				? `${this.preselected} ${this.name.charAt(0).toUpperCase() + this.name.slice(1)}`
				: (this.value = this.label);

			if (this.preselected) {
				this.selectInternalModel = this.preselected;
				this.updateValue({ value: this.preselected, key: this.name });
			}
		},

		// Can be called by parent
		reset() {
			this.setDefaults();
			this.selectInternalModel = '';
		}
	},

	mounted() {
		this.setDefaults();
	}
};
</script>

<style lang="scss">
$component: 'select-field';

.#{$component} {
	position: relative;

	&__select {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	&__label {
		position: relative;
		display: block;

		overflow: hidden;

		height: 50px;

		padding: 0 15px;
		line-height: 50px;
		font-size: 16px;

		background-image: linear-gradient(
			180deg,
			var(--content-background-dark) 0%,
			var(--content-background-light-2) 100%
		);
		border: 1px solid #5e5e66;
		border-radius: 2px;
		box-shadow: inset 0px 0px 0px 2px $color-black;

		color: var(--primary-body-color);
		text-shadow: 0 1px 0 var(--primary-body-color-dark);

		&::placeholder {
			color: $color-white;
		}

		&:after {
			content: '';

			display: block;
			position: absolute;

			right: calc($global-spacing / 2) + 5;
			top: 48%;
			transform: translateY(-50%) rotate(45deg);

			border: solid var(--primary-body-color);
			border-width: 0 1px 1px 0;
			padding: 5px;
		}
	}
}
</style>
