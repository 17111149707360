export default {
	/*
	 * When a user logsout during his session and one if the bottom sales is active force the user to login and redirect to the home route
	 *
	 * Sales options:
	 *
	 * Preregistration
	 * Membersale
	 * Travelsale
	 *
	 */

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		}
	},

	watch: {
		isUserLoggedIn(val) {
			if (!this.shopConfig) {
				return;
			}

			if (
				this.shopConfig.membersale ||
				this.shopConfig.preregistration ||
				this.shopConfig.travelsale ||
				this.shopConfig.loginRequired ||
				this.shopConfig.membershipRequired
			) {
				if (!val) {
					// this.$store.commit('shopconfig/setResetShop');
					// this.$store.commit('shopapi/setResetShop');
					// this.$store.commit('setShowCounter', false);

					// this.$fireErrorEvent(2001);
					this.$fireErrorEventWithPromis(6004).then(response => {
						this.userLogin();
					});
					// this.$router.push('/');
					this.$router.push({
						name: 'slug',
						query: this.$route.query
					});
				}
			}
		}
	}
};
