<template>
	<button
		v-if="isDisabled"
		target="_blank"
		class="button-small button-small--disabled"
		:class="{
			'button-small--orange': isOrange,
			'button-small--extra': isExtraSmall
		}"
		disabled
	>
		<div>
			<slot />
		</div>
	</button>

	<nuxt-link
		v-else-if="to"
		class="button-small"
		:class="{
			'button-small--orange': isOrange,
			'button-small--extra': isExtraSmall
		}"
		:to="{ path: route, query: $route.query }"
	>
		<slot />
	</nuxt-link>

	<a
		v-else-if="external"
		:href="external"
		target="_blank"
		class="button-small"
		:class="{
			'button-small--orange': isOrange,
			'button-small--extra': isExtraSmall
		}"
	>
		<slot />
	</a>

	<button
		v-else
		target="_blank"
		class="button-small"
		:class="{
			'button-small--orange': isOrange,
			'button-small--extra': isExtraSmall
		}"
	>
		<slot />
	</button>
</template>

<script>
export default {
	name: 'ButtonSmall',

	props: {
		to: [String, Object],
		external: String,
		isDisabled: {
			type: Boolean,
			default: false
		},
		isOrange: {
			type: Boolean,
			default: false
		},
		isExtraSmall: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		route() {
			//Adds slug if router has slug to internal nuxt link

			if (typeof this.to === 'string') {
				return this.$route.params.slug ? `/${this.$route.params.slug}/${this.to}` : this.to;
			}

			return this.to;
		}
	}
};
</script>

<style lang="scss">
$component: 'button-small';

.#{$component} {
	position: relative;

	width: 100%;

	padding: 12px 0;

	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	font-family: $font-medium;
	font-size: 16px;
	line-height: 1;

	// background-color: #20202A;
	background-color: rgba(var(--primary-color), 1);

	border: 1px solid #0f0f14;
	border-radius: 2px;

	&:not(&--disabled) {
		html.no-touchevents & {
			transition: all 0.3s $ease-in-out-sine;

			&:hover {
				// background-color: #5e5e66;
				background-color: rgba(var(--primary-color), 1);
				box-shadow: inset 0px 0px 0px 4px $color-black;
			}
		}
	}

	&--extra {
		font-size: 14px;
		padding: 8px 0;
	}

	&--orange {
		background-color: var(--primary-color);
		// border: 1px solid #6E3210;

		html.no-touchevents & {
			&:hover {
				border-color: #0f0f14;
			}
		}
	}

	&--alternative {
		color: var(--button-alternative-text-color);
		background-color: rgba(var(--button-alternative-background-color), 1);
	}

	@include sm {
		font-size: 12px;
	}
}
</style>
