<template>
	<div v-if="isMobile === true">
		<div class="steps-left-progress-bar-mobile">
			<select id="jumpto-select" @change="handleStepChange">
				<option
					v-for="(step, index) in steps"
					:key="index"
					:value="step.path"
					:selected="activeIndex === index"
				>
					{{ step.label }}
				</option>
			</select>
		</div>
	</div>
	<div v-else>
		<div class="steps-left-progress-bar">
			<ol v-if="steps && steps.length > 0">
				<li v-for="(step, index) in steps" :key="index" :class="{ current: activeIndex === index }">
					<template v-if="step.type === 'checkout'">
						<button
							class="steps-left__button"
							:aria-label="`Step ${index + 1} is ${step.completed ? 'complete' : 'incomplete'}`"
							:disabled="activeIndex === index"
							@click="handleCheckoutClick"
						>
							<component class="steps-left__button-icon" :is="`icon-builder-${step.type}`" />
							{{ step.label }}
						</button>
					</template>
					<template v-else>
						<nuxt-link
							:to="{ path: step.path, query: $route.query }"
							class="steps-left__button"
							:aria-label="`Step ${index + 1} is ${step.completed ? 'complete' : 'incomplete'}`"
							:disabled="activeIndex === index"
						>
							<component class="steps-left__button-icon" :is="`icon-builder-${step.type}`" />
							{{ step.label }}
						</nuxt-link>
					</template>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	name: 'StepsLeftProgressBar',
	props: {
		activeIndex: {
			type: Number,
			required: true
		},
		steps: {
			type: Array,
			required: true
		}
	},

	computed: {
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		},

		hasItemsInBasket(item) {
			const activeOptions = this.activeSalesOptions;

			return item => {
				const data = activeOptions.find(option => option.title === item.title);
				return data && data.basketData && data.basketData.length;
			};
		},

		activeSalesOptions() {
			return this.availableSalesOptions.filter(item => item.active);
		},

		availableSalesOptionsFiltered() {
			let optionsFiltered = [...this.availableSalesOptions];
			// console.log(this.previousOrderTravelItems);
			// Filter out tickets step:
			if (this.isMembershipInBasket || this.hasUpgradeModule) {
				optionsFiltered = this.availableSalesOptions.filter(option => option.option !== 'ticket');
			}
			if (Object.keys(this.previousOrderTravelItems).length) {
				if (optionsFiltered) {
					optionsFiltered = optionsFiltered.filter(item => item.option !== 'travel');
				}
			} else {
				optionsFiltered = optionsFiltered.filter(item => item.option !== 'traveladdon');
			}
			// Filter out other steps:
			const { upgradableOrder } = this.shopConfig;
			// console.log(upgradableOrder)
			if (upgradableOrder) {
				const skipSteps = [];
				upgradableOrder.skipTravel && skipSteps.push('travel');
				upgradableOrder.skipBus && skipSteps.push('transportation');
				optionsFiltered = optionsFiltered.filter(option => !skipSteps.includes(option.option));
			}

			return optionsFiltered;
		}
	},

	methods: {
		/**
		 * Handles the step change event when the select dropdown value is changed.
		 * @param {Event} event - The change event object.
		 * @returns {void}
		 */
		handleStepChange(event) {
			if (event.target.value === this.$route.path) return;
			if (event.target.value === '') {
				this.handleCheckoutClick();
			}
			this.$router.push({ path: event.target.value, query: this.$route.query });
		},

		/**
		 * Handles the click event for the checkout button.
		 * Sets the 'showCheckout' state in the Vuex store to true.
		 */
		handleCheckoutClick() {
			this.$store.commit('setShowCheckout', true);
		}
	}
};
</script>

<style lang="scss">
$component: 'steps-left-progress-bar';

$steps-color: var(--progress-bar-font-color);
$steps-background-color: var(--progress-bar-background-color);
$steps-active-font-color: var(--progress-bar-active-font-color);
$steps-active-background-color: var(--progress-bar-active-background-color);
$steps-font-color: var(--progress-bar-font-color);
$steps-font-size: 0.5rem;
$steps-line-height: 1.25;
$steps-height: 1.5rem;
$rounded-borders: true;

.#{$component} {
	label {
		font-size: 0.5rem;
	}

	&-mobile {
		position: relative;

		&::after {
			content: '';
			display: block;
			border: solid $steps-color;
			border-width: 0 3px 3px 0;
			padding: 3px;
			color: $steps-color;
			position: absolute;
			transform: rotate(45deg);
			right: 12px;
			top: 50%;
			margin-top: -6px;
			pointer-events: none;
		}

		select {
			background-color: $steps-background-color;
			border-radius: $steps-height;
			border: 1px solid $steps-color;
			color: $steps-font-color;
			cursor: pointer;
			display: block;
			font-size: 0.75rem;
			line-height: 1;
			padding: 0.5rem 2rem 0.5rem 1rem;
		}
	}

	ol {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		list-style: none;
		text-align: center;
		margin: 0;
		padding: 0;

		li {
			margin: 0;
			padding: 0 0.75rem;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				top: 50%;
				left: 100%;
				transform: translate(-0.75rem, -100%);
				width: 1.5rem;
				height: 1px;
				background: $steps-color;
				z-index: -1;
			}

			&:last-child::after {
				display: none;
			}

			&.current .steps-left__button {
				background-color: $steps-active-background-color;
				color: $steps-active-font-color;
				cursor: default;
			}
		}

		.steps-left__button {
			align-items: center;
			background-color: $steps-background-color;
			border-radius: $steps-height;
			@if $rounded-borders {
				border: 1px solid $steps-color;
			}
			color: $steps-color;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			font-size: $steps-font-size;
			height: $steps-height;
			line-height: $steps-line-height;
			padding: 0.5rem 0.75rem;
			transition: all 0.3s ease-in-out;
			white-space: nowrap;

			&:hover:not([disabled]) {
				background-color: $steps-font-color;
				color: $steps-background-color;
			}

			@media (min-width: 1200px) {
				font-size: 0.75rem;
				padding: 0.75rem 1rem;
			}

			@media (min-width: 1600px) {
				font-size: 1rem;
				padding: 1rem 1rem;
			}
		}
		.steps-left__button-icon {
			max-height: 0.75rem;
			max-width: 0.75rem;
			margin-right: 0.5rem;
		}

		// Reset
		a,
		a:visited {
			text-decoration: none;
			color: $steps-font-color;
		}
	}

	@include sm {
		ol {
			display: none;
		}
	}
}
</style>
