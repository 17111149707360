<template>
	<section class="page">
		<Grid class="page__grid">
			<slot></slot>
		</Grid>
	</section>
</template>

<script>
export default {
	name: 'Page'
};
</script>

<style lang="scss">
$component: 'page';

$top-margin: 100px;
$top-margin-sm: 50px;

.#{$component} {
	padding-bottom: 50px;

	@include md-lg {
		margin-top: $top-margin;
	}
}
</style>
