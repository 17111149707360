<template>
	<div class="upgrade-discount">
		<div class="upgrade-discount__inner" v-if="discountText.description || discountText.price">
			<h2
				class="upgrade-discount__desc"
				v-if="discountText.description_1"
				v-html="discountText.description_1"
			></h2>
			<h2 class="upgrade-discount__price" v-if="discountText.price" v-html="discountText.price"></h2>
		</div>
		<p class="upgrade-discount__warning" v-html="upgradeText && discountText.description_2"></p>
	</div>
</template>

<script>
export default {
	name: 'UpgradeDiscount',

	props: {
		discountText: Object,
		upgradeText: Object
	}
};
</script>

<style lang="scss">
$component: 'upgrade-discount';

.#{$component} {
	padding: 10px;

	font-family: var(--font-bold);

	background-color: $color-orange-defqon;

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		margin-bottom: 5px;
	}

	&__desc {
		padding-right: 10px;
	}

	&__price {
		flex-shrink: 0;
	}

	&__warning {
		font-size: 16px;
		line-height: 1.4;
		font-style: italic;
	}
}
</style>
