<template>

	<div class="basket-upgrades">

		<BasketItem
			v-for="(product, index) in basketItemsData"
			:data="product"
			:key="`upgrade${index}`"
			@remove="onRemove"
		/>

	</div>

</template>

<script>
import BasketItem from './BasketItem';

export default {
	name: 'BasketUpgrades',

	components: { BasketItem },

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		upgradesInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].upgrades || [];
		},

		basketItemsData() {
			return this.upgradesInBasket.map(product => {
				return {
					id: product.type.id,
					title: `${product.numproducts}x&nbsp;&nbsp;${product.type.name}` || '',
					description: product.type.meta.description || '',
					price: product.type.price,
					numberOfProducts: product.numproducts,
					image: product.type.meta.image,
					iconSrc: '/images/upgrade-icon.png',
					hideButtons: true,
					showRemoveButton: true,
					type: 'upgrade',
					data: product
				};
			});
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		}
	},

	methods: {
		onRemove(upgradeId) {
			if (!this.isShopUpdatePending) {
				const payload = {
					url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
					params: {
						productId: upgradeId,
						quantity: 0
					}
				};

				this.$store
					.dispatch(`shopapi/putRequest`, payload)
					.then(response => {
						if (!response.status || response.status !== 200) {
							if (response.data && response.data.error && response.data.error.message) {
								this.$store.commit('setModalMessage', response.data.error.message); // message that gets shown by 8000 error modal
								this.$fireErrorEvent(8000);
							} else {
								console.log('Unknown error response:', response);
							}
						}
					})
					.catch(error => {
						this.apiErrorMsg =
							error.response &&
							error.response.data &&
							error.response.data.error &&
							error.response.data.error.message;

						if (this.apiErrorMsg) {
							this.$store.commit('setModalMessage', error.response.data.error.message); // message that gets shown by 8000 error modal
							this.$fireErrorEvent(8000);
						} else {
							const errorText = this.$t('generalError.body');
							const formatedError = this.apiErrorMsg
								? `${error.response.data.error.message}, cartId:${this.shopCart.id}`
								: `${errorText} cartId:${this.shopCart.id}`;
							this.$fireErrorEvent(formatedError);
						}
					});
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-upgrades';
</style>

