<template>
	<div class="basket-merchandise">
		<BasketItem
			v-for="(ticket, index) in basketItemsData"
			:data="ticket"
			:key="`ticket${index}`"
			@input="onInputChange"
		/>
	</div>
</template>

<script>
import BasketItem from './BasketItem';

export default {
	name: 'BasketMerchandise',

	components: { BasketItem },

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		merchInBasket() {
			const merch = this.$store.getters['shopapi/getMappedProductsInBasket'].merchandise;

			if (merch && merch.length) {
				return merch.filter(item => item.type.name != 'Shipping');
			}

			return [];
		},

		basketItemsData() {
			return this.merchInBasket.map(merch => {
				return {
					id: merch.type.id,
					title: merch.type.name || '',
					description: merch.type.meta.vendorVariantTitle || '',
					price: merch.type.price,
					numberOfProducts: merch.numproducts,
					stock: merch.type.stockAvailable,
					maxAmount: merch.type.maxQuantity,
					image: merch.type.meta.image,
					icon: 'IconBuilderMerchandise',
					type: 'merchandise',
					data: merch
				};
			});
		}
	},

	methods: {
		async onInputChange({ productId, amount }) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: productId,
					quantity: amount
				}
			};

			const addTicketsResult = await this.$store.dispatch(`shopapi/putRequest`, payload);

			if (!addTicketsResult || !addTicketsResult.status || addTicketsResult.status !== 200) {
				if (addTicketsResult.data && addTicketsResult.data.error && addTicketsResult.data.error.message) {
					this.$store.commit('setModalMessage', addTicketsResult.data.error.message); // message that gets shown by 8000 error modal
					this.$fireErrorEvent(8000);
				} else {
					console.log('Unknown error response:', addTicketsResult);
				}
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-merchandise';

.#{$component} {
}
</style>
