<template>
	<div class="basket-transportation">
		<BasketItem
			v-for="(ticket, index) in basketItemsData"
			:data="ticket"
			:key="`ticket${index}`"
			@input="onInputChange"
		/>
	</div>
</template>

<script>
import BasketItem from './BasketItem';
import countryList from '@/static/data/countryCodes.json';

export default {
	name: 'BasketTransportation',

	components: { BasketItem },

	data() {
		return {
			countryList: countryList
		};
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		transportInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].transportation || [];
		},

		basketItemsData() {
			return this.transportInBasket.map(product => {
				// console.log(product.type.meta.hasOwnProperty('image'));
				return {
					id: product.type.id,
					title: (this.shopConfig.festivalData && this.shopConfig.festivalData.title) || '',
					description: this.getTransportDescription(product.type),
					price: product.type.price,
					numberOfProducts: product.numproducts,
					stock: product.type.stockAvailable,
					maxAmount: product.type.maxQuantity,
					image:
						product.type.hasOwnProperty('meta') && product.type.meta.hasOwnProperty('image')
							? product.type.meta.image
							: null,
					icon: 'IconBuilderTransportation',
					hideButtons: !!product.type.slug,
					type: 'transportation',
					data: product
				};
			});
		}
	},

	methods: {
		getTransportDescription(type) {
			if (type.id && type.id.split('_')[0] === 'paylogic') {
				return `${this.getCountry(type)}, ${type.name.split('_')[1]}, ${type.name.split('_')[2]}`;
			} else {
				return type.name;
			}
			// >{{ getCountry(data) }}, {{ data.type.name.split('_')[1] }},
			// {{ data.type.name.split('_')[2] }}</span
			// >
			// <span class="cart-overview__list-value" v-else>{{ data.type.name }}</span>
		},

		getCountry(type) {
			return this.countryList.find(country => country.value === type.name.split('_')[0].toLowerCase()).type;
		},

		async onInputChange({ productId, amount }) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: productId,
					quantity: amount
				}
			};

			const addTicketsResult = await this.$store.dispatch(`shopapi/putRequest`, payload);

			if (!addTicketsResult || !addTicketsResult.status || addTicketsResult.status !== 200) {
				if (addTicketsResult.data && addTicketsResult.data.error && addTicketsResult.data.error.message) {
					this.$store.commit('setModalMessage', addTicketsResult.data.error.message); // message that gets shown by 8000 error modal
					this.$fireErrorEvent(8000);
				} else {
					console.log('Unknown error response:', addTicketsResult);
				}
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-transportation';

.#{$component} {
}
</style>
