<template>
	<div class="shop-status"></div>
</template>

<script>
import moment from 'moment';

export default {
	/*
	 * Poll a json file for the API status only used when shit goes really wrong..
	 */
	name: 'ShopStatus',

	methods: {
		startPolling() {
			const url = `https://app.q-dance.com/notice?t=${moment().hour()}${moment().minutes()}`;

			this.$axios
				.get(url)
				.then(response => {
					const data = JSON.parse(response.data);

					if (data.message.title) {
						this.$store.commit('setModalMessage', data.message.error); // message that gets shown by 8000 error modal
						this.$fireErrorEvent(8002);
					}

					this.timeOut();
				})
				.catch(error => {
					console.log('shopstatus call failed');

					this.timeOut();
				});
		},

		timeOut() {
			setTimeout(() => {
				this.startPolling();
			}, 60000);
		}
	},

	mounted() {
		this.startPolling();
	}
};
</script>

<style lang="scss">
$component: 'shop-status';

.#{$component} {
	position: absolute;

	display: none;
	width: 0;
	height: 0;
}
</style>
