<template>
	<div class="toast-message" v-if="getProducts" v-show="showMessage">
		<div class="toast-message__box">
			<div class="toast-message__message" v-html="message"></div>

			<ButtonSmall class="shop-modal__button" @click.native="closeToast">
				<span>{{ $t('showmessageformissingproduct.close') }}</span>
			</ButtonSmall>
		</div>
	</div>
</template>

<script>
let Flickity;

export default {
	name: 'ShowMessageForMissingProduct',

	data() {
		return {
			showMessage: false,
			message: null,
			tempBasket: null
		};
	},

	components: {},

	computed: {
		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		getProducts() {
			return this.$store.getters['shopapi/getProducts'];
		},
		getShopCart() {
			return this.$store.getters['shopconfig/getCart'];
		}
	},

	watch: {
		'getShopCart.products': {
			deep: true,
			handler(newVal, oldval) {
				if (newVal && oldval) {
					if (this.getProducts && Object.keys(this.productsInBasket).length && !this.showMessage) {
						const extra = this.getProducts.extras.filter(item => item.meta.isConditionalProduct === true);
						const membership = this.getProducts.membership.filter(
							item => item.meta.isConditionalProduct === true
						);
						const merchandise = this.getProducts.merchandise.filter(
							item => item.meta.isConditionalProduct === true
						);
						const transportation = this.getProducts.transportation.filter(
							item => item.meta.isConditionalProduct === true
						);
						const data = extra.concat(membership, merchandise, transportation);

						for (const key in newVal) {
							if (!oldval.hasOwnProperty(key)) {
								const item = data.find(item => item.id === key);
								if (item) {
									this.message = item.meta.hasOwnProperty('conditionalMessage')
										? item.meta.conditionalMessage
										: 'conditionalMessage not set';
								}
							}
						}

						if (!this.productsInBasket.hasOwnProperty('ticket') && this.message) {
							this.showMessage = true;
							return;
						}

						this.checkForProductDisclaimer(newVal, oldval);
					}
				}
			}
		}
	},
	methods: {
		checkForProductDisclaimer(newVal, oldval) {
			const extra = this.getProducts.extras.filter(item => item.meta.productDisclaimer);
			const membership = this.getProducts.membership.filter(item => item.meta.productDisclaimer);
			const merchandise = this.getProducts.merchandise.filter(item => item.meta.productDisclaimer);
			const transportation = this.getProducts.transportation.filter(item => item.meta.productDisclaimer);
			const data = extra.concat(membership, merchandise, transportation);

			for (const key in newVal) {
				if (!oldval.hasOwnProperty(key)) {
					const item = data.find(item => item.id === key);
					if (item) {
						this.message = item.meta.productDisclaimer;
						this.showMessage = true;
						return;
					}
				}
			}
		},
		closeToast() {
			this.message = null;
			this.showMessage = false;
		}
	}
};
</script>

<style lang="scss">
$component: 'toast-message';

.#{$component} {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 100000;

	width: 100vw;
	height: 100vh;

	background: rgba(var(--content-background-rgb), 0.9);

	&__box {
		// min-width: 320px;
		padding: 30px;

		text-align: center;

		border: 1px solid #5e5e66;
		border-radius: 2px;
		box-shadow: inset 0px 0px 0px 2px $color-black;
		background-image: linear-gradient(
			180deg,
			var(--content-background-dark) 0%,
			var(--content-background-light-2) 100%
		);
	}

	&__title {
		margin-bottom: 25px;
	}

	&__message {
		margin-bottom: 25px;

		font-size: 14px;
	}

	&__button {
		width: 150px;
		margin-bottom: 10px;
	}

	@include md-lg {
		&__box {
			width: 25%;
			min-width: 540px;
		}

		&__button {
			width: 150px;
		}
	}

	@include sm {
		padding: $global-spacing;

		&__box {
			max-width: 540px;
		}
	}
}
</style>
