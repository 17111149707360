<template>
	<component
		:is="tag"
		:class="{
			'is-centered': centered,
			'is-fluid': fluid,
			'full-width': fullWidth,
			'no-margins': noMargins,
			's-no-margins': sNoMargins,
			'm-no-margins': mNoMargins,
			'l-no-margins': lNoMargins
		}"
		class="grid-container"
	>
		<div
			ref="innerGrid"
			:style="{
				flexDirection: reversed ? 'row-reverse' : 'row'
			}"
			class="grid"
		>
			<slot />
		</div>
	</component>
</template>

<script>
export default {
	props: {
		tag: {
			type: String,
			default: 'div'
		},
		centered: {
			type: Boolean,
			default: true
		},
		fluid: {
			type: Boolean,
			default: false
		},
		fullWidth: {
			type: Boolean,
			default: false
		},
		reversed: {
			type: Boolean,
			default: false
		},

		noMargins: Boolean,
		sNoMargins: Boolean,
		mNoMargins: Boolean,
		lNoMargins: Boolean
	}
};
</script>

<style lang="scss">
$component: 'grid';

.grid-container {
	width: 100%;
	max-width: $max-width;

	&.is-fluid {
		max-width: 100%;
	}

	&.is-centered {
		margin-left: auto;
		margin-right: auto;
	}

	&:not(.full-width) {
		padding-left: $global-spacing;
		padding-right: $global-spacing;

		@include sm {
			padding-left: $global-spacing--mobile;
			padding-right: $global-spacing--mobile;
		}
	}

	&.no-margins .grid {
		margin-left: -$global-spacing;
		margin-right: -$global-spacing;

		@include sm {
			margin-left: -$global-spacing--mobile;
			margin-right: -$global-spacing--mobile;
		}
	}

	@include lg {
		&.l-no-margins .grid {
			margin-left: -$global-spacing;
			margin-right: -$global-spacing;
		}
	}

	@include md-lg {
		&.m-no-margins .grid {
			margin-left: -$global-spacing;
			margin-right: -$global-spacing;
		}
	}

	@include sm {
		&.s-no-margins .grid {
			margin-left: -$global-spacing--mobile;
			margin-right: -$global-spacing--mobile;
		}
	}
}

.#{$component} {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
}
</style>
