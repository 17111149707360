<template>
	<div
		class="ticket-item"
		:class="{ 'ticket-item--upsell': data.isUpsell, 'ticket-item--highlight': data.isHighlight }"
	>
		<div v-if="data.meta.image" class="ticket-item__image" :class="{ 'md-lg-only': data.hideVisualOnMobile }">
			<img :src="cdnUrl(data.meta.image)" :alt="data.meta.description" />
		</div>

		<div class="ticket-item__content">
			<h3 class="ticket-item__title">{{ data.name }}</h3>

			<template v-if="showsoldoutWithPrice">
				<h3 class="ticket-item__price">
					{{ price }}
					<span
						class="ticket-item__fee"
						v-if="!hideServiceFee && showHideServiceFees && data.meta.calculateTouristTax"
					>
						{{ $t('page.tickets.includesFee') }}
					</span>

					<span
						class="ticket-item__fee"
						v-if="!hideServiceFee && showHideServiceFees && !data.meta.calculateTouristTax"
					>
						{{ $t('page.tickets.includesFeeAndTax') }}
					</span>
				</h3>

				<p class="ticket-item__fee 3" v-if="!showHideServiceFees && data.meta.calculateTouristTax">
					<span v-if="!hideServiceFee">{{ this.priceBreakdown }}</span>

					<span class="1" v-if="!hideServiceFee && !showHideServiceFees">
						+ {{ this.formatPrice(this.data.serviceCosts).concat(' ', $t('checkout.order_fee')) }}
					</span>

					<span class="2" v-if="!data.meta.calculateTouristTax">
						+
						{{
							this.formatPrice(data.meta.touristTaxAmount).concat(
								' ',
								this.$t('checkout.tourist_tax.title')
							)
						}}
					</span>

					<span class="3" v-if="!data.meta.mentionEntertainmentFee">
						+
						{{
							this.formatPrice(data.meta.entertainmentFeeAmount).concat(
								' ',
								$t('checkout.entertainment_tax.title')
							)
						}}
					</span>

					<span class="4" v-if="hideServiceFee && !!data.meta.additionalTicketText">
						{{ data.meta.additionalTicketText }}
					</span>
				</p>
			</template>

			<div class="ticket-item__description" v-if="data.meta.description" v-html="data.meta.description"></div>

			<template v-if="!data.meta.overrideSoldout">
				<div
					class="ticket-item__item-container"
					:class="{ 'ticket-item__item-container--stepper': useStepper }"
					v-if="!data.soldOut && data.stockAvailable > 0 && !data.isUpsell"
				>
					<div v-if="data.meta.perks && data.meta.perks.length">
						<div class="ticket-item__includes-wrapper" @click="onToggleusp()">
							<p class="ticket-item__includes">{{ $t('page.tickets.includes') }}</p>

							<arrow-check
								class="ticket-item__icon-check-down"
								:class="{ 'ticket-item__icon-check-down--up': isExpandUsp }"
							></arrow-check>
						</div>

						<ul class="ticket-item__perks" v-show="isExpandUsp">
							<li class="ticket-item__perk" v-for="(perk, index) in data.meta.perks" :key="index">
								<arrow-check class="ticket-item__icon-check"></arrow-check>

								<span>{{ perk }}</span>
							</li>
						</ul>
					</div>

					<template v-if="!useStepper">
						<SelectFieldSimple
							class="ticket-item__input"
							v-model="selectedTickets"
							:isDisabled="isShopUpdatePending"
							:data="ticketFilterInput"
						/>

						<Button
							class="button--alternative-second button--small"
							:class="{
								'button--disabled': this.selectedTickets === ''
							}"
							@click.native="addTicketsToCart"
							v-if="!isShopUpdatePending"
						>
							<span>{{ buttonLabel }}</span>
						</Button>

						<div v-else>
							<Spinner />
						</div>

						<p class="ticket-item__max">
							<span v-if="data.minQuantity">Min {{ data.minQuantity }} p.p. & </span>
							Max {{ calculateMaxtickets(data) }} p.p.
						</p>
					</template>

					<div class="ticket-item__stepper-container" v-if="useStepper">
						<InputNumberField
							v-model="numberOfProducts"
							:isDisabled="isShopUpdatePending"
							:stock="data.stockAvailable"
							:max-value="ticketFilterInputStepper"
							:min-value="data.minQuantity"
							:step-size="data.stepSize"
							class="ticket-item__stepper"
							:type="'ticket-stepper'"
							isSmall
							v-if="!isShopUpdatePending"
						/>

						<div v-else>
							<Spinner />
						</div>

						<p class="ticket-item__max">
							<span v-if="data.minQuantity">Min {{ data.minQuantity }} p.p. & </span>
							Max {{ calculateMaxtickets(data) }} p.p.
						</p>
					</div>
				</div>

				<div
					v-else-if="(data.soldOut || data.stockAvailable < 1) && isTravelTicketAvailable && !data.isUpsell"
					class="ticket-item__sold-out"
				>
					<!-- <Button class="button--alternative-second button--small button--disabled">
					<span>Sold out</span>
				</Button> -->

					<Button
						class="ticket-item__button button--alternative-second button--small"
						:to="{
							name: 'slug-travel',
							params: {
								slug: $route.params.slug
							}
						}"
					>
						<span>{{ $t('page.travel.available') }}</span>
					</Button>

					<p class="ticket-item__sold-out ticket-item__sold-out--p">
						{{ data.name }}{{ $t('page.tickets.sold_out') }}
						<nuxt-link
							:to="{
								name: 'slug-travel',
								params: {
									slug: $route.params.slug
								},
								query: $route.query
							}"
						>
							{{ $t('page.tickets.sold_out_travel') }}
						</nuxt-link>
					</p>
				</div>

				<Button
					v-else-if="(data.soldOut || data.stockAvailable < 1) && !data.isUpsell"
					class="button--alternative-second button--small button--disabled"
				>
					<span>{{ $t('page.tickets.sold_out') }}</span>
				</Button>
			</template>

			<template v-else>
				<template v-if="data.meta.soldOutTravelCtaText && data.meta.soldOutText">
					<p class="ticket-item__sold-out ticket-item__sold-out--p" v-html="data.meta.soldOutText"></p>

					<Button
						class="ticket-item__button button--alternative-second button--small"
						:to="{
							name: 'slug-travel',
							params: {
								slug: $route.params.slug
							}
						}"
					>
						<span>{{ data.meta.soldOutTravelCtaText }}</span>
					</Button>
				</template>

				<template v-else>
					<p class="ticket-item__sold-out ticket-item__sold-out--p" v-html="data.meta.soldOutText"></p>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
import InputNumberField from '@/components/ui/InputNumberField';
import SelectFieldSimple from '@/components/ui/SelectFieldSimple';
import { flatMapDeep } from 'lodash';
// import { data } from 'flickity/js/flickity';

export default {
	name: 'TicketItem',

	props: {
		data: {
			type: Object,
			default: null
		}
	},

	data() {
		return {
			selectedTickets: '',
			isExpandUsp: false
		};
	},

	components: {
		InputNumberField,
		SelectFieldSimple
	},

	watch: {
		ticketsInCart(val) {
			if (val) {
				const isTicket = val.find(item => item.type.id === this.data.id);

				if (!isTicket) {
					this.selectedTickets = '';
				}
			}
		}
	},

	computed: {
		allowedExtraTickets() {
			return this.shopConfig.shopSettings.fields.allowedExtraTickets;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		expandUspDefault() {
			return this.shopConfig.shopSettings.fields.showHideTicketUsp || false;
		},

		showsoldoutWithPrice() {
			if (this.data.soldOut) {
				if (!this.shopConfig.soldoutPrice) {
					return false;
				}
				return true;
			}
			return true;
		},
		hideServiceFee() {
			if (this.data.meta.mergeServiceFees === 'Hide service fee') {
				return true;
			}
			return false;
		},

		showHideServiceFees() {
			if (this.data.meta.mergeServiceFees) {
				if (this.data.meta.mergeServiceFees === 'Show service fee as text') {
					return true;
				}
				if (this.data.meta.mergeServiceFees === 'Show service fee as price') {
					return false;
				}
			}

			if (!this.data.meta.mergeServiceFees) {
				return this.data.meta.serviceFees;
			}

			return false;
		},

		ossConfig() {
			return this.$store.getters.getOssConfig;
		},

		ticketsInCart() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].ticket;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInCart() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		},

		useStepper() {
			return this.shopConfig.useStepper;
		},

		buttonLabel() {
			const allowedToadd =
				this.selectedTickets >= 1 && this.selectedTickets != this.shopCart.products[this.data.id];
			const label = allowedToadd
				? `${this.$t('ticketitem.addtocart.add')} ${this.selectedTickets} ticket${
						this.selectedTickets > 1 ? 's' : ''
				  } ${this.$t('ticketitem.addtocart.to')} ${
						this.shopConfig.preregistration
							? this.$t('ticketitem.addtocart.wishlist')
							: this.$t('ticketitem.addtocart.cart')
				  }`
				: this.$t('ticketitem.removeticket');
			return this.selectedTickets !== '' ? label : this.$t('ticketitem.selectyourticket');
		},

		ticketFilterInputStepper() {
			return this.calculateMaxtickets(this.data) >= this.data.stockAvailable
				? this.data.stockAvailable
				: this.calculateMaxtickets(this.data);
		},

		ticketFilterInput() {
			const options = [
				{
					value: '',
					label: this.$t('ticketitem.makeyourselection')
				}
			];

			const totalTickets =
				this.calculateMaxtickets(this.data) >= this.data.stockAvailable
					? this.data.id == 'paylogic_c041caf6f89245c5a557a59e664936c7'
						? this.data.stock
						: this.data.stockAvailable
					: this.calculateMaxtickets(this.data);

			this.data;
			for (
				let i = Math.max(this.data.minQuantity, this.data.meta.minimumQuantity, 1);
				i < totalTickets + 1;
				i++
			) {
				options.push({
					label: `${i} Ticket${i > 1 ? 's' : ''}`,
					value: String(i)
				});
			}

			return {
				options
			};
		},

		ticketsData() {
			return this.$store.getters['shopapi/getProducts'] && this.$store.getters['shopapi/getProducts'].ticket;
		},

		isTravelTicketAvailable() {
			return this.ticketsData.some(item => {
				item.categories &&
					item.stockAvailable >= 1 &&
					item.categories.some(ticket => ticket.en === 'TRAVEL TICKETS' || ticket.nl === 'TRAVEL TICKETS');
			});
		},
		price() {
			// this.data.meta.serviceFees = false
			// this.data.meta.calculateTouristTax = true
			// this.data.meta.touristTaxAmount = 10.95

			const taxBool = this.data.meta.calculateTouristTax;
			const taxAmount = this.data.meta.touristTaxAmount;
			const serviceBool = this.data.meta.serviceFees;
			const serviceExPrice = this.data.priceExcludingServiceCosts;
			const fullPrice = this.data.price;

			let curPrice = fullPrice;

			// if(taxBool === true && serviceBool === true){
			// 	curPrice = fullPrice
			// } else if(taxBool === true && serviceBool === false){
			// 	curPrice = fullPrice
			// } else if(taxBool === false && serviceBool === true){
			// 	curPrice = fullPrice
			// } else if(taxBool === false && serviceBool === false){
			// 	curPrice = fullPrice
			// }

			return this.formatPrice(curPrice);
		},

		priceBreakdown() {
			const taxBool = this.data.meta.calculateTouristTax;
			const taxAmount = this.data.meta.touristTaxAmount;
			const serviceBool = this.data.meta.serviceFees;
			const serviceExPrice = this.data.priceExcludingServiceCosts;
			const fullPrice = this.data.price;

			let curPrice = 0;

			if (taxBool === true && serviceBool === true) {
				curPrice = fullPrice;
			} else if (taxBool === true && serviceBool === false) {
				curPrice = serviceExPrice;
			} else if (taxBool === false && serviceBool === true) {
				curPrice = fullPrice - taxAmount;
			} else if (taxBool === false && serviceBool === false) {
				curPrice = serviceExPrice - taxAmount;
			}

			return this.formatPrice(curPrice);
		},

		numberOfProducts: {
			get() {
				return this.shopCart.products[this.data.id] || 0;
			},

			set(val) {
				this.selectedTickets = val;
				this.addTicketsToCart();

				const tempData = {
					name: this.data.meta.name,
					price: this.data.price,
					productId: this.data.id,
					amount: val - this.numberOfProducts,
					shop: this.$store.getters['shopconfig/getData'].titleFull,
					type: this.data.mappedType
				};
				// if (val - this.numberOfProducts == 1) {
				// 	this.addToDataLayer({
				// 		products: [tempData]
				// 	});
				// } else {
				// 	this.removeFromDataLayer({
				// 		products: [tempData]
				// 	});
				// }
			}
		}
	},

	methods: {
		async addTicketsToCart() {
			const allowedToadd =
				this.selectedTickets >= 1 && this.selectedTickets != this.shopCart.products[this.data.id]; // Allowed to add when number of selected tickets is larger than amount already in cart.
			const numtickets = allowedToadd ? this.selectedTickets : 0; // If not adding, we're removing.
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: this.data.id,
					quantity: numtickets
				},
				datalayerQuantity: this.useStepper ? 1 : this.selectedTickets
			};

			// console.log(this.data);

			const addTicketsResult = await this.$store.dispatch(`shopapi/putRequest`, payload);

			if (!allowedToadd) {
				// this.numberOfTickets = 0;
				this.selectedTickets = '';
			}

			if (!addTicketsResult || !addTicketsResult.status || addTicketsResult.status !== 200) {
				if (addTicketsResult.data && addTicketsResult.data.error && addTicketsResult.data.error.message) {
					this.$store.commit('setModalMessage', addTicketsResult.data.error.message); // message that gets shown by 8000 error modal
					this.$fireErrorEvent(8000);
				} else {
					console.log('Unknown error response:', addTicketsResult);
					const formatedError = `Probeer het opnieuw. Refresh deze pagina en sluit - indien nodig - aan in de wachtrij om opnieuw in de ticketshop te komen. cartId:${this.shopCart.id}`;
					this.$fireErrorEvent(formatedError);
				}
			}
		},

		calculateMaxtickets(ticketItem) {
			//Special hack for zwartecross (now used for all shops) see https://egotribe.atlassian.net/browse/REQ-363 for an detailed explanaition
			const isSpecialTicket = this.allowedExtraTickets && this.allowedExtraTickets[0].includes(ticketItem.id);
			let { ticket } = this.productsInCart;
			if (!isSpecialTicket) {
				//remove the special tickets from the regular tickets
				const filteredTickets =
					ticket &&
					ticket.filter(
						item => this.allowedExtraTickets && !this.allowedExtraTickets[0].includes(item.type.id)
					);
				let maxAmount = ticketItem.maxQuantity;
				let minAmount = ticketItem.minQuantity;

				let amount = minAmount ? minAmount : 0;
				if (filteredTickets) {
					filteredTickets.forEach(item => {
						if (ticketItem.id != item.type.id) {
							amount = amount + parseInt(item.numproducts);
						}
					});
					if (filteredTickets.length + ticketItem.minQuantity > this.shopConfig.maxTicketAmount) {
						return 0;
					}
				}
				let maxTicketsToBuy = Math.min(this.shopConfig.maxTicketAmount + 1 - amount, maxAmount);
				return maxTicketsToBuy <= 0 ? 0 : maxTicketsToBuy;
			} else {
				let maxAmount = this.allowedExtraTickets && this.allowedExtraTickets[1].maxQuantity;
				let minAmount = 0;
				let amount = minAmount ? minAmount : 0;
				if (ticket) {
					const filteredTickets = ticket.filter(
						ticket =>
							this.allowedExtraTickets &&
							this.allowedExtraTickets[0].some(item => item === ticket.type.id)
					);
					if (filteredTickets.length) {
						filteredTickets.forEach(item => {
							if (ticketItem.id != item.type.id) {
								amount = amount + parseInt(item.numproducts);
							}
						});
						if (filteredTickets.length + ticketItem.minQuantity > this.allowedExtraTickets[1].maxQuantity) {
							return 0;
						}
					}
				}
				//this.allowedExtraTickets[1].maxQuantity, maxQuantity is set in the CMS, and is used to override the ticket maxQuantity set by the API
				let maxTicketsToBuy = Math.min(this.allowedExtraTickets[1].maxQuantity - amount, maxAmount);
				return maxTicketsToBuy;
			}
		},

		formatPrice(val) {
			return this.$options.filters.formatPrice(val);
		},

		onToggleusp() {
			this.isExpandUsp = !this.isExpandUsp;
		}
	},

	mounted() {
		if (this.shopCart.products[this.data.id]) {
			this.selectedTickets = this.shopCart.products[this.data.id].toString();
		}

		this.isExpandUsp = this.expandUspDefault;

		const isSpecialTicket = this.allowedExtraTickets && this.allowedExtraTickets[0].includes(this.data.id);
		if (isSpecialTicket) {
			this.data.maxQuantity = this.allowedExtraTickets[1].maxQuantity;
		}
	}
};
</script>

<style lang="scss">
$component: 'ticket-item';

.#{$component} {
	font-size: 14px;

	border-radius: 3px;

	background-color: var(--card-bg-color);

	box-shadow: 0 2px 20px rgba(black, 0.5);

	&--highlight {
		width: 200px;
	}

	&--upsell {
		height: 328px; // equal to upsell merchandise item height

		padding: 4px;
		border: 2px solid $color-white;

		color: $color-black;

		background: $color-white;
		box-shadow: inset 0px 0px 0px 2px #c5c5c5;

		.#{$component} {
			&__description {
				display: none;
			}

			&__content {
				padding: 5px;
			}
			&__title {
				margin-bottom: 0;
				font-family: $font-regular;
				font-size: 18px;
			}
			&__price {
				margin-bottom: 0;

				font-family: $font-regular;
				font-size: 18px;
				line-height: 1.7;
			}
		}
	}

	&__image {
		line-height: 0;
	}

	&__content {
		display: flex;
		flex-direction: column;
		padding: $global-spacing;
		height: 100%;
	}

	&__item-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		&--stepper {
			justify-content: space-between;
			height: 100%;
		}
	}

	&__title {
		margin-bottom: calc($global-spacing / 2);
		text-transform: uppercase;
	}

	&__price {
		margin-bottom: calc($global-spacing / 2);

		.#{$component}__fee {
			font-size: 12px;
			font-family: $font-regular;
			font-weight: normal;
		}
	}

	&__fee {
		font-size: 12px;
		margin-bottom: $global-spacing;
	}

	&__input {
		margin-bottom: calc($global-spacing / 2);
	}

	&__max {
		padding-top: 5px;
		margin-bottom: $global-spacing * 1.5;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__includes {
		font-size: 16px;
		text-transform: uppercase;
	}

	&__includes-wrapper {
		cursor: pointer;
		display: flex;
		align-items: center;
		// justify-content: space-between;
		margin-bottom: $global-spacing * 0.75;
	}

	&__icon-check-down {
		margin-left: 15px;
		transition: transform 0.2s ease-in-out;
		transform: rotate(90deg);
		&--up {
			transform: rotate(-90deg);
		}
	}

	&__perks {
		padding-bottom: $global-spacing;
	}

	&__perk {
		display: flex;
	}

	&__icon-check {
		margin: 5px 10px 0 0;
		flex-shrink: 0;
	}

	&__sold-out {
		// font-family: $font-bold;

		margin-bottom: calc($global-spacing / 2);

		> button {
			font-family: $font-bold;
		}

		&--p {
			margin: 10px 0;
			> a {
				// text-decoration: none;
			}
		}
	}

	&__button {
		display: block;
		margin-top: 10px;
	}

	&__description {
		padding-bottom: calc($global-spacing / 2);
	}

	&__stepper-container {
	}

	&__stepper {
	}

	@include sm {
		max-width: $max-mobile-col-width;

		margin-left: auto;
		margin-right: auto;

		&--upsell {
			height: 321px;
		}
	}
}
</style>
