<template>
	<button v-if="isDisabled" :class="classes" class="button button--disabled" disabled>
		<div>
			<slot />
		</div>
	</button>

	<nuxt-link v-else-if="to" class="button" :class="classes" :to="{ path: route, query: $route.query }">
		<slot> </slot>
	</nuxt-link>

	<a v-else-if="external" :href="external" :target="target" class="button" :class="classes">
		<slot> </slot>
	</a>

	<a v-else-if="regular" class="button" :class="classes">
		<slot> </slot>
	</a>

	<button v-else target="_blank" class="button" :class="classes">
		<slot> </slot>
	</button>
</template>

<script>
import preserveUTMParams from '@/utils/preserveUTMParams';
export default {
	name: 'Button',

	props: {
		to: [String, Object],
		external: String,
		isDisabled: {
			type: Boolean,
			default: false
		},
		target: {
			type: String,
			default: '_blank'
		},
		isNavBarButton: {
			type: Boolean,
			default: false
		},
		isPrimary: {
			type: Boolean,
			default: false
		},
		regular: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		route() {
			//Adds slug if router has slug to internal nuxt link

			if (typeof this.to === 'string' && this.$route.params.slug) {
				return `/${this.$route.params.slug}/${this.to}`;
			}

			return this.to;
		},

		customColorStyle() {
			if (this.customColor) {
				return `background-color: ${this.customColor}`;
			}
			return '';
		},

		classes() {
			let classes = '';
			this.isPrimary && (classes += ' button--primary');
			this.isNavBarButton && (classes += ' button--navbar');

			return classes;
		}
	},
	methods: {
		preserveUTMParams
	}
};
</script>

<style lang="scss">
$component: 'button';

.#{$component} {
	position: relative;

	width: 100%;
	height: 70px;

	padding: 24px 4px;

	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	font-family: $font-medium;
	font-size: 14px;

	border: 1px solid #5e5e66;
	box-shadow: inset 0px 0px 0px 2px $color-black;
	text-shadow: 0 1px 0 var(--primary-body-color-dark);

	color: var(--button-alternative-text-color);

	z-index: 5;

	&:not(&--disabled) {
		html.no-touchevents & {
			transition: all 0.3s $ease-in-out-sine;

			&:hover {
				background-color: rgba(var(--primary-color), 1);
				box-shadow: inset 0px 0px 0px 7px $color-black;

				&:after {
					opacity: 0;
				}
			}
		}
	}

	&--disabled {
		cursor: default;
		pointer-events: none;

		> * {
			opacity: 0.4;
		}
	}

	&:after {
		content: '';
		position: absolute;

		width: 100%;
		height: 100%;

		opacity: 1;

		left: 0;
		top: 0;

		z-index: -1;

		// background-image: linear-gradient(var(--content-background-dark), var(--content-background-light-2));
		box-shadow: inset 0px 0px 0px 2px $color-black;
		background-color: rgba(var(--button-alternative-background-color), 1);

		transition: opacity 0.3s $ease-in-out-sine;
	}

	&--primary {
		&:after {
			background-image: none;
			background-color: rgba(var(--primary-color), 1);
		}

		/* &:not(.button--disabled):hover {
			html.no-touchevents & {
				background-color: var(--content-background-dark);
			}
		} */
	}

	&--navbar {
		color: var(--navbar-button-text-color);
		&:after {
			background-image: linear-gradient(var(--navbar-button--dark), var(--navbar-button-color));
		}
	}

	&--defqon {
		border: none;
	}

	&--alternative {
		color: var(--button-alternative-text-color);
		background-color: rgba(var(--button-alternative-background-color), 1);

		border: 1px solid $color-black;
		box-shadow: none;
		border-radius: 0;

		&:after {
			display: none;
		}
	}

	&--alternative-green {
		color: var(--primary-body-color);

		background-color: $color-green;
		border: 1px solid $color-black;
		box-shadow: none;
		border-radius: 0;

		&:after {
			display: none;
		}
	}

	&--alternative-red {
		color: var(--primary-body-color);

		background-color: $color-red;
		border: 1px solid $color-black;
		box-shadow: none;
		border-radius: 0;

		&:after {
			display: none;
		}
	}

	&--alternative-second {
		color: var(--button-alternative-text-color);

		border: 1px solid #5e5e66;
		border-radius: 2px;
		box-shadow: inset 0px 0px 0px 2px $color-black;

		&:not(.button--disabled) {
			background-color: rgba(var(--button-alternative-background-color), 1);

			&:after {
				display: none;
			}
		}
	}

	&--alternative-third {
		color: var(--primary-body-color);

		border: 1px solid #5e5e66;
		border-radius: 2px;
		box-shadow: inset 0px 0px 0px 2px $color-black;
		background: var(--content-background);

		&:not(.button--disabled) {
			&:after {
				display: none;
			}
		}
	}

	&--filter {
		background-color: rgba(var(--primary-color), 1);
		box-shadow: inset 0px 0px 0px 7px $color-black;

		border: 1px solid #5e5e66;

		&:after {
			opacity: 0;
		}
	}

	&--small {
		height: 50px;
		padding: 16px 0;
		line-height: 15px;
	}

	&--mini {
		width: 200px;
		height: 50px;
		padding: 16px 0;
	}

	&--ini-mini {
		height: 25px;
		padding: 5px 0;
		line-height: 0;

		&:hover {
			background-color: rgba(var(--primary-color), 1);
			box-shadow: inset 0px 0px 0px 2px $color-black !important;
		}
	}

	&--ini-mini-height {
		display: flex;
		justify-content: center;
		align-items: center;

		height: auto;

		padding: 8px 4px;
		line-height: 1.2;

		&:hover {
			background-color: rgba(var(--primary-color), 1);
			box-shadow: inset 0px 0px 0px 2px $color-black !important;
		}
	}

	@include sm-md {
		&--ini-mini-height {
			height: 50px;
			padding: 10px 0;
			line-height: 15px;
		}
	}

	@include sm {
		font-size: 12px;

		&--navbar {
			height: 40px;

			padding: 10px 0;
		}
	}
}
</style>
