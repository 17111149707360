<template>
	<Grid v-if="isOrderFee || !!isOrderFeeWishlist" class="order-fee" full-width no-margins>
		<Column :s="6">
			<span>{{ $t('checkout.service_fee') }}</span>
		</Column>

		<Column :s="6">
			<h3 class="order-fee__price">{{ orderFee | formatPrice }}</h3>
		</Column>
	</Grid>
</template>

<script>
export default {
	name: 'BasketOrderFee',
	data() {
		return {
			hasProductFee: false
		};
	},
	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		ignoreOrderFee() {
			return this.$store.getters['shopconfig/getIgnoreOrderFee'];
		},

		isOrderFee() {
			// console.log(this.shopConfig.orderServiceFeeWishlist)
			return (
				!this.ignoreOrderFee &&
				Object.keys(this.productsInBasket).length &&
				//Removed for REQ-492
				// !this.shopConfig.preregistration &&
				this.orderFee
			);
		},

		isOrderFeeWishlist() {
			return this.shopConfig.orderServiceFeeWishlist;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		orderFee() {
			/*
			loadProductsFromJson
			productsJsonUrl
			shopJsonUrl
			*/
			if (this.shopConfig.showDetailedServiceFees) {
				let fee = 0;
				// console.log(this.productsInBasket.ticket)
				if (this.productsInBasket.ticket) {
					this.productsInBasket.ticket.forEach(ticket => {
						// console.log(ticket)
						fee += ticket.numproducts * ticket.type.serviceCosts;
					});
				}
				// console.log('include fees')
				return fee;
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'order-fee';

.#{$component} {
	padding-bottom: 5px;

	&__price {
		text-align: right;
	}
}
</style>
