<template>
	<div class="basket-total">
		<Grid class="basket-total__amount" full-width no-margins>
			<Column :s="6">
				<h2 :class="{ h1: !isSubtotal }">
					{{ isSubtotal ? $t('checkout.subtotal') : $t('checkout.total') }}
				</h2>
			</Column>

			<Column class="basket-total__price" :s="6">
				<h2 :class="{ h1: !isSubtotal }">{{ totalPrice | formatPrice }}</h2>
				<span class="basket-total__vat" v-if="!isSubtotal">{{ $t('checkout.included_vat') }}</span>
			</Column>
		</Grid>
	</div>
</template>

<script>
export default {
	name: 'BasketTotal',

	props: {
		isSubtotal: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		hasMerchandise() {
			return !!this.productsInBasket.merchandise;
		},

		upgradableOrder() {
			return this.shopConfig.upgradableOrder;
		},

		upgradeDiscount() {
			return this.upgradableOrder && this.upgradableOrder.discount && this.upgradableOrder.discount.priceAmount;
		},

		hasUpgradeInCart() {
			const { upgrades } = this.$store.getters['shopapi/getMappedProductsInBasket'];
			return upgrades && upgrades.length;
		},

		ignoreOrderFee() {
			return this.$store.getters['shopconfig/getIgnoreOrderFee'];
		},

		isOrderFeeWishlist() {
			return this.shopConfig.orderServiceFeeWishlist;
		},

		totalPrice() {
			let total = this.ignoreOrderFee
				? this.shopCart.totalAmountExcludingServiceCosts
				: this.shopCart.totalAmount;
			total = this.hasMerchandise
				? this.productsInBasket.merchandise.find(item => item.type.slug === 'shipping').type.price + total
				: total;

			if (!this.isSubtotal && this.hasUpgradeInCart && this.upgradeDiscount) {
				total -= this.upgradeDiscount / 100;
			}

			if (!!this.isOrderFeeWishlist && this.shopConfig.preregistration) {
				total += Number(this.isOrderFeeWishlist);
			}
			return total;
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-total';

.#{$component} {
	margin-bottom: $global-spacing;

	&__price {
		text-align: right;
	}

	h2 {
		text-transform: uppercase;
	}

	&__amount {
		padding-top: $global-spacing;
		border-top: 1px solid var(--primary-body-color);
	}

	&__vat {
		display: block;

		margin-top: -2px;

		font-size: 14px;
		text-transform: uppercase;
	}

	@include sm {
		margin-bottom: $global-spacing--mobile;

		&__vat {
			font-size: 12px;
		}
	}
}
</style>
