var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"col",class:{
		[`col-s--${_vm.s}`]: _vm.s,
		[`col-m--${_vm.m}`]: _vm.m,
		[`col-l--${_vm.l}`]: _vm.l,

		[`col-s-offset--${_vm.sOffset}`]: _vm.sOffset,
		[`col-m-offset--${_vm.mOffset}`]: _vm.mOffset,
		[`col-l-offset--${_vm.lOffset}`]: _vm.lOffset,

		'no-margin': _vm.noMargin,
		's-no-margin': _vm.sNoMargin,
		'm-no-margin': _vm.mNoMargin,
		'l-no-margin': _vm.lNoMargin,

		's-hide': _vm.sHide
	},style:({
		order: _vm.order
	})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }