<template>
	<div class="upgrade-unlock">
		<img v-if="data.image" :src="data.image" class="upgrade-unlock__image md-lg-only" alt="" />
		<div class="upgrade-unlock__inner">
			<div class="upgrade-unlock__name-container">
				<h2 class="upgrade-unlock__name" v-if="data.name" v-html="data.name"></h2>
				<h2 class="upgrade-unlock__price sm-only" v-if="data.price" v-html="data.price"></h2>
			</div>
			<div class="upgrade-unlock__mobile-container">
				<img v-if="data.image" :src="data.image" class="upgrade-unlock__image sm-only" alt="" />
				<div>
					<p v-if="data.description_1" v-html="data.description_1"></p>
					<p v-if="data.description_2" v-html="data.description_2"></p>
				</div>
			</div>
		</div>

		<h2 class="upgrade-unlock__price md-lg-only" v-if="data.price" v-html="data.price"></h2>
	</div>
</template>

<script>
export default {
	name: 'UpgradeUnlock',

	props: {
		data: Object
	}
};
</script>

<style lang="scss">
$component: 'upgrade-unlock';

.#{$component} {
	display: flex;
	justify-content: space-between;
	align-items: center;

	font-family: var(--font-bold);

	line-height: 1.4;

	&__inner {
		flex: auto;
	}

	&__name {
		color: $color-orange-defqon;
	}

	&__price {
		flex-shrink: 0;

		margin-left: 10px;

		color: $color-orange-defqon;
	}

	&__image {
		flex-shrink: 0;
		width: 100px;
		height: auto;

		margin-right: 20px;
	}

	p a {
		color: $color-orange-defqon;
	}

	@include md-lg {
		&__name {
			font-size: 30px;
		}

		&__price {
			font-size: 40px;
		}

		// class is used in json upgrade text
		.upgrade-package__mobile-link {
			// html.no-touchevents & {
			display: none;
			// }
		}
	}

	@include sm {
		&__name-container {
			display: flex;
			align-items: center;
		}

		&__image {
			width: 70px;

			margin-right: 10px;
		}

		&__mobile-container {
			display: flex;
			align-items: center;

			margin: 15px 0;
		}

		// class is used in json upgrade text
		.upgrade-package__desktop-link {
			// html.touchevents & {
			display: none;
			// }
		}
	}
}
</style>
