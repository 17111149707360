<template>
	<div class="basket-travel">
		<BasketItem
			v-for="(travelItem, index) in travelItemsData"
			:key="`travel${index}`"
			:data="travelItem"
			@remove="
				() => {
					removeTravel(travelItem);
				}
			"
		/>
	</div>
</template>

<script>
import BasketItem from './BasketItem';
import RemoveTravelMixin from '@/mixins/RemoveTravelMixin';

export default {
	name: 'BasketTravel',

	components: { BasketItem },

	mixins: [RemoveTravelMixin],

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		travelInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].travel || [];
		},

		ticketsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].ticket || [];
		},

		travelTicketsInBasket() {
			return (
				this.ticketsInBasket.filter(ticket => {
					if (ticket.type.categories && ticket.type.categories.length) {
						return (
							ticket.type.categories[0].en === 'TRAVEL TICKETS' ||
							ticket.type.categories[0].nl === 'TRAVEL TICKETS'
						);
					}
					// return true;
				})[0] || null
			); // Pick the first ticket type. Only 1 type allowed (in case there are more)
		},

		travelTicketsDescription() {
			let description = '';

			if (this.travelInBasket.length && this.travelTicketsInBasket) {
				description += `<br>${this.travelInBasket[0].package.occupation} X ${this.travelTicketsInBasket.type.name} (included)`;
			}

			return description;
		},

		travelTicketsPrice() {
			if (this.travelTicketsInBasket) {
				if (this.shopConfig.showDetailedServiceFees) {
					return (
						(this.travelTicketsInBasket.type.priceExcludingServiceCosts -
							this.travelTicketsInBasket.type.meta.touristTaxAmount) *
						this.travelInBasket[0].package.occupation
					);
				} else {
					return (
						(this.travelTicketsInBasket.type.price -
							this.travelTicketsInBasket.type.meta.touristTaxAmount) *
						this.travelInBasket[0].package.occupation
					);
				}
			}

			return 0;
		},

		travelItemsData() {
			return this.travelInBasket.map(travel => {

				//Remove VMR (entertaiment fee from price)
				let travelPrice = travel.totalPrice + this.travelTicketsPrice;
				if(this.travelTicketsInBasket && this.travelTicketsInBasket.type.meta.entertainmentFeeAmount != 0 && this.travelTicketsInBasket.type.meta.entertainmentFeeAmount){
					travelPrice = travelPrice - this.travelTicketsInBasket.type.meta.entertainmentFeeAmount *
						this.travelInBasket[0].package.occupation
				}

				return {
					title: travel.package.name || '',
					description: (travel.packageOption.title || '') + this.travelTicketsDescription,
					price: travelPrice,
					image:
						travel.package.images &&
						travel.package.images[0].square.sizes &&
						travel.package.images[0].square.sizes[0].url,
					icon: 'IconBuilderTravel',
					hideButtons: true,
					showRemoveButton: true,
					type: 'travel',
					data: travel
				};
			});
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-travel';

.#{$component} {
}
</style>
