// import vue from 'Vue';
export const state = () => ({
	products: null,
	purchasedProducts: null,
	userproducts: null,
	shopUpdateState: '',
	insurencePrice: null,
	shippingPrice: 0,
	shippingCountry: '',
	isRefundChoiceMade: false,
	cartHasUnsavedChanges: false,
	hasPreviousOrder: false
});

export const actions = {
	getRequest(context, payload) {
		const url = `${process.env.SHOP_API_BASE}${payload.url}`;
		const config = payload.config || {};
		config.headers = config.headers || {};

		return this.$axios.get(url, config);
	},

	patchRequest(context, payload) {
		if (payload.params.hasOwnProperty('hotelCartId') && payload.params['hotelCartId']) {
			context.commit('setShopUpdateState', 'updating');
			// console.log('patch rquest')
			const config = {
				headers: {
					Authorization: 'Bearer ' + context.rootGetters['shopconfig/getData'].shopToken,
					'X-Q-Dance-Event-Namespace': context.rootGetters['shopconfig/getData'].apiNamespace
				}
			};

			const isTravelProduct = payload.url.includes('/hotel/');
			!isTravelProduct && context.dispatch('updateCart');
			// console.log('hotelCartID:',payload.params['hotelCartId'])
			return this.$axios.patch(payload.url, payload.params, config).then(response => {
				!isTravelProduct && context.dispatch('updateCart');
				return response;
			});
		} else {
			// console.log('No hotelCartId provided')
		}
	},

	postRequest(context, payload) {
		context.commit('setShopUpdateState', 'updating');

		const config = {
			headers: {
				Authorization: 'Bearer ' + context.rootGetters['shopconfig/getData'].shopToken,
				'X-Q-Dance-Event-Namespace': context.rootGetters['shopconfig/getData'].apiNamespace
			}
		};

		const isTravelProduct = payload.url.includes('/hotel/');

		if (isTravelProduct && context.rootGetters['shopconfig/getData'].preregistration) {
			context.dispatch('fillpreregisterCart', payload);

			return new Promise(resolve => {
				resolve({
					status: 200
				});
			});
		}

		if (payload.params['hotelCartId']) {
			!isTravelProduct && context.dispatch('updateCart');
			// console.log('hotelCartID:',payload.params['hotelCartId'])
			return this.$axios.post(payload.url, payload.params, config).then(response => {
				!isTravelProduct && context.dispatch('updateCart');

				return response;
			});
		}

		return this.$axios.post(payload.url, payload.params, config).then(response => {
			//create a small delay. When a product is purchased with a voucher and the price === 0 the API needs some time to verify the data, causing a direct redirect breaks the shop.
			if (payload.hasOwnProperty('paymentStep') && payload.paymentStep === true) {
				setTimeout(() => {
					!isTravelProduct && context.dispatch('updateCart');
				}, 4000);
				return response;
			} else {
				!isTravelProduct && context.dispatch('updateCart');
				return response;
			}
		});
		// .catch(error => {

		// 	context.commit('setShopUpdateState', '');
		// 	console.log(error);
		// 	return error;

		// });
	},

	async putRequest(context, payload) {
		const isTravelProduct = payload.url.includes('/hotel/');
		context.commit('setShopUpdateState', 'updating');

		/*
		 * If shop is in preregistration fill the fakecart with the products
		 */
		if (context.rootGetters['shopconfig/getData'].preregistration) {
			return new Promise(resolve => {
				context.dispatch('fillpreregisterCart', payload).then(response => {
					resolve({
						status: 200
					});
				});
			});
		}

		/*
		 * This is the normal flow wheb the shop is not in preregistration mode.
		 */
		const config = {
			headers: {
				Authorization: 'Bearer ' + context.rootGetters['shopconfig/getData'].shopToken,
				'X-Q-Dance-Event-Namespace': context.rootGetters['shopconfig/getData'].apiNamespace
			}
		};

		const { shopToken } = context.rootGetters['shopconfig/getData'];
		if (
			context.rootGetters['shopconfig/getData'].shopInit &&
			context.rootGetters['shopconfig/getData'].shopInit.isAddOnShop
		) {
			shopToken && (config.headers['x-cart-token'] = shopToken);
		}

		if (context.rootGetters.getUpgradeOrderId) {
			config.headers['X-Upgradable-Order-Id'] = context.rootGetters.getUpgradeOrderId;
		}

		if (context.rootGetters.getAccessId) {
			config.headers['X-Upgradable-Order-Id'] = context.rootGetters.getAccessId;
		}

		return this.$axios
			.put(payload.url, payload.params, config)
			.then(response => {
				if (!isTravelProduct && !payload.paymentStep && !payload.params.voucher) {
					context.dispatch('dataLayer/addRemoveCart', payload, { root: true });
				}
				//create a small delay. When a product is purchased with a voucher and the price === 0 the API needs some time to verify the data, causing a direct redirect breaks the shop.
				if (payload.hasOwnProperty('paymentStep') && payload.paymentStep === true) {
					setTimeout(() => {
						!isTravelProduct && context.dispatch('updateCart');
					}, 4000);
					return response;
				} else {
					!isTravelProduct && context.dispatch('updateCart');
					return response;
				}
			})
			.catch(error => {
				context.commit('setShopUpdateState', '');
				return error.response;
			});
	},

	deleteRequest(context, { url, params }) {
		context.commit('setShopUpdateState', 'updating');

		const config = {
			data: params,
			headers: {
				Authorization: 'Bearer ' + context.rootGetters['shopconfig/getData'].shopToken,
				'X-Q-Dance-Event-Namespace': context.rootGetters['shopconfig/getData'].apiNamespace
			}
		};

		const isTravelProduct = url.includes('/hotel/');
		const isTravelExtras = isTravelProduct && params && !!params.extraId;

		if (isTravelProduct && context.rootGetters['shopconfig/getData'].preregistration) {
			context.dispatch('fillpreregisterCart', payload);

			return new Promise(resolve => {
				resolve({
					status: 200
				});
			});
		}

		return this.$axios
			.delete(url, config)
			.then(response => {
				/*
				 * Only update after extra deletion. Not after whole package deletion because there is no more cart
				 */
				if (isTravelExtras) {
					context.dispatch('updateTravelCart');
				}

				context.dispatch('updateCart');
			})
			.catch(error => {
				context.commit('setShopUpdateState', '');

				return error;
			});
	},

	/*
	 * Fill the fake cart with data and calculate the price
	 */
	async fillpreregisterCart(context, payload) {
		const shopCart = context.rootGetters['shopconfig/getCart'];
		const travelCart = context.rootGetters['shopconfig/getTravelCart'];
		const isTravelProduct = payload && payload.url.includes('/hotel/');

		if (!payload || (isTravelProduct && !travelCart)) {
			return;
		}
		const id = payload.params.productId;
		const num = parseInt(payload.params.quantity);
		const products = context.getters.getProducts;
		const dontUpdateWishlist = payload.dontUpdateWishlist ? payload.dontUpdateWishlist : false;

		let updatedCart = { ...shopCart };
		let matchedProduct;
		let totalAmount = 0;
		let totalAmountExcludingServiceCosts = 0;

		Object.keys(products).forEach(key => {
			matchedProduct = products[key].find(item => item.id === id);

			if (matchedProduct) {
				updatedCart.products[id] = num;
				updatedCart.timeout = 1200;
			}

			/*
			 * Remove from fakecart
			 */
			if (num === 0) {
				delete updatedCart.products[id];
			}
			// if (payload.params.hotel === 0) {
			delete updatedCart.products['hotel'];
			// }

			Object.keys(updatedCart.products).forEach(prodKey => {
				matchedProduct = products[key].find(item => item.id === prodKey);
				if (matchedProduct) {
					totalAmount += matchedProduct.price * updatedCart.products[prodKey];
					totalAmountExcludingServiceCosts +=
						matchedProduct.priceExcludingServiceCosts * updatedCart.products[prodKey];
				}
			});
		});

		/*
		 * Add travel item. The hotelId is placed within the object this to be able recall the selected package.
		 */
		if (travelCart && !payload.params.hotel) {
			updatedCart.products.hotel = travelCart.totalPrice;
			totalAmount += travelCart.totalPrice;
		}

		if (updatedCart.totalAmount !== totalAmount && !dontUpdateWishlist) {
			context.commit('setCartHasUnsavedChanges', true);
		}

		updatedCart.totalAmountExcludingServiceCosts = totalAmountExcludingServiceCosts;
		updatedCart.totalAmount = totalAmount;

		/*
		 * Push the data to the sso
		 */

		if (!dontUpdateWishlist) {
			await context.dispatch('sso/saveWishlist', updatedCart, { root: true }).then(response => {
				// console.log('Wish-saved: ', updatedCart.totalAmount, payload);

				if (response.data.data === 'OK') {
					context.commit('shopconfig/setCart', updatedCart, { root: true });
					context.commit('setShopUpdateState', '');
				}
			});
		} else {
			/*
			 * Only update the cart with the data. Not pushing it to the sso
			 */
			context.commit('shopconfig/setCart', updatedCart, { root: true });
			context.commit('setShopUpdateState', '');
		}
	},

	/*
	 * After a product is added to the cart request the cart to get the latest product info
	 * If this creates to much load on the API it is possibile to handle the data via the store.
	 * Commit the data back to the shopConfig
	 */
	updateCart(context) {
		if (context.rootGetters['shopconfig/getData'].preregistration) {
			context.dispatch('fillpreregisterCart', payload);

			return new Promise(resolve => {
				resolve({
					status: 200
				});
			});
		}

		const shopCart = context.rootGetters['shopconfig/getCart'];
		const payload = {
			url: `/carts/${shopCart.id}`
		};

		payload.config = {
			headers: {
				Authorization: 'Bearer ' + context.rootGetters['shopconfig/getData'].shopToken,
				'X-Q-Dance-Event-Namespace': context.rootGetters['shopconfig/getData'].apiNamespace
			}
		};

		const { shopToken } = context.rootGetters['shopconfig/getData'];

		if (
			context.rootGetters['shopconfig/getData'].shopInit &&
			context.rootGetters['shopconfig/getData'].shopInit.isAddOnShop
		) {
			shopToken && (payload.config.headers['x-cart-token'] = shopToken);
		}

		return context
			.dispatch('getRequest', payload)
			.then(response => {
				if (response.data.data.checkoutAllowed) {
					// vue.$fireErrorEvent(7000);
				}
				context.commit('shopconfig/setCart', response.data.data, { root: true });
				context.dispatch('updateProductStock');
			})
			.catch(error => {
				context.commit('setShopUpdateState', '');
				// console.log('err',error);
			});
	},

	updateTravelCart(context) {
		if (context.rootGetters['shopconfig/getData'].preregistration) {
			context.dispatch('fillpreregisterCart', payload);

			return new Promise(resolve => {
				resolve({
					status: 200
				});
			});
		}

		const shopCartId = context.rootGetters['shopconfig/getCart'].id;
		const { shopToken, apiNamespace, apiTravelId } = context.rootGetters['shopconfig/getData'];

		const payload = {
			url: `/carts/${shopCartId}/products/hotel/${apiTravelId}`,
			config: {
				headers: {
					Authorization: 'Bearer ' + shopToken,
					'X-Q-Dance-Event-Namespace': apiNamespace
				}
			}
		};

		return context
			.dispatch('getRequest', payload)
			.then(response => {
				context.commit('shopconfig/setTravelCart', response.data.data, { root: true });
				context.commit('setShopUpdateState', '');
			})
			.catch(error => {
				context.commit('setShopUpdateState', '');
				console.log(error);
			});
	},

	updateProductStock(context) {
		const shopData = context.rootGetters['shopconfig/getData'];
		const payload = {
			url: `/products`,
			config: {
				headers: {
					'X-Q-Dance-Event-Namespace': shopData.apiNamespace
				}
			}
		};

		const shopToken = context.rootGetters['shopconfig/getShopToken'];

		if (shopToken) {
			payload.config.headers['x-cart-token'] = shopToken;
		}

		if (
			(context.rootGetters['shopconfig/getData'].shopInit &&
				context.rootGetters['shopconfig/getData'].shopInit.isAddOnShop) ||
			context.rootGetters['shopconfig/getCartFromDeeplink']
		) {
			shopToken && (payload.config.headers['x-cart-token'] = shopToken);
		}

		context
			.dispatch('getRequest', payload)
			.then(response => {
				context.commit('setProducts', response.data.data);
				context.commit('setShopUpdateState', '');
			})
			.catch(error => {
				context.commit('setShopUpdateState', '');
				console.log(error);
			});
	},

	deleteTravelCart(context) {
		/* if (!context.rootGetters['shopconfig/getTravelCart']) {

            return;

        } */

		context.commit('setShopUpdateState', 'update.travel');

		const travelCart = context.rootGetters['shopconfig/getTravelCart'];
		const brand = context.rootGetters['sso/getSsoBrand'];
		const tempData = {
			name: travelCart.package.name,
			price: travelCart.totalPrice,
			id: `${travelCart.package.eventId}_${travelCart.package.accommodationId}`,
			amount: 1,
			type: 'travel'
		};
		context.dispatch(
			'dataLayer/removeFromCart',
			{ brand: brand, productData: tempData, quantity: 1 },
			{ root: true }
		);

		if (travelCart.extras) {
			travelCart.extras.forEach(item => {
				const tempData = {
					name: item.extra.name,
					price: item.extra.content.price,
					id: item.extra.id,
					amount: 1,
					type: 'travel_extra'
				};
				context.dispatch(
					'dataLayer/removeFromCart',
					{ brand: brand, productData: tempData, quantity: 1 },
					{ root: true }
				);
			});
		}

		const shopCartId = context.rootGetters['shopconfig/getCart'].id;
		const { apiTravelId } = context.rootGetters['shopconfig/getData'];

		return context
			.dispatch('deleteRequest', {
				url: `${process.env.SHOP_API_BASE}/carts/${shopCartId}/products/hotel/${apiTravelId}`
			})
			.then(response => {
				context.commit('shopconfig/setTravelCart', null, { root: true });
				context.commit('setShopUpdateState', '');
			})
			.catch(error => {
				context.commit('setShopUpdateState', '');
				console.log(error);
			});
	},

	deleteExtra(context, payload) {
		const shopCartId = context.rootGetters['shopconfig/getCart'].id;
		const apiTravelId = context.rootGetters['shopconfig/getData'].apiTravelId;

		return context.dispatch('deleteRequest', {
			...payload,
			url: `${process.env.SHOP_API_BASE}/carts/${shopCartId}/products/hotel/${apiTravelId}/extras`
		});
	},

	removeOptionFromExperienceBuilder(context, payload) {
		const shopCartId = context.rootGetters['shopconfig/getCart'].id;
		const postData = {
			url: `${process.env.SHOP_API_BASE}/carts/${shopCartId}/products`
		};

		payload.forEach(item => {
			if (item.type && item.type.id) {
				postData.params = {
					productId: item.type.id,
					quantity: 0
				};

				context.dispatch('putRequest', postData);
			} else {
				console.log('No product id found in', item);
			}
		});
	},

	sendTransportRequest(context, payload) {
		return this.$axios.post('https://pers.api-dev.q-dance.com/rest/mail', payload);
	}
};

export const getters = {
	getRawProducts(state) {
		return state.products;
	},
	getPurchasedProducts(state) {
		return state.purchasedProducts;
	},
	getProducts: (state, context, rootState, rootGetters) => {
		if (!state.products) {
			return null;
		}

		/*
		 * There are two things happening in this getter:
		 * 1. Take all the products from the api and add the metadata from Contentful
		 * 2. Group and sort the products by their new 'mappedType'
		 */

		// Metadata from ContentFul (formatted by AddMetaObjectToProducts.js):
		const ticketMeta = rootGetters['shopconfig/getTicketMetaData'];
		const extraMeta = rootGetters['shopconfig/getExtraMetaData'];
		const compareTransportMeta = rootGetters['shopconfig/getCompareTransportMetaData'];
		const merchandiseMeta = rootGetters['shopconfig/getMerchandiseMetaData'];
		const membershipMeta = rootGetters['shopconfig/getMembershipMetaData'];
		const packageMeta = rootGetters['shopconfig/getPackageMetaData'];
		const notUsed = rootGetters['shopconfig/getNotusedData'];

		// Prepare products from api for grouping by category

		const mappedProducts = state.products.map(product => {
			let itemMetadata;
			let mappedType;
			// console.log(product.type, product.name);
			if (product.type === 'insurance') {
				mappedType = 'insurance';
			} else if (product.type === 'merchandise' && merchandiseMeta) {
				itemMetadata = merchandiseMeta.find(meta => meta.id === product.id);
				mappedType = 'merchandise';
			} else if (product.type === 'bus_travel') {
				mappedType = 'transportation';
			} else if (
				product.type === 'ticket' &&
				membershipMeta &&
				membershipMeta.find(meta => meta.id === product.id)
			) {
				// Memberships come from the api as 'ticket'.
				mappedType = 'membership';
				itemMetadata = membershipMeta.find(meta => meta.id === product.id);
			} else {
				itemMetadata = ticketMeta && ticketMeta.find(meta => meta.id === product.id);

				if (itemMetadata) {
					mappedType = 'ticket';
				} else {
					itemMetadata = notUsed && notUsed.find(meta => meta.id === product.id);

					if (itemMetadata) {
						mappedType = 'notused';
					} else {
						itemMetadata =
							compareTransportMeta && compareTransportMeta.find(meta => meta.id === product.id);

						if (itemMetadata) {
							// console.log(itemMetadata);
							mappedType = 'comparetransport';
						} else {
							itemMetadata = extraMeta && extraMeta.find(meta => meta.id === product.id);

							if (itemMetadata) {
								// console.log(itemMetadata);
								mappedType = 'extras';
							} else {
								if (product.hasOwnProperty('categories') && product.categories.length) {
									if (
										product.categories[0].en === 'ORGANISED BUS' ||
										product.categories[0].nl === 'ORGANISED BUS'
									) {
										mappedType = 'transportation';
									}
								} else {
									/* console.log(
									'no CTF data found for PL product:',
									product.type,
									'-',
									product.name,
									'-',
									product.id
								); */
								}
							}
						}
					}
				}
			}

			// If found, add metadata to Paylogic product metadata
			const meta = itemMetadata
				? {
						...product.meta,
						...itemMetadata.meta
				  }
				: product.meta;

			/* if (mappedType === 'membership') {
				console.log('a.', meta);
				console.log('b.', itemMetadata);
			}
 */
			return {
				...product,
				meta,
				mappedType
			};
		});

		/*
		 * Upgrades don't exist in the cms. They are user specific and returned in the cart create with an order id.
		 * We match the upgrades in the cart with those in de api products to check if they are valid and to add info about available stock.
		 */
		const availableUpgrades = [];
		const { upgradableOrder } = rootGetters['shopconfig/getData'];
		// Get the user specific upgrades
		if (upgradableOrder && upgradableOrder.upgrades && upgradableOrder.upgrades.length) {
			upgradableOrder.upgrades.forEach(upgradeGroup => {
				if (upgradeGroup.upgradeOptions && upgradeGroup.upgradeOptions.length) {
					upgradeGroup.upgradeOptions.forEach(upgrade => {
						availableUpgrades.push(upgrade);
					});
				}
			});
		}

		// Group products by category
		const groupedProducts = {};
		const sortItemsByContentfulOrder = metaData => {
			const sortedItems = [];

			metaData.forEach(productMetaData => {
				const product = mappedProducts.find(product => product.id === productMetaData.id);
				if (product && !sortedItems.find(sortedItem => sortedItem.id === product.id)) {
					// Product with same id could have been added twice in Contentful.
					if (
						!(
							product.type === 'ticket' &&
							(availableUpgrades.some(upgrade => upgrade.id === product.id) || product.isUpgrade)
						)
					) {
						// Filter upgrades out of tickets. We could just use the 'isUpgrade' flag in the ticket but that's not always set correctly.
						// Valid upgrades are added in step 3 below
						sortedItems.push(product);
					}
				}
			});

			return sortedItems;
		};

		// 1. Categories not sorted, added in the order as loaded from Paylogic
		['transportation', 'insurance'].forEach(category => {
			groupedProducts[category] = mappedProducts.filter(product => product.mappedType === category);
		});

		// 2. Categories sorted by order from Contentful (using the metadata from Contentful)
		if (packageMeta) groupedProducts.packages = sortItemsByContentfulOrder(packageMeta);
		if (membershipMeta) groupedProducts.membership = sortItemsByContentfulOrder(membershipMeta);
		if (ticketMeta) groupedProducts.ticket = sortItemsByContentfulOrder(ticketMeta);
		if (merchandiseMeta) groupedProducts.merchandise = sortItemsByContentfulOrder(merchandiseMeta);
		if (extraMeta) groupedProducts.extras = sortItemsByContentfulOrder(extraMeta);
		if (compareTransportMeta) groupedProducts.comparetransport = sortItemsByContentfulOrder(compareTransportMeta);

		// 3. Add order upgrades, if available
		// Match and mix with the upgrade products from the api
		const confirmedUpgrades = [];
		if (availableUpgrades.length) {
			availableUpgrades.forEach(upgrade => {
				const shopUpgradeProduct = state.products.find(prod => prod.id === upgrade.id);
				if (shopUpgradeProduct) {
					confirmedUpgrades.push({
						...upgrade,
						...shopUpgradeProduct
					});
				}
			});
		}
		// If found and valid, add them.
		if (confirmedUpgrades.length) {
			groupedProducts.upgrades = confirmedUpgrades;
		}

		return groupedProducts;
	},
	getUserproducts: state => state.userproducts,
	getInsurencePrice: state => state.insurencePrice,
	getShippingPrice: state => state.shippingPrice,
	getShippingCountry: state => state.shippingCountry,
	getIsRefundChoiceMade: state => state.isRefundChoiceMade,

	getShopUpdateState: state => state.shopUpdateState,
	getIsShopUpdatePending: state => !!state.shopUpdateState,

	getMappedProductsInBasket: (state, context, rootState, rootGetters) => {
		if (!rootGetters['shopconfig/getCart']) {
			return {};
		}

		const shopProducts = context.getProducts;
		const tempData = {
			...shopProducts
		};
		const cartProducts = rootGetters['shopconfig/getCart'].products;
		const travelProducts = rootGetters['shopconfig/getTravelCart'];
		const data = {};

		if (rootGetters['shopconfig/getData'].shopInit && rootGetters['shopconfig/getData'].shopInit.isAddOnShop) {
			// Add empty obj + key to be able to check and group for addons
			// hacky, but only solution since its data from a different endpoint (no CTF)
			tempData['addon'] = {};
		}
		// console.log(tempData)
		// Add products from cart
		Object.keys(tempData).forEach(key => {
			/*
			 * Exclude the ticket_init it is only used to map the correct data to extras and tickets
			 */
			if (key != 'ticket_init') {
				const itemData = [];

				Object.keys(cartProducts).forEach(cartkey => {
					if (key !== 'addon') {
						let cartProduct = tempData[key].find(item => item.id === cartkey);
						cartProduct &&
							itemData.push({
								numproducts: cartProducts[cartkey],
								type: cartProduct
							});
					} else if (key == 'addon') {
						const purchasedProducts =
							rootGetters['shopapi/getPurchasedProducts'].data.data['upsell'].hospitality['add_on'];
						// console.log('bought:',purchasedProducts)
						if (cartkey.search('addon_') != -1) {
							const addonids = cartkey.split('_');
							let type = purchasedProducts.find(addon => {
								if (addon.reference == addonids[1]) {
									return addon;
								}
							});
							let foundAddon = type && type.extras.available.find(addon => addon.id == addonids[2]);
							let data = {};
							data[addonids[1]] = {
								quantity: cartProducts[cartkey],
								type: foundAddon
							};
							foundAddon && itemData.push(data); // : (console.log(`Addon ${addonids[2]} not found.`));
						}
					}
				});

				if (itemData.length) {
					data[key] = itemData;
				}
				/*
				 * This is needed for the user only, the order fee is not specified anywhere else in the API.
				 * So we need to show the user the price of an booking.
				 * No other logic is needed. The price is already accounted for by the API.
				 */
				if (
					key === 'transportation' &&
					data.transportation &&
					data.transportation[0].type.id.split('_')[0] != 'paylogic'
				) {
					itemData.push({
						numproducts: 1,
						type: {
							name: 'Order fee',
							price: 5,
							slug: 'orderfee'
						}
					});
				}

				if (key === 'merchandise' && data.merchandise) {
					itemData.push({
						numproducts: 1,
						type: {
							name: 'Shipping',
							price: parseFloat(context.getShippingPrice),
							slug: 'shipping'
						}
					});
				}
			}
		});

		// Add products from travel cart
		if (travelProducts) {
			data.travel = [travelProducts];
		}
		return data;
	},

	getCartHasUnsavedChanges: state => state.cartHasUnsavedChanges,
	getIsInsurableProductInCart: (state, getters) => {
		return (
			getters.getMappedProductsInBasket &&
			['upgrades', 'ticket', 'transportation', 'travel', 'extras', 'comparetransport', 'addon'].some(
				key => !!getters.getMappedProductsInBasket[key]
			)
		);
	},
	getHasPreviousOrder: state => state.hasPreviousOrder
};

export const mutations = {
	setShopUpdateState(state, payload) {
		state.shopUpdateState = payload;
	},

	setPurchasedProducts(state, payload) {
		state.purchasedProducts = payload;
	},

	setHasPreviousOrder(state, payload) {
		state.hasPreviousOrder = payload;
	},

	setProducts(state, payload) {
		state.products = payload;
	},

	setUserproducts(state, payload) {
		state.userproducts = payload;
	},

	setResetShop(state) {
		state.products = null;
	},

	updateShopData(state, payload) {
		state.products[payload.key] = payload.data;
	},

	setInsurencePrice(state, payload) {
		state.insurencePrice = payload;
	},

	setShippingPrice(state, payload) {
		state.shippingPrice = payload;
	},

	setShippingCountry(state, payload) {
		state.shippingCountry = payload;
	},

	setCartHasUnsavedChanges(state, payload) {
		state.cartHasUnsavedChanges = payload;
	},

	setIsRefundChoiceMade(state, payload) {
		state.isRefundChoiceMade = payload;
	}
};
