<template>
	<header class="header">
		<Navigation />
	</header>
</template>

<script>
import Navigation from '@/components/layout/Navigation';

export default {
	name: 'Header',

	components: {
		Navigation
	}
};
</script>

<style lang="scss">
$component: 'header';

.#{$component} {
	display: block;

	position: fixed;

	width: 100%;
	height: $nav-height;

	top: 0;

	z-index: 100;

	@include sm {
		height: $nav-height-mob;
	}
}
</style>
