<template>

	<transition>

		<div class="shop-modal-prefillcart" :class="{ 'shop-modal-prefillcart--animated': delayed }" v-show="showModal">

			<div class="shop-modal-prefillcart__header">

				<div class="shop-modal-prefillcart__header-wrapper">

					<div class="shop-modal-prefillcart__header-copy">

						<transition name="fade-fast" mode="out-in">

							<h1 :key="`title_${cartCopy.key}`">{{ cartCopy.title }}</h1>

						</transition>

						<transition name="fade-fast" mode="out-in">

							<p class="shop-modal-prefillcart__body" :key="`body_${cartCopy.key}`">
								 {{ cartCopy.body }}
							</p>

						</transition>

					</div>

					<button class="shop-modal-prefillcart__close" @click="closeModal">

						<Close />

					</button>

				</div>

			</div>

			<Grid class="shop-modal-prefillcart__inner">

				<Column :sNoMargin="true">

					<div class="shop-modal-prefillcart__wrapper" v-if="delayed">

						<WhishListTotalOverview @addAll="addAll" :allSoldOut="allSoldOut" v-if="false" />

						<div class="shop-modal-prefillcart__category-wrapper">

							<div
								class="shop-modal-prefillcart__category"
								v-for="(item, key) in sortedProductBasket"
								:key="key"
							>

								<Grid :fullWidth="true" v-if="item.length">

									<Column :s="isWishlistMode ? 9 : 6" :m="isWishlistMode ? 6 : 4" noMargin>

										<h3 class="shop-modal-prefillcart__title">
											 {{ reformateBusTransferTitle(key) | UCFirst }}
										</h3>

									</Column>

									<Column :sHide="true" :m="2">

										<h3 class="shop-modal-prefillcart__title">#</h3>

									</Column>

									<Column :sHide="true" :m="2">

										<h3 class="shop-modal-prefillcart__title">{{ $t('yourwishlist.price') }}</h3>

									</Column>

									<!-- <Column :sHide="true" :m="1" v-if="showDetailedServiceFees">
                                        <h3 class="shop-modal-prefillcart__title">Service Fees</h3>
                                    </Column> -->

									<Column :s="3" :m="2">

										<h3 class="shop-modal-prefillcart__title">{{ $t('yourwishlist.total') }}</h3>

									</Column>

									<!-- <Column :s="3" :m="2" noMargin>

                                    </Column> -->

								</Grid>

								<PrefillCartItem
									v-for="(data, index) in sortedProductBasket[key]"
									:key="index"
									ref="prefillitem"
									:data="{
										data: data,
										key: key,
										sortedProductWishList: sortedProductWishList
									}"
								/>

							</div>

							<div class="shop-modal-prefillcart__button-wrapper" v-if="!isWishlistMode">

								<Button
									class="shop-modal-prefillcart__button button--alternative-third button--small"
									@click.native="closeModal"
								>

									<span>{{ $t('yourwishlist.continueshopping') }}</span>

								</Button>

								<Button
									class="shop-modal-prefillcart__button button--alternative-third button--small"
									@click.native="expandCart"
									v-if="!allSoldOut && basketHasProducts"
								>

									<span>{{ $t('yourwishlist.gotocheckout') }}</span>

								</Button>

								<Button
									class="shop-modal-prefillcart__button shop-modal-prefillcart__button--addtocart button--alternative-third button--small"
									:isDisabled="true"
									v-if="!basketHasProducts"
								>

									<span>{{ $t('yourwishlist.addproductsbeforecheckout') }}</span>

								</Button>

							</div>

						</div>

					</div>

					<div class="shop-modal-prefillcart__wrapper shop-modal-prefillcart__wrapper--center" v-else>

						<br />

						<br />

						<Spinner />

					</div>

				</Column>

			</Grid>

		</div>

	</transition>

</template>

<script>
import PrefillCartItem from '@/components/ui/PrefillCartItem';
import WhishListTotalOverview from '@/components/ui/WhishListTotalOverview';

export default {
	name: 'ShopModalfillcart',

	data() {
		return {
			delayed: false,
			allSoldOut: true
		};
	},

	components: {
		PrefillCartItem,
		WhishListTotalOverview
	},

	computed: {
		cartCopy() {
			if (this.delayed) {
				if (this.isWishlistMode) {
					return {
						title: this.$t(`wishlist.titleRegular`),
						body: this.$t(`wishlist.bodyPreregistration`),
						key: 0
					};
				} else {
					return {
						title: this.$t(`wishlist.titleRegular`),
						body: this.$t(`wishlist.bodySale`),
						key: 1
					};
				}
			} else {
				return {
					title: this.$t(`wishlist.titleLoading`),
					body: this.$t(`wishlist.bodyLoading`),
					key: 2
				};
			}
		},

		sortedProductWishList() {
			const cartProducts = this.wishlistData && this.wishlistData.wishlist.products;
			const travelProducts = this.wishlistData && this.wishlistData.travelCart;
			const data = {};

			this.allSoldOut = true;

			// Add products
			!!this.shopProducts &&
				!!cartProducts &&
				Object.keys(this.shopProducts).forEach(key => {
					/*
					 * Exclude the ticket_init it is only used to map the correct data to extras and tickets
					 */
					if (key != 'ticket_init') {
						const itemData = [];

						Object.keys(cartProducts).forEach(cartkey => {
							const cartProduct = this.shopProducts[key].find(item => item.id === cartkey);

							cartProduct &&
								itemData.push({
									numproducts: cartProducts[cartkey],
									type: cartProduct
								});

							/*
							 * If all products are soldout disable ADD ALL ITEMS button
							 */
							if (cartProduct && !cartProduct.soldOut && cartProduct.stockAvailable >= 1) {
								this.allSoldOut = false;
							}
						});

						if (itemData.length) {
							data[key] = itemData;
						}
						/*
						 * This is needed for the user only, the order fee is not specified anywhere else in the API.
						 * So we need to show the user the price of an booking.
						 * No other logic is needed. The price is already accounted for by the API.
						 */
						if (
							key === 'transportation' &&
							data.transportation &&
							data.transportation[0].type.id.split('_')[0] != 'paylogic'
						) {
							itemData.push({
								numproducts: 1,
								type: {
									name: 'Order fee',
									price: 5,
									slug: 'orderfee'
								}
							});
						}
					}
				});

			// Add travel products
			if (travelProducts) {
				data.travel = [travelProducts];
			}

			return data;
		},

		sortedProductBasket() {
			/*
			 * Remove the travel tickets from the ticket array
			 * The price is added to the travelproduct
			 */
			let ticketsWithOutTravelTypes = this.sortedProductWishList.ticket || null;
			if (this.sortedProductWishList.ticket) {
				ticketsWithOutTravelTypes = this.sortedProductWishList.ticket.filter(item => {
					const tickets =
						item.type.categories &&
						!item.type.categories.find(
							ticket => ticket.en === 'TRAVEL TICKETS' || ticket.nl === 'TRAVEL TICKETS'
						);
					return tickets;
				});
			}

			let sorted = {
				ticket: ticketsWithOutTravelTypes,
				travel: this.sortedProductWishList.travel || null,
				transportation: this.sortedProductWishList.transportation || null,
				merchandise: this.sortedProductWishList.merchandise || null,
				extras: this.sortedProductWishList.extras || null,
				insurence: this.sortedProductWishList.insurence || null,
				comparetransport: this.sortedProductWishList.comparetransport || null
			};
			/*
			 * Remove all falsy values, ( "", 0, false, null, undefined )
			 * Magic :-)
			 */
			sorted = Object.entries(sorted).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});

			return sorted;
		},

		wishlistData() {
			return this.$store.getters['sso/getWishlist'];
		},

		showModal() {
			if (this.wishlistData) {
				const hasProductsInWishlist =
					this.wishlistData.wishlist &&
					this.wishlistData.wishlist.products &&
					Object.keys(this.wishlistData.wishlist.products);
				return (
					this.$store.getters.getModalPrefillCartActive &&
					(this.wishlistData.travelCart || hasProductsInWishlist)
				);
			}

			return false;
		},

		shopProducts() {
			return this.$store.getters['shopapi/getProducts'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		isWishlistMode() {
			return this.shopConfig && this.shopConfig.preregistration;
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		basketHasProducts() {
			return Object.keys(this.productsInBasket)[0];
		},

		showDetailedServiceFees() {
			return this.shopConfig.showDetailedServiceFees;
		}
	},

	watch: {
		showModal(val) {
			if (val && !this.delayed) {
				this.$store.commit('setShowCheckout', true);

				/*
				 * Reduce API load
				 */
				const timeOut = process.env.ENV === 'dev' ? 0 : Math.floor(Math.random() * 10 + 1) * 1000;

				setTimeout(() => {
					this.delayTheWishList();
				}, timeOut);
			}
		}
	},

	methods: {
		addAll() {
			/* this.$refs.prefillitem.forEach(item => {
				if (item.data.key === 'travel') {
					item.loadTravel();
					return;
				}

				item.addToCart();
			}); */
		},

		reformateBusTransferTitle(key) {
			key = key === 'transportation' ? 'Organised Bus Transport' : key;
			key = key === 'travel' ? 'Travel & Stay Package' : key;
			key = key === 'comparetransport' ? 'Extra Transport Products' : key;
			return key === 'extras' ? 'Extras & other experiences' : key;
		},

		closeModal() {
			this.$store.commit('setModalPrefillCartActive', false);
			this.$store.commit('setShowCheckout', false);
		},

		delayTheWishList() {
			this.delayed = true;
		},

		expandCart() {
			this.$store.commit('setModalPrefillCartActive', false);
			this.$store.commit('setShowCheckout', true);
		}
	}
};
</script>

<style lang="scss">
$component: 'shop-modal-prefillcart';

.#{$component} {
	display: block;

	position: fixed;
	z-index: $layer-checkout;

	width: 100vw;
	height: 100vh;

	overflow-y: scroll;
	overflow-x: hidden;

	background: var(--content-background);

	top: 0;
	left: 0;

	z-index: 1000;

	&--animated {
		&.v-enter,
		&.v-leave-to {
			opacity: 0;

			.#{$component}__header,
			.#{$component}__inner {
				transform: translateY(20%);
			}
		}

		&.v-enter-active {
			transition: opacity 0.4s $ease-in-out-sine;

			.#{$component}__header,
			.#{$component}__inner {
				transition: transform 0.5s $ease-out-cubic;
			}
		}

		&.v-leave-active {
			transition: opacity 0.4s $ease-in-out-sine;

			.#{$component}__header,
			.#{$component}__inner {
				transition: transform 0.5s $ease-in-cubic;
			}
		}
	}

	&__category {
		padding: 0 10px 40px;
	}

	&__category-wrapper {
		// padding: 120px 0 150px 0;
		padding-bottom: 150px;
	}

	&__header {
		position: fixed;

		width: 100%;
		height: 167px;

		z-index: 10;
	}

	&__header-wrapper {
		position: relative;

		display: flex;
		justify-content: space-between;
		align-items: center;

		width: calc(100% - #{$global-spacing * 4 + 5px}); // 5px scrollbar compensation
		max-width: calc(1180px - 80px);
		left: -3px;
		height: 100%;

		margin: 0 auto;
		padding: 20px 45px;

		background-color: var(--content-background-light);
	}

	&__header-copy {
		flex: auto;
		padding-right: 5px;
	}

	&__body {
		padding-top: 20px;
		font-size: 12px;
		max-width: 75%;
	}

	&__close {
		position: relative;
		right: 0;
	}

	&__title {
		font-family: $font-regular;
		font-size: 20px;

		padding: 0 0 15px;
	}

	&__wrapper {
		position: relative;
		// margin-top: calc(167px + 2px);
		margin-top: 180px;

		width: 100%;

		&--center {
			text-align: center;
		}
	}

	&__button {
		max-width: 165px;

		&--addtocart {
			max-width: 280px;
		}

		&:not(:last-of-type) {
			margin-right: 10px;
		}
	}

	&__button-wrapper {
		text-align: right;
	}

	@include sm {
		&__body {
			padding-top: 20px;
			font-size: 12px;
			max-width: 100%;
		}

		&--has-couter {
			margin-top: 40px;
		}

		&__category {
			padding: 0 5px 40px;
		}

		&__header {
			position: relative;
			height: auto;
		}

		&__wrapper {
			margin-top: 0;
		}

		&__header-wrapper {
			width: calc(100% - (20px + 5px));
			padding: 20px 20px;
			align-items: flex-start;
		}

		&__category-wrapper {
			padding-top: 20px;
		}

		&__button {
			max-width: 100%;

			&:not(:last-of-type) {
				margin-bottom: 20px;
			}
		}
	}
}
</style>

