<template>
	<div class="paymentmethods" v-if="getShopCart.order">
		<template v-if="getShopCart.order.paymentUri === null">
			<Grid class="paymentmethods__top" full-width no-margins>
				<Column :m="6">
					<label class="paymentmethods__label">{{ $t('checkout.voucher.add') }}</label>

					<div class="paymentmethods__wrapper">
						<div class="paymentmethods__select">
							<input
								class="input-field__input"
								type="input"
								v-model="voucherCode"
								v-if="!showLoaderVoucher"
							/>

							<Spinner class="paymentmethods__spinner" v-else />
						</div>

						<Button
							class="paymentmethods__button button--alternative"
							:class="{ 'button--disabled': !voucherCode || showLoaderVoucher || showLoader }"
							@click.native="validateVoucher"
						>
							<span>{{ $t('checkout.voucher.redeem') }}</span>
						</Button>
					</div>

					<div>
						<p class="paymentmethods__error" v-if="errorVoucher">{{ errorVoucher }}</p>

						<p class="paymentmethods__success" v-if="isVoucherExcepted">
							{{ $t('paymentmethods.success') }}
						</p>
					</div>
				</Column>

				<Column :m="6" v-if="isMembershipInBasket">
					<label class="paymentmethods__label md-lg-only">&nbsp;</label>

					<div class="paymentmethods__wrapper">
						<div class="paymentmethods__disclaimer" v-html="$t('membership.disclaimerVoucher')"></div>
					</div>
				</Column>
			</Grid>

			<Grid full-width no-margins>
				<Column :m="6">
					<label class="paymentmethods__label">{{ $t('checkout.payment_method.select') }}</label>

					<div class="paymentmethods__wrapper">
						<div class="paymentmethods__select">
							<SelectField
								ref="methods"
								:label="$t('checkout.payment_method.method')"
								:name="'Payment Method'"
								:valueFromDataAttr="true"
								v-model="selectedPayment"
								v-if="!showLoader"
								:disabled="getShopCart.order.paymentUri != null"
							>
								<option
									v-for="item in paymentMethods"
									:key="item.name"
									:value="item.name"
									:name="item.name"
									v-bind:data-id="item.uid"
								>
									{{ item.name }}
								</option>
							</SelectField>

							<Spinner class="paymentmethods__spinner" v-else />
						</div>

						<Button
							class="paymentmethods__button button--alternative"
							:class="{ 'button--disabled': !selectedPayment || showLoaderVoucher || showLoader }"
							target="_self"
							@click.native="onClickPay"
						>
							<span>HELLO </span>
						</Button>
					</div>

					<div>
						<p class="paymentmethods__error">{{ error }}</p>
					</div>

					<img
						v-if="!isPaymentMethods"
						class="paymentmethods__logos"
						src="/images/payment-logos.png"
						alt=""
					/>

					<div v-else>
						<img
							v-for="(item, index) in isPaymentMethods"
							:key="index"
							class="paymentmethods__logos"
							:src="item.fields.file.url"
							:alt="item.fields.title"
						/>
					</div>
				</Column>

				<Column :m="6" v-if="!hasUpgradeModule">
					<div class="paymentmethods__order-total">
						<p
							class="paymentmethods__label"
							v-html="isTotalAmountZero ? '&nbsp;' : $t('checkout.total')"
						></p>

						<p class="paymentmethods__total" v-if="!isTotalAmountZero">{{ totalPrice | formatPrice }}</p>

						<p class="paymentmethods__success" v-if="isVoucherExcepted">{{ voucherSuccessCopy }}</p>
					</div>
				</Column>
			</Grid>
		</template>

		<template v-else>
			<Grid full-width no-margins>
				<Column :m="6">
					<label class="paymentmethods__label">{{ $t('label.finalize') }}</label>

					<div class="paymentmethods__wrapper">
						<div class="paymentmethods__select"></div>

						<Button
							class="paymentmethods__button button--alternative"
							target="_self"
							@click.native="onCLickFinalizePayment"
						>
							<span>{{ buttonConfirmLabel }}</span>
						</Button>
					</div>
				</Column>
			</Grid>
		</template>
	</div>

	<div class="paymentmethods" v-else>
		<Spinner />
	</div>
</template>

<script>
import InputField from '@/components/ui/filter/Input';
import SelectField from '@/components/ui/SelectField';
import preserveUTMParams from '@/utils/preserveUTMParams';

export default {
	name: 'PaymentMethods',

	components: {
		SelectField,
		InputField
	},

	data() {
		return {
			selectedPayment: null,
			showLoader: false,
			showLoaderVoucher: false,
			paymentUrl: '',
			error: '',
			errorVoucher: '',
			voucherCode: null,
			isVoucherExcepted: false
		};
	},

	computed: {
		getPaymentProvider() {
			return this.$store.getters['shopconfig/getPaymentProvider'];
		},
		ignoreOrderFee() {
			return this.$store.getters['shopconfig/getIgnoreOrderFee'];
		},

		totalPrice() {
			return this.ignoreOrderFee
				? this.getShopCart.totalAmountExcludingServiceCosts
				: this.getShopCart.totalAmount;
		},

		getShopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		getShopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		hasUpgradeModule() {
			return this.$store.getters['shopconfig/getHasUpgradeModule'];
		},

		paymentMethods() {
			return this.$store.getters['shopconfig/getPaymentmethods'];
		},

		isTotalAmountZero() {
			return (
				this.paymentMethods.length === 1 && this.paymentMethods[0].name.toLowerCase() === 'no payment required'
			);
		},

		buttonConfirmLabel() {
			return this.isTotalAmountZero ? this.$t('label.cart.complete_order') : this.$t('label.cart.pay');
		},

		voucherSuccessCopy() {
			if (this.isTotalAmountZero) {
				return this.$t('checkout.payment_method.zero');
			} else {
				return this.$t('checkout.payment_method.discount_next_step');
			}
		},

		isMerchandiseSeleced() {
			return this.productsInBasket.hasOwnProperty('merchandise');
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		isMembershipInBasket() {
			return !!this.productsInBasket.membership;
		},
		isPaymentMethods() {
			return this.getShopConfig.shopTheme ? this.getShopConfig.shopTheme.fields.orderPaymentOptions : false;
		}
	},

	watch: {
		paymentMethods(val) {
			if (this.selectedPayment && !val.some(method => method.uid === this.selectedPayment.value)) {
				this.selectedPayment = null;
				this.$refs.methods.reset();
			}
		},
		getShopCart(val) {
			// console.log(val);
		}
	},

	methods: {
		validateVoucher() {
			this.showLoaderVoucher = true;

			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.getShopCart.id}/vouchers`,
				params: {
					voucher: this.voucherCode
				}
			};

			this.$store
				.dispatch(`shopapi/putRequest`, payload)
				.then(response => {
					this.showLoaderVoucher = false;
					this.isVoucherExcepted = false;
					this.errorVoucher = '';
					console.log(response);
					if (response && response.data.hasOwnProperty('error')) {
						this.setErrorMessage(response.data.error.code, response.data.error.message);
					} else {
						console.log(response.data.data);
						this.isVoucherExcepted = true;
						this.$store.commit(
							'shopconfig/setPaymentmethods',
							response.data.data.order.availablePaymentMethods
						);
					}
				})
				.catch(error => {
					console.log(error);
					this.setErrorMessage('voucher.invalid', '');
				});
		},

		setErrorMessage(errorCode, errorMessage) {
			const error = {
				'voucher.invalid': this.$t('paymentmethods.voucherinvalid') || 'This voucher code is invalid',
				'voucher.used': this.$t('paymentmethods.voucherused') || 'This voucher code has already been used'
			}[errorCode];

			console.log(errorCode, error);

			this.errorVoucher = error || errorMessage;
		},

		onCLickFinalizePayment() {
			window.location.href = preserveUTMParams(this.getShopCart.order.paymentUri);
		},

		onClickPay() {
			console.log('onClickPay');
			this.showLoader = true;

			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.getShopCart.id}/orders`,
				params: {
					paymentMethod: this.selectedPayment.value,
					redirectUri: preserveUTMParams(`${window.location.origin}/${this.getShopConfig.slug}/paymentstatus`)
				}
			};
			const selectedMethod = this.paymentMethods.find(item => item.uid === this.selectedPayment.value);
			this.$store.dispatch('dataLayer/addPaymentInfo', selectedMethod.name);

			this.$store
				.dispatch('shopapi/postRequest', payload)
				.then(response => {
					// this.paymentSelected = true;
					this.showLoader = false;
					window.location.href = preserveUTMParams(response.data.data.redirectUri);
					// this.paymentUrl = response.data.data.redirectUri;
				})
				.catch(error => {
					let errormessage = 'undefined error';

					if (error && error.response && error.response.data && error.response.data.error.message) {
						errormessage = error.response.data.error.message;
					}

					this.showLoader = false;
					const formatedError = `Something went wrong: ${errormessage}. Please contact us for your order request.`;

					this.$fireErrorEvent(formatedError);

					console.log('error:', error);
				});
		},
		mounted() {}
	}
};
</script>

<style lang="scss">
$component: 'paymentmethods';

.#{$component} {
	display: block;

	padding-bottom: 100px;
	margin-top: $global-spacing;

	> .grid-container {
		// margin-left: -$global-spacing * 2;
	}

	&__top {
		margin-bottom: 40px;
	}

	&__error {
		color: $color-red;
		// font-size: 10px;

		padding-top: 10px;
	}

	&__success {
		color: $color-green;

		padding-top: 10px;
	}

	&__wrapper {
		padding-top: 10px;
	}

	&__select {
		width: 100%;

		margin-bottom: 10px;
	}

	&__label {
		display: block;
		font-size: 20px;
	}

	&__total {
		margin-bottom: 10px;

		font-family: $font-bold;
		font-size: 24px;
	}

	&__spinner {
		height: 50px;
		.lds-ellipsis {
			height: 50px !important;
			div {
				top: 15px !important;
			}
		}
	}

	&__disclaimer {
		font-size: 14px;
	}

	&__logos {
		max-width: 255px;
		height: 31px;

		margin-top: 10px;
		&:not(:first-of-type) {
			margin-left: 1.75rem;
		}
	}
}
</style>
