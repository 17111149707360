<template>
	<div class="large-radio">
		<input
			class="large-radio__option"
			type="radio"
			:name="name"
			:id="data.option"
			:value="data.option"
			v-on:change="updateValue({ value: $event.target.value, key: $event.target.name })"
		/>

		<label class="large-radio__option-label" :for="data.option">
			<slot> </slot>
		</label>
	</div>
</template>

<script>
export default {
	name: 'LargeRadio',

	props: {
		data: null,
		name: null
	},

	methods: {
		updateValue(val) {
			this.$emit('input', val);
		}
	}
};
</script>

<style lang="scss">
$component: 'large-radio';

.#{$component} {
	display: flex;

	width: 100%;
	max-width: 320px;
	height: 130px;

	overflow: hidden;

	background-image: linear-gradient(
		180deg,
		var(--content-background-dark) 0%,
		var(--content-background-light-2) 100%
	);
	// background-image: linear-gradient(180deg, var(--primary-color), var(--content-background));
	border: 1px solid #5e5e66;
	border-radius: 2px;
	box-shadow: inset 0px 0px 0px 2px $color-black;

	text-shadow: 0 1px 0 var(--primary-body-color-dark);

	&:not(:last-child) {
		margin-right: 30px;
	}

	&--alternative {
		border: none;
		box-shadow: none;

		.#{$component} {
			&__option {
				border: none;

				&:checked + label {
					color: var(--primary-body-color);
					border: 1px solid rgba(var(--primary-color), 1);
					&:after {
						box-shadow: none;
					}
				}
			}

			&__option-label {
				border: 1px solid #5e5e66;
				&:hover {
					border-color: rgba(var(--primary-color), 1);
				}
			}
		}
	}

	&__option {
		position: absolute;

		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;

		&:checked {
			visibility: visible;
			opacity: 1;
		}

		&:checked + label {
			color: $color-white;

			&:after {
				display: block;
				box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5), inset 0 -8px 0 0 rgba(var(--primary-color), 1);
			}
		}
	}

	&__option-label {
		cursor: pointer;
		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: 100%;
		height: 100%;

		color: $color-grey;
		text-transform: capitalize;

		transition: 0.3s color $ease-in-out-cubic, 0.3s border-color $ease-in-out-cubic;

		> span {
			> svg {
				path {
					fill: var(--primary-body-color);
				}
			}
		}

		&:after {
			content: '';

			position: absolute;
			display: none;

			width: 100%;
			height: 10px;

			bottom: 0;
			left: 0;
		}
	}

	@include sm {
		max-width: 100%;
	}
}
</style>
