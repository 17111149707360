export default {
	install(Vue) {
		Vue.mixin({
			computed: {
				/* ssoBrand() {
					return this.$store.getters['sso/getSsoBrand'];
				}, */

				isLoginChecked() {
					return this.$store.getters['sso/getIsLoginChecked'];
				},

				isUserLoggedIn() {
					return this.$store && this.$store.getters['sso/getIsUserLoggedIn'];
				},

				userData() {
					return this.$store.getters['sso/getUserData'];
				},

				isPreregistrationChecked() {
					return this.$store.getters['sso/getIsPreregistrationChecked'];
				},

				userPreregistrationData() {
					return this.$store.getters['sso/getUserPreregistrationData'];
				},

				userHasPreregistered() {
					return this.$store.getters['sso/getUserHasPreregistered'];
				},

				membershipStatus() {
					return this.$store.getters['sso/getMembershipStatus'];
				}
			},

			methods: {
				userLogin() {
					this.$store.dispatch('sso/userLogIn', this.$route);
				},
				userLogout() {
					this.$store.dispatch('sso/userLogOut');
				},
				userRegister() {
					this.$store.dispatch('sso/userRegister', this.$route);
				},
				editAccount() {
					this.$store.dispatch('sso/editAccount');
				},

				loadUserPreregistration() {
					if (this.isUserLoggedIn && !this.userPreregistrationData) {
						this.$store.dispatch('sso/loadUserPreregistration');
					}
				},

				storeUserPreregistration(preferences = {}) {
					return this.$store.dispatch('sso/storeUserPreregistration', preferences);
				},

				subscribeNewsletter() {
					return this.$store.dispatch('sso/subscribeNewsletter', window.location.search);
				},

				subscribeNewsletterSimple() {
					return this.$store.dispatch('sso/subscribeNewsletterSimple');
				},

				loadMembershipStatus() {
					return this.$store.dispatch('sso/loadMembershipStatus');
				}
			}
		});
	}
};
