<template>
	<div class="hero" :class="{ 'hero--no-gradient': hasNoBgGradient }" :style="`height: ${heroHeight}`">
		<div class="hero__image" :class="{ 'hero__image--has-glow': hasGlow }">
			<transition name="fade" mode="out-in">
				<!-- <img :src="cdnUrl(currentHeroImage)" alt="" :key="currentHeroImage" /> -->
				<img
					class="img-lazy"
					v-lazy="cdnUrl(`${currentHeroImage}?fm=jpg&fl=progressive&w=1920&q=80`)"
					alt=""
					:key="currentHeroImage"
				/>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Hero',

	computed: {
		hasGlow() {
			return this.$route.name === `index`;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'] || null;
		},

		hasNoBgGradient() {
			return this.shopConfig && this.shopConfig.disableBackgroundGradient;
		},

		heroHeight() {
			return this.hasNoBgGradient && this.viewport ? `${this.viewport.height}px` : 'auto';
		},

		currentHeroImage() {
			let stepImageObject = null;
			const shopTheme = this.shopConfig && this.shopConfig.shopTheme && this.shopConfig.shopTheme.fields;

			// Optional background per page
			const ctfImageSlug = {
				'slug-checkout': 'backgroundOrderCompleted',
				'slug-paymentstatus': 'backgroundOrderCompleted',
				'slug-tickets': 'backgroundTickets',
				'slug-membership': 'backgroundMembership',
				'slug-travel': 'backgroundTravel',
				'slug-travel-detail': 'backgroundTravel',
				'slug-traveladdon': 'backgroundTravel',
				'slug-traveladdon-detail': 'backgroundTravel',
				'slug-transportation': 'backgroundTransportation',
				'slug-transportation-eu': 'backgroundTransportation',
				'slug-transportation-nl': 'backgroundTransportation',
				'slug-merchandise': 'backgroundMerchandise',
				'slug-extras': 'backgroundExtras'
			}[this.$route.name];

			if (ctfImageSlug && shopTheme) {
				stepImageObject = shopTheme[ctfImageSlug];
			}

			if (stepImageObject) {
				return stepImageObject.fields.file.url;
			}

			// If no page bg image found, use the shoptheme bg image or even the bg image from the ossConfig
			const heroImage = this.shopConfig && this.shopConfig.festivalData && this.shopConfig.festivalData.heroImage;
			return heroImage || this.$store.getters.getMainBackgroundImage;
		}
	}
};
</script>

<style lang="scss">
$component: 'hero';

.#{$component} {
	position: absolute;

	top: 0;

	width: 100%;

	z-index: -1;

	&:not(&--no-gradient):after {
		content: '';
		display: block;

		position: absolute;

		bottom: 1%;
		left: 0;

		width: 100%;
		height: 300px;

		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--content-background) 100%);
	}

	&--no-gradient {
		position: fixed;
		.#{$component} {
			&__image {
				top: $nav-height;
				height: calc(100% - #{$nav-height});
				> img {
					height: 100%;
					@include object-fit(cover, center);
				}
			}
		}
	}

	&__image {
		position: relative;

		width: 100%;
		height: 100%;

		&:before {
			content: '';
			display: block;
			position: absolute;

			z-index: 1;

			width: 100%;
			height: 100%;

			opacity: 0;
			background-color: #171722;

			transition: all 1s $ease-in-out-cubic;
		}

		&--has-glow {
			&:before {
				display: block;
				opacity: 0.6;
				transition: all 1s $ease-in-out-cubic;
			}
		}

		> img {
			width: 100%;
			// height: 730px;

			// @include object-fit(cover, top);
		}
	}

	@include sm {
		&:after {
			height: 300px;
		}

		&--thunderdome {
			.hero__image > img {
				height: 100%;
				object-fit: cover;
			}
		}

		&__image {
			> img {
				height: 530px;
			}
		}
	}
}
</style>
