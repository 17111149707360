<template>
	<component
		:is="tag"
		class="col"
		:class="{
			[`col-s--${s}`]: s,
			[`col-m--${m}`]: m,
			[`col-l--${l}`]: l,

			[`col-s-offset--${sOffset}`]: sOffset,
			[`col-m-offset--${mOffset}`]: mOffset,
			[`col-l-offset--${lOffset}`]: lOffset,

			'no-margin': noMargin,
			's-no-margin': sNoMargin,
			'm-no-margin': mNoMargin,
			'l-no-margin': lNoMargin,

			's-hide': sHide
		}"
		:style="{
			order
		}"
	>
		<slot />
	</component>
</template>

<script>
export default {
	props: {
		tag: {
			type: String,
			default: 'div'
		},
		s: {
			type: Number,
			default: 12
		},
		m: Number,
		l: Number,

		sOffset: Number,
		mOffset: Number,
		lOffset: Number,

		noMargin: Boolean,
		sNoMargin: Boolean,
		mNoMargin: Boolean,
		lNoMargin: Boolean,

		sHide: Boolean,

		order: Number
	}
};
</script>

<style lang="scss">
$component: 'col';
$columns: 12;

.#{$component} {
	position: relative;

	&:not(.no-margin) {
		@include sm {
			margin-left: $global-spacing--mobile;
			margin-right: $global-spacing--mobile;

			&.s-no-margin {
				margin-left: 0;
				margin-right: 0;
			}
		}

		@include md {
			margin-left: $global-spacing;
			margin-right: $global-spacing;

			&.m-no-margin {
				margin-left: 0;
				margin-right: 0;
			}
		}

		@include lg {
			margin-left: $global-spacing;
			margin-right: $global-spacing;

			&.m-no-margin,
			&.l-no-margin {
				margin-left: 0;
				margin-right: 0;
			}
		}

		@for $i from 1 through $columns {
			&.#{$component} {
				&-s--#{$i} {
					@include sm {
						width: calc(#{((calc(100 / $columns)) * $i) + '%'} - #{$global-spacing--mobile * 2});

						&.s-no-margin {
							width: #{((calc(100 / $columns)) * $i) + '%'};
						}
					}

					@include md-lg {
						width: calc(#{((calc(100 / $columns)) * $i) + '%'} - #{$global-spacing * 2});
					}
				}

				&-s-offset--#{$i} {
					@include sm {
						margin-left: calc(#{((calc(100 / $columns)) * $i) + '%'} + #{$global-spacing--mobile * 2});

						&.s-no-margin {
							margin-left: #{((calc(100 / $columns)) * $i) + '%'};
						}
					}

					@include md-lg {
						margin-left: calc(#{((calc(100 / $columns)) * $i) + '%'} + #{$global-spacing * 2});
					}
				}
			}
		}

		@include md-lg {
			@for $i from 1 through $columns {
				&.#{$component} {
					&-m--#{$i} {
						&:not(.m-no-margin) {
							width: calc(#{((calc(100 / $columns)) * $i) + '%'} - #{$global-spacing * 2});
						}

						&.m-no-margin {
							width: #{((calc(100 / $columns)) * $i) + '%'};
						}
					}

					&-m-offset--#{$i} {
						&:not(.m-no-margin) {
							margin-left: calc(#{((calc(100 / $columns)) * $i) + '%'} + #{$global-spacing});
						}

						&.m-no-margin {
							margin-left: #{((calc(100 / $columns)) * $i) + '%'};
						}
					}
				}
			}
		}

		@include lg {
			@for $i from 1 through $columns {
				&.#{$component} {
					&-l--#{$i} {
						&:not(.l-no-margin) {
							width: calc(#{((calc(100 / $columns)) * $i) + '%'} - #{$global-spacing * 2});
						}

						&.l-no-margin {
							width: #{((calc(100 / $columns)) * $i) + '%'};
						}
					}

					&-l-offset--#{$i} {
						&:not(.l-no-margin) {
							margin-left: calc(#{((calc(100 / $columns)) * $i) + '%'} + #{$global-spacing});
						}

						&.l-no-margin {
							margin-left: #{((calc(100 / $columns)) * $i) + '%'};
						}
					}
				}
			}
		}
	}

	&.no-margin {
		&.#{$component} {
			@for $i from 1 through $columns {
				&-s--#{$i} {
					width: #{((calc(100 / $columns)) * $i) + '%'};
				}

				&-s-offset--#{$i} {
					margin-left: #{((calc(100 / $columns)) * $i) + '%'};
				}
			}

			@include md-lg {
				@for $i from 1 through $columns {
					&-m--#{$i} {
						width: #{((calc(100 / $columns)) * $i) + '%'};
					}

					&-m-offset--#{$i} {
						margin-left: #{((calc(100 / $columns)) * $i) + '%'};
					}
				}
			}

			@include lg {
				@for $i from 1 through $columns {
					&-l--#{$i} {
						width: #{((calc(100 / $columns)) * $i) + '%'};
					}

					&-l-offset--#{$i} {
						margin-left: #{((calc(100 / $columns)) * $i) + '%'};
					}
				}
			}
		}
	}

	&.s-hide {
		@include sm {
			display: none;
		}
	}
}
</style>
