import Vue from 'vue';
import VueScroll from '@/plugins/VueScroll';
import StaticLanguage from '@/plugins/StaticLanguage';
import ErrorEvents from '@/plugins/ErrorEvents';
import SsoPlugin from '@/plugins/SsoPlugin';
import UseDataLayer from '~/plugins/UseDataLayer';
import Vue2TouchEvents from 'vue2-touch-events';
import 'gsap';

Vue.use(ErrorEvents);
Vue.use(VueScroll);
Vue.use(SsoPlugin);
Vue.use(UseDataLayer);
Vue.use(Vue2TouchEvents, {
	disableClick: false,
	touchClass: '',
	tapTolerance: 10,
	touchHoldTolerance: 400,
	swipeTolerance: 30,
	longTapTimeInterval: 400,
	namespace: 'touch'
});
Vue.use(StaticLanguage);
