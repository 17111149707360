// import Language from '@/static/data/staticLanguageData.json';
// import LanguageMana from '@/static/data/staticLanguageData_mm.json';

export default {
	install(Vue) {
		let language = new Vue({
			data: {
				// $languageData: process.env.SHOPBRAND != 'mananamanana' ? Language : LanguageMana
			}
		});

		Vue.mixin({
			computed: {
				$getStaticLanguageData() {
					return this.$store.getters.getStaticTranslationData;
				}
			}
		});
	}
};
