import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0b88c44c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _379f468a = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _194ba5b0 = () => interopDefault(import('../pages/_slug/checkout/index.vue' /* webpackChunkName: "pages/_slug/checkout/index" */))
const _3e0057ce = () => interopDefault(import('../pages/_slug/comparetransport/index.vue' /* webpackChunkName: "pages/_slug/comparetransport/index" */))
const _698ba366 = () => interopDefault(import('../pages/_slug/extras/index.vue' /* webpackChunkName: "pages/_slug/extras/index" */))
const _8dfe1700 = () => interopDefault(import('../pages/_slug/membership/index.vue' /* webpackChunkName: "pages/_slug/membership/index" */))
const _45552822 = () => interopDefault(import('../pages/_slug/merchandise/index.vue' /* webpackChunkName: "pages/_slug/merchandise/index" */))
const _d5f4eed2 = () => interopDefault(import('../pages/_slug/packages/index.vue' /* webpackChunkName: "pages/_slug/packages/index" */))
const _3ea6af24 = () => interopDefault(import('../pages/_slug/paymentstatus/index.vue' /* webpackChunkName: "pages/_slug/paymentstatus/index" */))
const _c6879266 = () => interopDefault(import('../pages/_slug/register/index.vue' /* webpackChunkName: "pages/_slug/register/index" */))
const _707ffada = () => interopDefault(import('../pages/_slug/tickets/index.vue' /* webpackChunkName: "pages/_slug/tickets/index" */))
const _4debc1d4 = () => interopDefault(import('../pages/_slug/transportation/index.vue' /* webpackChunkName: "pages/_slug/transportation/index" */))
const _2a84f378 = () => interopDefault(import('../pages/_slug/travel/index.vue' /* webpackChunkName: "pages/_slug/travel/index" */))
const _e9d3285c = () => interopDefault(import('../pages/_slug/traveladdon/index.vue' /* webpackChunkName: "pages/_slug/traveladdon/index" */))
const _7e3ce788 = () => interopDefault(import('../pages/_slug/upgrade/index.vue' /* webpackChunkName: "pages/_slug/upgrade/index" */))
const _1a5db185 = () => interopDefault(import('../pages/_slug/comparetransport/detail.vue' /* webpackChunkName: "pages/_slug/comparetransport/detail" */))
const _b8776738 = () => interopDefault(import('../pages/_slug/comparetransport/eu.vue' /* webpackChunkName: "pages/_slug/comparetransport/eu" */))
const _9abdd31c = () => interopDefault(import('../pages/_slug/comparetransport/nl.vue' /* webpackChunkName: "pages/_slug/comparetransport/nl" */))
const _72790a1c = () => interopDefault(import('../pages/_slug/transportation/eu.vue' /* webpackChunkName: "pages/_slug/transportation/eu" */))
const _fd5457ac = () => interopDefault(import('../pages/_slug/transportation/nl.vue' /* webpackChunkName: "pages/_slug/transportation/nl" */))
const _00295ff5 = () => interopDefault(import('../pages/_slug/extras/_id.vue' /* webpackChunkName: "pages/_slug/extras/_id" */))
const _2a838dbb = () => interopDefault(import('../pages/_slug/tickets/_id.vue' /* webpackChunkName: "pages/_slug/tickets/_id" */))
const _85024b36 = () => interopDefault(import('../pages/_slug/travel/_detail/index.vue' /* webpackChunkName: "pages/_slug/travel/_detail/index" */))
const _09cf5c1a = () => interopDefault(import('../pages/_slug/traveladdon/_detail/index.vue' /* webpackChunkName: "pages/_slug/traveladdon/_detail/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _0b88c44c,
    name: "login"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }, {
    path: "/:slug",
    component: _379f468a,
    name: "slug"
  }, {
    path: "/:slug/checkout",
    component: _194ba5b0,
    name: "slug-checkout"
  }, {
    path: "/:slug/comparetransport",
    component: _3e0057ce,
    name: "slug-comparetransport"
  }, {
    path: "/:slug/extras",
    component: _698ba366,
    name: "slug-extras"
  }, {
    path: "/:slug/membership",
    component: _8dfe1700,
    name: "slug-membership"
  }, {
    path: "/:slug/merchandise",
    component: _45552822,
    name: "slug-merchandise"
  }, {
    path: "/:slug/packages",
    component: _d5f4eed2,
    name: "slug-packages"
  }, {
    path: "/:slug/paymentstatus",
    component: _3ea6af24,
    name: "slug-paymentstatus"
  }, {
    path: "/:slug/register",
    component: _c6879266,
    name: "slug-register"
  }, {
    path: "/:slug/tickets",
    component: _707ffada,
    name: "slug-tickets"
  }, {
    path: "/:slug/transportation",
    component: _4debc1d4,
    name: "slug-transportation"
  }, {
    path: "/:slug/travel",
    component: _2a84f378,
    name: "slug-travel"
  }, {
    path: "/:slug/traveladdon",
    component: _e9d3285c,
    name: "slug-traveladdon"
  }, {
    path: "/:slug/upgrade",
    component: _7e3ce788,
    name: "slug-upgrade"
  }, {
    path: "/:slug/comparetransport/detail",
    component: _1a5db185,
    name: "slug-comparetransport-detail"
  }, {
    path: "/:slug/comparetransport/eu",
    component: _b8776738,
    name: "slug-comparetransport-eu"
  }, {
    path: "/:slug/comparetransport/nl",
    component: _9abdd31c,
    name: "slug-comparetransport-nl"
  }, {
    path: "/:slug/transportation/eu",
    component: _72790a1c,
    name: "slug-transportation-eu"
  }, {
    path: "/:slug/transportation/nl",
    component: _fd5457ac,
    name: "slug-transportation-nl"
  }, {
    path: "/:slug/extras/:id",
    component: _00295ff5,
    name: "slug-extras-id"
  }, {
    path: "/:slug/tickets/:id",
    component: _2a838dbb,
    name: "slug-tickets-id"
  }, {
    path: "/:slug/travel/:detail",
    component: _85024b36,
    name: "slug-travel-detail"
  }, {
    path: "/:slug/traveladdon/:detail",
    component: _09cf5c1a,
    name: "slug-traveladdon-detail"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
