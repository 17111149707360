<template>
	<div class="upsell-tile">
		<div class="upsell-tile__top">
			<div v-if="imageUrl" class="upsell-tile__image" :class="{ 'md-lg-only': data.hideVisualOnMobile }">
				<img :src="cdnUrl(imageUrl)" alt="" />
			</div>

			<div class="upsell-tile__content">
				<h3 class="upsell-tile__title">{{ data.title }}</h3>
			</div>
		</div>

		<div class="upsell-tile__bottom">
			<n-link class="upsell-tile__button" :to="tileRoute" @click.native="onClickTile">
				{{ data.buttonLabel }}
			</n-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'UpsellTile',

	props: {
		data: Object
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		imageUrl() {
			return (this.data.tileImage && this.data.tileImage.fields.file.url) || null;
		},

		tileRoute() {
			const routeName = {
				Packages: 'slug-packages',
				Tickets: 'slug-tickets',
				Travel: 'slug-travel',
				Transport: 'slug-transport',
				Merchandise: 'slug-merchandise',
				Extras: 'slug-extras'
			}[this.data.tileLink];

			return {
				name: routeName,
				params: {
					slug: this.$route.params.slug
				}
			};
		}
	},

	methods: {
		onClickTile() {
			if (window.dataLayer) {
				window.dataLayer.push({
					event: 'upsellClick',
					eventLabel: `${this.shopConfig.festivalData.title}${this.data.isCart ? ' - Cart' : ' - Overlay'} `,
					eventCategory: this.data.tileLink,
					eventAction: 'Add to cart'
				});
			}

			this.$emit('close');
		}
	}
};
</script>

<style lang="scss">
$component: 'upsell-tile';

.#{$component} {
	display: flex;
	flex-direction: column;

	height: 328px; // equal to upsell merchandise item height

	padding: 4px;

	font-size: 14px;
	color: $color-black;
	text-decoration: none;

	background-color: var(--card-bg-color);

	&__content {
		padding: 3px 5px 5px;
	}

	&__bottom {
		flex: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		padding: 5px;
	}

	&__image {
		line-height: 0;
	}

	&__title {
		margin-bottom: calc($global-spacing / 2);

		font-family: $font-regular;
		font-size: 18px;
		text-transform: uppercase;
	}

	&__button {
		// font-family: $font-bold;
		font-size: 16px;
		line-height: 30px;
		color: white;
		text-decoration: none;
		text-align: center;

		// background-color: rgba(var(--primary-color), 1);
		background-color: $color-black;

		html.no-touchevents & {
			transition: background-color 0.3s $ease-out-cubic;

			&:hover {
				background-color: rgba(var(--primary-color), 1);
			}
		}
	}

	@include sm {
		height: 321px;

		&__content {
			padding: 5px;
		}

		&__title {
			font-size: 14px;
		}
	}
}
</style>
