export default {
	cart: {
		insurance: {
			addon: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			toggle: 'Meer informatie',
			xcover: {
				uri: "<a href='https://www.xcover.com/nl/pds/booking-refund-protection-eu' target='_blank'>Meer informatie vind je hier</a>",
				body: 'Deze boekingsbescherming wordt aangeboden en uitgevoerd door onze partner XCover. Voor alle voorwaarden met betrekking tot deze boekingsbescherming verwijzen wij dan ook graag naar hun website.'
			}
		},
		order_fee: 'Bestelkosten',
		service_fee: 'Ticket Service Fee',
		subtotal: 'Subtotaal',
		tax: 'BTW',
		title: 'Je winkelwagen',
		total: 'Totaal',
		tourist_tax: {
			title: 'Toeristenbelasting',
			lead: 'Dit is een lokale belasting opgelegd door sommige gemeenten en steden, die wordt gebruikt om lokale faciliteiten te financieren en te onderhouden.Het bedrag zal variëren afhankelijk van de gemeente en is vaak een klein bedrag per persoon per nacht.In dit geval is het € per persoon per nacht '
		},
		entertainmentTax: {
			body: "In de kosten van een Grasnapolsky ticket, Camping 't Zilveruitje en een ticket incl. een camper/caravan is een borg inbegrepen. Indien de camper/caravan na afloop weer netjes meegenomen wordt naar huis en/of de gehuurde tent netjes achtergelaten wordt, wordt de borg binnen enkele weken na het Evenement wee sr teruggestort op de rekening van de tickethouder.",
			label: 'Borg'
		}
	},

	// TODO use this translations file to replace the error.json coming in from Contentful
	// ERRORS
	error: {
		accommodation_sold_out:
			'De geselecteerde items kunnen niet worden toegevoegd. Deze accommodatie is uitverkocht.Mensen voltooien nu hun boeking. Als de betalingen niet worden bevestigd, zal dit pakket weer beschikbaar zijn. ',
		accommodation_ticket_sold_out:
			'De geselecteerde items kunnen niet worden toegevoegd.De tickets zijn momenteel niet beschikbaar.Als betalingen niet worden bevestigd, is dit ticket weer beschikbaar. ',
		voucher_invald: 'Deze voucher-code is ongeldig',
		voucher_user: 'Deze voucher-code is al gebruikt',
		voucher_redeem_error: 'Er is een fout opgetreden bij het verzilveren van de voucher. Probeer het opnieuw. ',
		form_covid_not_accepted: 'U bent het nog niet eens met de terugbetalingsvoorwaarden van COVID.',
		form_has_errors: 'Corrigeer de fouten in het formulier om uw bestelling te voltooien.',
		form_incomplete: 'Vul alle vereiste velden in *',
		form_no_country_selected: 'U hebt nog geen land geselecteerd om de verzendkosten te berekenen.',
		form_no_insurance_choice: 'Geef aan of u een boekingsbescherming voor uw bestelling wilt toevoegen.',
		form_terms_not_accepted: 'Accepteer de algemene voorwaarden om uw bestelling te voltooien.',
		generic:
			'Probeer het opnieuw.Vernieuw deze pagina en voeg, indien nodig, lid van de wachtrij om opnieuw toegang te krijgen tot de ticketwinkel. ',
		invalid_request: 'Er is een ongeldig verzoek gedaan.Probeer het opnieuw. ',
		no_config_found: 'Een OSS -configuratie ontbreekt.Neem contact op met de ondersteuning. ',
		order_lookup_failed:
			'Er is een fout opgetreden bij het opzoeken van uw bestelling. Er ging iets mis aan onze zijde. Probeer het later opnieuw',
		order_lookup_invalid:
			'Uw bestelnummer lijkt ongeldig, kopieer het juiste nummer uit de e-mail die u heeft ontvangen.',
		order_lookup_no_order:
			'We kunnen geen bestelling vinden met dat bestelnummer. Kopieer het juiste bestelnummer uit van de e-mail die u heeft ontvangen.',
		page_not_found: 'Pagina niet gevonden.',
		shipping_unavailable: 'Verzending is momenteel niet beschikbaar voor uw locatie.',
		undefined: 'Er is een onbekende fout opgetreden.',

		// ALL 1000 ARE UNDEFINED SERVER ERRORS
		// ALL 2000 ARE USER AUTHENTICATED ERRORS
		// ALL 3000 ARE ACCOUNT RELATED ERRORS
		// ALL 4000 ARE GENERAL SHOP ERRORS/MESSAGES
		// ALL 5000 ARE PRODUCT ERRORS
		// ALL 6000 ERRORS ARE DESCISION ERRORS  CLOSE / PROCEED
		// ALL 7000 CODES ARE NOTIFICATIONS
		// ALL 8000 CODES ARE SHOP ERROR CODES
		code: {
			0: '--modal-closed--',
			1000: {
				title: 'Oeps...',
				message: 'Er ging iets mis.Probeer het opnieuw. '
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		failed: {
			header: {
				body: '',
				title: 'Betaling mislukt'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		pending: {
			header: {
				body: '',
				title: 'Bestelling wordt verwerkt.'
			},
			message:
				'De bestelling is afgerond maar wij wachten nog op de betaling. Zodra wij deze hebben ontvangen, sturen wij je de bevestiging per mail. Houd er rekening mee dat dit enkele minuten kan duren. Als het bedrag is afgeschreven maar je na 30 minuten nog geen bevestiging van ons hebt ontvangen, neem dan contact op met de klantenservice via: <a href="mailto:tickets@feestfabriek.nl">tickets@feestfabriek.nl</a>.'
		},
		canceled: {
			header: {
				body: '',
				title: 'Betaling geannuleerd'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		completed: {
			header: {
				title: 'YES! HET IS GELUKT!'
			},
			message:
				"<h2>HET AFTELLEN KAN BEGINNEN!</h2><br><h3>En nu?</h3><ul><br><li>Je order wordt verwerkt. Binnen 2 uur ontvang je een orderbevestiging in je mailbox.</li><li>Wanneer je betaling is verwerkt ontvang je een betaalbevestiging.</li><li>Je ontvangt ook een e-mail van onze partner Paylogic met daarin de details van je bestelling en meer info over wanneer je je tickets kunt personaliseren.</li><li>Heb je ook merchandise besteld? Dan wordt je bestelling binnen 2 – 7 werkdagen verzonden. Hiervan ontvang je een track & trace code per mail.<i></ul><br><br><p>Let op: je ontvangt de ticket(s) pas na personalisatie. Personaliseren is mogelijk vanaf 20 november.*<br>Hiervoor onvang je - je raadt het al - een mail van onze partner Paylogic.<br>*Jeugdkaarten hoeven niet gepersonaliseerd te worden. Deze ontvang je zodra de complete order is gepersonaliseerd.</a></p><i></ul><br><br><p>Vragen over je order? <a href='https://www.zwartecross.nl/info/contact/' target='_blank'>Neem contact met ons op</a></p>"
		},
		paymentProvider: {
			adyen: {
				message: ''
			},
			paylogic: {
				message: ''
			}
		}
	},
	savetimeModal: {
		cta: 'Inloggen of registreren',
		body: 'Login en bespaar tijd tijdens het afrekenen',
		title: 'Bespaar tijd'
	},

	// BUTTON AND ANCHOR LABELS
	label: {
		back: 'Terug',
		cancel: 'Annuleren',
		cart: {
			add: 'Toevoegen',
			add_to_cart: 'Toevoegen aan winkelwagen',
			add_to_wishlist: 'Toevoegen aan verlanglijst',
			add_to_selection: 'Toevoegen aan selectie',
			cart: 'Winkelwagen',
			complete_payment: 'Volledige betaling',
			complete_order: 'Complete bestelling',
			continue_shopping: 'Blijf winkelen',
			order: 'Klaar om te bestellen',
			remove: 'Verwijderen',
			remove_from_cart: 'Verwijderen uit winkelwagen',
			remove_from_wishlist: 'Verwijderen uit verlanglijstje',
			replace_item: 'Vervangen',
			pay: 'Betalen',
			save: 'Pre-registratie opslaan',
			wishlist: 'Verlanglijst'
		},
		choices: {
			eu: 'Europa',
			nl: 'Nederland',
			title: 'Vanaf waar vertrek je?'
		},
		category: {
			large: 'AANVRAAGFORMULIER GEORGANISEERD BUSVERVOER',
			small: 'Resultaten voor kleine groepen'
		},
		ticket: 'tickets',
		transportation: 'transport',
		travel: 'reis',
		try_again: 'Probeer het opnieuw',
		update: 'update',
		yes: 'Ja'
	},

	loading: 'Laden ...',
	loading_one_moment: 'Eén moment alsjeblieft ...',

	// MODALS
	modal: {
		save_time: {
			body: 'logNuInEnBespaarTijdTijdensHetAfrekenen',
			cta: 'Login van register',
			title: 'bespaarTijd'
		}
	},
	// PAGE CONTENT
	page: {
		checkout: {
			lead: 'U hebt 5 minuten om uw betaling te voltooien.',
			title: 'Selecteer uw betaalmethode'
		},
		extras: {
			lead: 'Selecteer uw aanvullende producten zoals parkeerkaarten, kluisjes en andere ervaringen.',
			title: "Extra's en andere ervaringen"
		},
		home: {
			lead: 'Welkom, kies een evenement om te beginnen.',
			title: '{Brand} One Stop Shop'
		},
		merchandise: {
			lead: 'Selecteer uw merchandise.',
			one_size: 'Eén maat past alles',
			title: 'Merchandise'
		},
		payment_status: {
			canceled: {
				title: 'Betaling geannuleerd',
				lead: '',
				message:
					'Helaas is er iets misgegaan met uw betaling en was uw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat u aan de achterkant van de wachtlijst wordt geplaatst.'
			},
			completed: {
				title: 'Bedankt',
				lead: '',
				message:
					'Bedankt voor uw bestelling. We zijn momenteel uw bestelling en betaling verwerken. Dit is geen bevestiging! Uw bestelling wordt bevestigd nadat u een bevestigings-e-mail hebt ontvangen. Als u geen bevestigings-e-mail binnen 2 ontvangtUren, controleer uw bankrekening of creditcard om te zien of de betaling succesvol was.'
			},
			error: {
				title: 'Betaling mislukt',
				lead: '',
				message:
					'Helaas is er iets misgegaan met uw betaling en was uw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat u aan de achterkant van de wachtlijst wordt geplaatst.'
			},
			failed: {
				title: 'Betaling mislukt',
				lead: '',
				message:
					'Helaas is er iets misgegaan met uw betaling en was uw bestelling niet succesvol. Het is mogelijk om opnieuw de winkel binnen te gaan, maar houd er rekening mee dat u aan de achterkant van de wachtlijst wordt geplaatst.'
			},
			pending: {
				title: 'Betaling is in behandeling',
				lead: '',
				message:
					"<p> Bedankt voor uw bestelling. We wachten nog steeds op de definitieve bevestiging van uw betaling door de betalingsprovider. </p> <p> Maak je geen zorgen, normaal gesproken krijgen we de bevestiging binnen een paar minuten. </p> <p> <u> Dit zal hierna gebeuren: </u> </p> <ul> <li> Uw bestelling en betaling worden verwerkt </li> <li> U ontvangt een bevestigingse-e-mail </li> <li> U ontvangt een trackingcode zodra we uw bestelling verzenden (indien van toepassing) </li> </ul> <br /> <br /> <p> Als het geld wordt afgetrokken van uw bankrekeningEn u krijgt geen bevestiging binnen 2 uur, neem contact op met <a target='_blank' href='https://www.justleep.it/'> Blaep klantenondersteuning </a>. </p> <p>U kunt ook contact met hen opnemen voor andere vragen over uw bestelling. </p> "
			},

			newsletter:
				'Meld u aan voor de nieuwsbrief en wees de eerste die op de hoogte is van belangrijke aankondigingen.',
			payment_provider: {
				adyen: {
					message:
						"Als u vragen heeft over uw betaling of bestelling, neem dan contact op met <a target='_blank' href='https://www.justleep.it/'> Blaap klantenservice </a>."
				},
				paylogic: {
					message:
						"Als u vragen heeft over uw betaling of bestelling, neem dan contact op met <a target='_blank' href='https://customerservice.paylogic.com/'> Paylogic klantenservice </a>."
				}
			}
		},
		shop: {
			experience_prebuilder: {
				form: ['Ik ben geïnteresseerd in', 'ik zal voorbij reizen', 'Hoeveel personen?'],
				lead: "Klik op 'Tickets Only' om tickets te bestellen. Gaat u voor de alles-in-één ervaring inclusief accommodaties? Klik op 'Travel & Stay-pakketten' voor alle reis- en accommodatiepakketten.",
				title: 'Creëer uw ervaring'
			},
			experience_builder: {
				choose: 'Kies uw',
				select: 'Selecteer uw',
				extras: {
					label: "Extra's en andere ervaringen",
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				lead: 'Welke producten zou u aan uw winkelwagen willen toevoegen?',
				merchandise: {
					label: 'Merchandise',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				ticket: {
					label: '{Event} tickets',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				title: 'Je winkelwagen',
				transportation: {
					label: 'Georganiseerd bustransport',
					remove: 'Verwijderen',
					update: 'Wijzigen/toevoegen'
				},
				travel: {
					label: 'travel & Stay Packages',
					remove: 'Verwijderen',
					update: 'Update boeken'
				}
			},
			lead: 'Alles wat je nodig hebt in één winkel.',
			title: 'Winkel'
		},
		shop_addon: {
			experience_prebuilder: {
				lead: 'U kunt nu producten toevoegen zoals kluisjes, parkeerkaarten, reizen en accommodatiepakketten en merchandise aan uw winkelmandje.',
				title: "{Event} Extra's winkel"
			},
			lead: 'Een overzicht van uw vorige bestelling (s).',
			loading: {
				title: 'Uw vorige bestelling controleren ...',
				lead: 'We zoeken naar uw vorige bestelling (en).Vernieuw deze pagina niet, het laden van uw vorige bestelling (en) kan tot een minuut duren. '
			},
			no_order: {
				lead: 'Voer het bestelnummer in dat u in uw e -mail hebt ontvangen (het bestelnummer dat u van de hoofdboeker hebt ontvangen).Zorg ervoor dat u bent ingelogd met hetzelfde account dat is gebruikt om uw bestelling te plaatsen. ',
				title: 'Er zijn geen eerdere bestellingen gevonden.'
			},
			title: 'Uw gekochte product (s).'
		},
		old: {
			package: {
				addons: 'Je gekozen uitbreidingen in het winkelmandje',
				summary: 'Samenvatting van je gekozen pakket'
			},
			changedorder:
				'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
			referenceLabel: 'Jouw ordernummer'
		},
		tickets: {
			filter_explain: 'Filter toegangskaarten',
			includes: 'Ticket inclusief',
			lead: 'Selecteer uw toegangskaarten.',
			remove: 'Tickets verwijderen',
			select: 'Selecteer uw tickets',
			sold_out: 'Uitverkocht',
			sold_out_combo:
				'Tickets zijn uitverkocht!Een beperkt bedrag kan nog steeds beschikbaar zijn in combinatie met {link} ',
			title: 'Tickets',
			upsell_accommodations: 'Accommodatiepakketten zijn beschikbaar voor u om aan uw bestelling toe te voegen.',
			upsell_extras: 'Extra producten zijn beschikbaar voor u om aan uw bestelling toe te voegen.',
			upsell_travel: 'Reispakketten zijn beschikbaar voor u om aan uw bestelling toe te voegen.'
		},
		transportation: {
			add: 'Toevoegen',
			departure_time: '*Geschatte vertrektijden',
			fee: '*Excl.€ 5,- servicekosten per bestelling ',
			from: 'Van',
			lead: 'Selecteer uw georganiseerde bustransport.',
			netherlands: 'The Nederlands',
			remove: 'Verwijderen',
			select_other: 'Selecteer een andere optie.',
			sold_out: 'Uitverkocht',
			tickets: 'Buskaartjes',
			title: 'Georganiseerd bustransport',
			to: 'Naar'
		},
		travel: {
			accommodation_extras: "accommodatieExtra's",
			available: 'Beschikbaar',
			arrival_date: 'Aankomstdatum',
			arrival_date_time: 'Aankomst dag en tijd',
			create_package: 'Maak uw pakket',
			departure_date: 'Vertrekdatum',
			includes: 'Pakket omvat',
			lead: 'Selecteer uw reis- en verblijfpakketten.',
			number_of_nights: 'Aantal nachten',
			no_packages: 'Geen pakket (s) gevonden',
			no_ticket: 'Geen ticket (s) gevonden',
			package_price: 'Prijs voor reispakket',
			persons: 'Aantal personen',
			room_setup: 'Kies uw kamerinstelling',
			selected: 'Geselecteerd',
			select_other: 'Selecteer een andere optie.',
			sold_out: 'Uitverkocht',
			tickets: 'tickets',
			title: 'travel &StayPackages',
			try_again: 'Probeer het opnieuw',
			warning: {
				title: 'Warning',
				message:
					'Je hebt al tickets in je winkelwagentje.Weet u zeker dat u extra tickets aan uw reispakket wilt toevoegen? '
			}
		}
	},
	upsellCheckOut: {
		title: 'Anderen kochten ook:'
	},
	membershipMonth: {
		header: {
			body: '',
			title: 'LIDMAATSCHAP'
		},
		disclaimerStep:
			'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 maand vooruit gefactureerd. Het lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
		membershipItem: {
			includes: 'Membership omvat'
		},
		disclaimerVoucher:
			'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will become inactive for approx. 30 mins. Please try again later.',
		disclaimerCheckout:
			'Je gaat een lidmaatschap aan voor 1 jaar. Je gaat ermee akkoord dat Abonnementenland 1 maand vooruit in rekening brengt. Je lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap eerder opzegt. Meer informatie over de inhoud van het lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>'
	},
	experiencebuilder: {
		body: 'Welke producten zou je willen toevoegen aan je {type}?',
		title: 'Jouw {type}',
		blocks: {
			extras: {
				slug: 'extras',
				label: 'Overige producten',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			ticket: {
				slug: 'tickets',
				label: 'Tickets',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			travel: {
				slug: 'reizen',
				label: 'reis- en accomodatiepakketten',
				remove: 'Verwijderen',
				update: 'Update Booking'
			},
			upgrade: {
				slug: 'upgrade',
				label: 'Upgrade',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			membership: {
				slug: 'lidmaatschap',
				label: 'Lidmaatschap',
				remove: 'Verwijderen'
			},
			merchandise: {
				slug: 'merchandise',
				label: 'Merchandise',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			traveladdon: {
				slug: "reis extra's",
				label: "Reis- en accomodatiepakketten extra's",
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			transportation: {
				slug: 'vervoer',
				label: 'georganised busvervoer',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			}
		},
		preregister: {
			body: '{typesale}<br/>Harder! verkoop begint',
			title: 'Je hebt je succesvol geregistreerd voor'
		},
		savepreregister: {
			body: "Tijdens de Harder!-kaartverkoop kun je jouw complete verlanglijst toevoegen aan je winkelmandje. Zo kun je meteen al je favoriete producten in één keer bestellen. Houd er wel rekening mee dat bepaalde producten al uitverkocht kunnen zijn, want de verlanglijst geeft je geen garantie op producten.<br/><br/>Vragen over je registratie: <a href='mailto:info@feestfabriek.nl'>info@feestfabriek.nl</a>",
			title: 'Verlanglijst klaar?'
		}
	},
	experienceprebuilder: {
		form: ['Ik ben geïnteresseerd in', 'Ik zal reizen via', 'Hoeveel personen?'],
		header: {
			body: '',
			title: '{festival}'
		},
		bussale: {
			body: 'Boek georganiseerd busvervoer van en naar de {festival}.',
			title: '{festival} Shop'
		},
		addonsale: {
			body: 'Je kunt nu producten zoals kluisjes, parkeerkaarten, reis- en accomodatie pakketten en merchandise aan je winkelmandje toevoegen.',
			title: "{festival} extra's shop"
		},
		membersale: {
			body: 'Maak deel uit van een nieuwe Zwarte Cross-ervaring. Koop nu je ticket en extra merchandise voor de Zwarte Cross.',
			title: '{festival}'
		},
		prebuilder: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis- en Tickets' voor alle reis- en accomodatiepakketten."
		},
		travelsale: {
			body: 'Je kunt nu je reis- & accomodatiepakket inclusief alle andere beschikbare opties toevoegen aan je winkelmandje. Als je tevreden bent met je order, ga dan door naar de kassa. Houd er rekening mee dat je slechts 20 minuten hebt om je betaling af te ronden.',
			title: '{festival} reis- en accomodatie Harder!-kaartverkoop'
		},
		prebuilderAddon: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis & Tickets'  voor alle reis- en accomodatiepakketten."
		},
		preregistration: {
			body: "Je kunt nu producten zoals tickets, reis- en accomodatiepakketten, merchandise en extra's toevoegen aan je verlanglijst. Je verlanglijst kan worden opgeslagen in je account.",
			title: '{festival} wishlist'
		},
		preregistrationprebuilder: {
			body: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.',
			title: '{festival} registratie'
		}
	},

	cart: {
		insurance: {
			addon: 'Voeg boekingsbescherming voor al je tickets toe voor slechts',
			toggle: 'Meer informatie',
			xcover: {
				uri: "<a href='https://www.xcover.com/nl/pds/booking-refund-protection-eu' target='_blank'>Meer informatie vind je hier</a>",
				body: 'Deze boekingsbescherming wordt aangeboden en uitgevoerd door onze partner XCover. Voor alle voorwaarden met betrekking tot deze boekingsbescherming verwijzen wij dan ook graag naar hun website.'
			}
		},
		touristTax: {
			body: 'Toeristenbelasting is een bedrag dat door de gemeente wordt gevraagd. In dit geval is dat € 5,- per campingbezoeker.',
			label: 'Toeristenbelasting'
		},
		entertainmentTax: {
			body: "In de kosten van een Grasnapolsky ticket, Camping 't Zilveruitje en een ticket incl. een camper/caravan is een borg inbegrepen. Indien de camper/caravan na afloop weer netjes meegenomen wordt naar huis en/of de gehuurde tent netjes achtergelaten wordt, wordt de borg binnen enkele weken na het Evenement weer teruggestort op de rekening van de tickethouder.",
			label: 'Borg'
		}
	},
	home: {
		body: 'Godsallemachtig wat was het weer een feest, onniedan?! Haal dat scheve agendaatje van ow maar gauw tevoorschijn en kras er met varkensstift in: ZWARTE CROSS 2025: 17-18-19-20 juli!<br><br>Inschrijven crossklassen: 2 november 2024<br><br>Harder!-kaartverkoop: 16 november 2024<br><br>Wereldwijde kaartverkoop: 23 november 2024',
		title: 'Ticketshop Zwarte Cross',
		button: 'Shop',
		membersale: 'Shop',
		travelsale: 'Vervoer- en accomodatie verkoop ',
		preregistration: 'Verlanglijst'
	},
	addon: {
		body: {
			loading:
				'We halen je vorige order(s) op. Refresh deze pagina niet, het laden van je vorige order(s) kan tot een minuut duren.',
			noorder:
				"Vul een ordernummer in dat je hebt ontvangen van Paylogic (het ordernummer dat je hebt ontvangen van de hoofdboeker). Zorg ervoor dat je bent ingelogd met het Zwarte Cross-account dat is gebruikt om je order te plaatsen. Als je op zoek bent naar tickets, bezoek dan de <a href='{uri}'>shop</a>.",
			overview: 'Een overzicht van je vorige order(s).'
		},
		title: {
			loading: 'Vorige order(s) checken',
			noorder: 'Er zijn geen vorige orders gevonden.',
			overview: 'Jouw gekochte producten'
		},
		button: {
			lookup: 'Vind order',
			continue: 'Maak je ervaring compleet'
		},
		lookup: {
			failed: 'Er is iets misgegaan aan onze kant. Probeer het later opnieuw',
			invalid: 'Jouw ordernummer lijkt ongeldig, kopieer het juiste nummer uit de Paylogic mail.',
			'no-order':
				'We kunnen geen order vinden met dat ordernummer. Kopieer het juiste ordernummer uit de Paylogic mail.'
		},
		package: {
			addons: 'Je gekozen uitbreidingen in het winkelmandje',
			summary: 'Samenvatting van je gekozen pakket'
		},
		changedorder:
			'Houd er rekening mee dat deze order mogelijk niet de meest recente boekingsgegevens weergeeft, omdat we jouw order op jouw verzoek handmatig hebben gewijzigd. Geen zorgen, we hebben je meest recente boekingsgegevens in ons boekingssysteem en in deze shop vind je alle mogelijke uitbreidingen die geschikt zijn voor je vervoer- en accomodatiepakket.',
		referenceLabel: 'Jouw ordernummer'
	},
	extras: {
		header: {
			body: 'Onderstaande producten zijn af te halen op de Zwarte Cross-camping.',
			title: 'Overige producten'
		}
	},
	popups: {
		shipmentNotAvailable: {
			body: 'Helaas is het niet mogelijk om naar het gekozen land te versturen',
			title: 'De producten zijn verwijderd uit het winkelmandje'
		}
	},
	travel: {
		header: {
			body: '',
			title: 'Vervoer- en accomodatiepakket'
		},
		package: {
			includes: 'Package bevat'
		},
		noticket: {
			title: 'Geselecteerd'
		}
	},
	upsell: {
		cta: 'Meer producten',
		body: 'Voeg deze producten toe aan je winkelmandje',
		title: 'De producten zijn toegevoegd aan je winkelmandje'
	},
	buttons: {
		buyAddons: 'Bestel uitbreidingen',
		learnMore: 'Meer information'
	},
	tickets: {
		header: {
			body: 'Max. 10 tickets per order',
			title: 'TICKETS'
		},
		ticketItem: {
			fee: 'Servicekosten',
			includes: ''
		}
	},
	checkout: {
		header: {
			body: '',
			title: 'Selecteer betaalmethode'
		},
		confetti: {
			message: 'Meer confetti!'
		},
		messages: {
			invalidData: 'Sommige velden bevatten missende of foute gegevens.'
		},
		emailWarning: {
			body: 'Onjuist e-mailadres'
		},
		paymentmethods: {
			message: 'Je betaalmethoden worden geladen'
		}
	},
	wishlist: {
		bodySale:
			"Je kunt nu controleren of je voorkeurstickets en andere items van je verlanglijst nog steeds beschikbaar zijn om te kopen. Zorg ervoor dat je ze aan je winkelmandje toevoegt als ze nog beschikbaar zijn. Product(en) uitverkocht? Je kunt een alternatief kiezen door op de knop 'vervangen' te klikken.",
		bodyLoading:
			'We halen je opgeslagen verlanglijst op. Refresh deze pagina niet, het laden van je verlanglijst kan tot een minuut duren.',
		titleLoading: 'Je verlanglijst wordt gecheckt',
		titleRegular: 'Jouw verlanglijst',
		bodyPreregistration: 'Maak je verlanglijst en bespaar tijd tijdens de verkoop'
	},
	membership: {
		header: {
			body: '',
			title: 'Lidmaatschap'
		},
		disclaimerStep:
			'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 jaar vooruit gefactureerd. Deze wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
		membershipItem: {
			includes: 'Lidmaatschap omvat'
		},
		disclaimerVoucher:
			'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will be become inactive for approx. 30 mins. Please try again later.',
		disclaimerCheckout:
			'Je staat op het punt om een 1-jarig Harder!-lidmaatschap aan te gaan. Je gaat ermee akkoord dat Abonnementenland 1 jaar vooruit in rekening brengt. Lidmaatschappen worden automatisch verlengd na de vervaldatum, tenzij je je abonnement opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>'
	},
	merchandise: {
		header: {
			body: 'Onderstaande producten worden verstuurd naar het adres dat wordt ingevuld bij het plaatsen van de order.',
			title: 'MERCHANDISE'
		},
		shippingerror: 'Helaas is het niet mogelijk om te verzenden naar het gekozen land.'
	},
	traveladdon: {
		detail: {
			extrasinfo:
				'Als je vragen hebt over de uitbreidingen op deze pagina, ga dan naar de FAQ op de Zwarte Cross website.'
		},
		header: {
			body: '',
			title: 'Jouw reis- en accomodatiepakket(ten)'
		},
		package: {
			includes: 'Pakket omvat'
		},
		noticket: {
			title: 'Geselecteerd'
		}
	},
	paymentstatus: {
		error: {
			header: {
				body: '',
				title: 'Betaling mislukt'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		failed: {
			header: {
				body: '',
				title: 'Betaling mislukt'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		pending: {
			header: {
				body: '',
				title: 'Bestelling wordt verwerkt.'
			},
			message:
				'De bestelling is afgerond maar wij wachten nog op de betaling. Zodra wij deze hebben ontvangen, sturen wij je de bevestiging per mail. Houd er rekening mee dat dit enkele minuten kan duren. Als het bedrag is afgeschreven maar je na 30 minuten nog geen bevestiging van ons hebt ontvangen, neem dan contact op met de klantenservice via: <a href="mailto:tickets@feestfabriek.nl">tickets@feestfabriek.nl</a>.'
		},
		canceled: {
			header: {
				body: '',
				title: 'Betaling geannuleerd'
			},
			message:
				'Helaas is er iets misgegaan met je betaling en is je order niet gelukt. Het is mogelijk om de shop opnieuw te betreden, maar houd er rekening mee dat je dan achteraan op de wachtrij komt te staan.'
		},
		completed: {
			header: {
				title: 'YES! HET IS GELUKT!'
			},
			message:
				"<h2>HET AFTELLEN KAN BEGINNEN!</h2><br><h3>En nu?</h3><ul><br><li>Je order wordt verwerkt. Binnen 2 uur ontvang je een orderbevestiging in je mailbox.</li><li>Wanneer je betaling is verwerkt ontvang je een betaalbevestiging.</li><li>Je ontvangt ook een e-mail van onze partner Paylogic met daarin de details van je bestelling en meer info over wanneer je je tickets kunt personaliseren.</li><li>Heb je ook merchandise besteld? Dan wordt je bestelling binnen 2 – 7 werkdagen verzonden. Hiervan ontvang je een track & trace code per mail.<i></ul><br><br><p>Let op: je ontvangt de ticket(s) pas na personalisatie. Personaliseren is mogelijk vanaf 20 november.*<br>Hiervoor onvang je - je raadt het al - een mail van onze partner Paylogic.<br>*Jeugdkaarten hoeven niet gepersonaliseerd te worden. Deze ontvang je zodra de complete order is gepersonaliseerd.</a></p><i></ul><br><br><p>Vragen over je order? <a href='https://www.zwartecross.nl/info/contact/' target='_blank'>Neem contact met ons op</a></p>"
		}
	},
	savetimeModal: {
		cta: 'Inloggen of registreren',
		body: 'Login en bespaar tijd tijdens het afrekenen',
		title: 'Bespaar tijd'
	},
	transportation: {
		header: {
			body: 'Reis met een georganiseerde busreis',
			title: 'Georganiseerd busvervoer'
		},
		choices: {
			eu: 'Europa',
			nl: 'Nederland',
			title: 'Vanaf waar vertrek je?'
		},
		category: {
			large: 'AANVRAAGFORMULIER GEORGANISEERD BUSVERVOER',
			small: 'Resultaten voor kleine groepen'
		},
		eventTravel: {
			body: {
				list: [
					'Comfortabele stoelen',
					'Airconditioning, toileten & muziek',
					'Neem je eigen drinken mee',
					'Onbeperkte hoeveelheid bagage'
				],
				title: 'Alle busreizen worden uitgevoerd door onze officiële partner Tripticket'
			},
			questions: {
				body: "Contact Tripticket:<br/><br/>+31(0)852733190</br><a href='mailto:help@tripticket.nl'>help@tripticket.nl</a><br/><br/>* Vertrektijden zijn onder voorbehoud. Je ontvangt je tickets ongeveer twee dagen voor vertrek. <Event name> met de definitieve vertrektijden.",
				title: 'Vragen over deze busreis of privé groepsaanvragen?'
			}
		}
	},
	upsellCheckOut: {
		title: 'Anderen kochten ook:'
	},
	membershipMonth: {
		header: {
			body: '',
			title: 'LIDMAATSCHAP'
		},
		disclaimerStep:
			'Het Harder!-lidmaatschap is een periodiek lidmaatschap. Het lidmaatschap wordt 1 maand vooruit gefactureerd. Het lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap opzegt. Meer informatie over het Harder!-lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>',
		membershipItem: {
			includes: 'Membership omvat'
		},
		disclaimerVoucher:
			'If you have a Q-dance Network Credit code you can redeem it here. Please note: if you abandon the checkout process after redeeming your code, your code will become inactive for approx. 30 mins. Please try again later.',
		disclaimerCheckout:
			'Je gaat een lidmaatschap aan voor 1 jaar. Je gaat ermee akkoord dat Abonnementenland 1 maand vooruit in rekening brengt. Je lidmaatschap wordt automatisch verlengd na de vervaldatum, tenzij je jouw lidmaatschap eerder opzegt. Meer informatie over de inhoud van het lidmaatschap vind je <a href="https://www.zwartecross.nl/ontdek/fanclub-harder/inhoud/" title="Harder!-lidmaatschap inhoud" target="_blank">hier</a>'
	},
	experiencebuilder: {
		body: 'Welke producten zou je willen toevoegen aan je {type}?',
		title: 'Jouw {type}',
		blocks: {
			extras: {
				slug: 'extras',
				label: 'Overige producten',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			ticket: {
				slug: 'tickets',
				label: 'Tickets',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			travel: {
				slug: 'reizen',
				label: 'reis- en accomodatiepakketten',
				remove: 'Verwijderen',
				update: 'Update Booking'
			},
			upgrade: {
				slug: 'upgrade',
				label: 'Upgrade',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			membership: {
				slug: 'lidmaatschap',
				label: 'Lidmaatschap',
				remove: 'Verwijderen'
			},
			merchandise: {
				slug: 'merchandise',
				label: 'Merchandise',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			traveladdon: {
				slug: "reis extra's",
				label: "Reis- en accomodatiepakketten extra's",
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			},
			transportation: {
				slug: 'vervoer',
				label: 'georganised busvervoer',
				remove: 'Verwijderen',
				update: 'Aanpassen/Toevoegen'
			}
		},
		preregister: {
			body: '{typesale}<br/>Harder! verkoop begint',
			title: 'Je hebt je succesvol geregistreerd voor'
		},
		savepreregister: {
			body: "Tijdens de Harder!-kaartverkoop kun je jouw complete verlanglijst toevoegen aan je winkelmandje. Zo kun je meteen al je favoriete producten in één keer bestellen. Houd er wel rekening mee dat bepaalde producten al uitverkocht kunnen zijn, want de verlanglijst geeft je geen garantie op producten.<br/><br/>Vragen over je registratie: <a href='mailto:info@feestfabriek.nl'>info@feestfabriek.nl</a>",
			title: 'Verlanglijst klaar?'
		}
	},
	experienceprebuilder: {
		form: ['Ik ben geïnteresseerd in', 'Ik zal reizen via', 'Hoeveel personen?'],
		header: {
			body: '',
			title: '{festival}'
		},
		bussale: {
			body: 'Boek georganiseerd busvervoer van en naar de {festival}.',
			title: '{festival} Shop'
		},
		addonsale: {
			body: 'Je kunt nu producten zoals kluisjes, parkeerkaarten, reis- en accomodatie pakketten en merchandise aan je winkelmandje toevoegen.',
			title: "{festival} extra's shop"
		},
		membersale: {
			body: 'Maak deel uit van een nieuwe Zwarte Cross-ervaring. Koop nu je ticket en extra merchandise voor de Zwarte Cross.',
			title: '{festival}'
		},
		prebuilder: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis- en Tickets' voor alle reis- en accomodatiepakketten."
		},
		travelsale: {
			body: 'Je kunt nu je reis- & accomodatiepakket inclusief alle andere beschikbare opties toevoegen aan je winkelmandje. Als je tevreden bent met je order, ga dan door naar de kassa. Houd er rekening mee dat je slechts 20 minuten hebt om je betaling af te ronden.',
			title: '{festival} reis- en accomodatie Harder!-kaartverkoop'
		},
		prebuilderAddon: {
			title: "Klik op 'Alleen tickets' om tickets te bestellen. Ga je voor de alles-in-één ervaring inclusief verblijf? Klik op 'Reis & Tickets'  voor alle reis- en accomodatiepakketten."
		},
		preregistration: {
			body: "Je kunt nu producten zoals tickets, reis- en accomodatiepakketten, merchandise en extra's toevoegen aan je verlanglijst. Je verlanglijst kan worden opgeslagen in je account.",
			title: '{festival} wishlist'
		},
		preregistrationprebuilder: {
			body: 'Registreer nu voor {festival} tickets of reis- en accomodatiepakketten.',
			title: '{festival} registratie'
		}
	},
	navigation: {
		overview: 'NL Overzicht',
		wishlist: 'Verlanglijst',
		previous: 'Vorige order(s)'
	}
};
