var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',[(_vm.getShowCheckout && _vm.getShopCart && _vm.getShopCart.status !== 'confirmed')?_c('div',{ref:"checkout",staticClass:"checkout",class:{ 'checkout--has-counter': _vm.showCounter },style:(_vm.heightStyle)},[_c('div',{staticClass:"checkout__header"},[_c('Grid',[_c('Column',[_c('div',{staticClass:"checkout__header-wrapper"},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.cartTitle))]),_vm._v(" "),(_vm.shopConfig.previewMode)?_c('span',{staticClass:"checkout__header-preview",domProps:{"innerHTML":_vm._s(_vm.shopConfig.previewModusCopy.body)}}):(!_vm.shopConfig.preregistration)?_c('p',{staticClass:"checkout__header-body"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.cartBody)+"\n\t\t\t\t\t\t\t")]):_vm._e()]),_vm._v(" "),(
								_vm.shopConfig.preregistration ||
								(_vm.getShopCart.status !== 'confirmed' && _vm.getShopCart.status !== 'in_payment')
							)?_c('button',{staticClass:"checkout__close",on:{"click":_vm.closeCart}},[_c('Close')],1):_vm._e()])])],1)],1),_vm._v(" "),_c('Grid',{staticClass:"checkout__inner"},[_c('Column',[(
						!_vm.showCheckoutForm &&
						_vm.getShopCart.status !== 'confirmed' &&
						_vm.getShopCart.status != 'in_payment'
					)?_c('Basket'):_vm._e(),_vm._v(" "),(_vm.showCheckoutForm && _vm.getShopCart.status === 'shopping')?_c('CheckoutForm'):_vm._e()],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }