/**
 * Preserves the UTM query parameters from the current URL and appends them to a new URL.
 *
 * @param {string} url - The new URL to append the preserved query parameters to.
 * @returns {string} The modified URL with preserved query parameters.
 */
export function preserveUTMParams(url) {
	try {
		if (url instanceof URL) {
			url = url.toString();
		}

		if (!url || typeof url !== 'string') throw new SyntaxError('Called with invalid url', url);

		let isRelative = url.startsWith('/');

		// If the new URL is a relative path, temporarily convert it to an absolute URL
		if (isRelative) url = window.location.origin + url;

		const currentUrl = window.location.href;

		const currentParams = new URLSearchParams(window.location.search);
		const newParams = new URLSearchParams(new URL(url).search);

		// Preserve current query parameters
		currentParams.forEach((value, key) => {
			if (key.startsWith('utm_')) {
				if (!newParams.has(key)) {
					newParams.append(key, value);
				}
			}
		});

		let preservedUrl = new URL(url);
		preservedUrl.search = newParams.toString();
		preservedUrl = preservedUrl.toString();

		if (isRelative) preservedUrl = preservedUrl.replace(window.location.origin, '');

		return preservedUrl;
	} catch (err) {
		return url;
	}
}

export default preserveUTMParams;
