<template>
	<div
		class="date-dropdown"
		:class="{
			'is-active': isActive,
			'is-small': isSmall,
			'is-invalid': isInvalid
		}"
	>
		<div
			class="date-dropdown__input-wrapper"
			v-for="(dropdown, index) in dropdowns"
			:key="index"
			:class="{
				[`is-${dropdown.id}`]: dropdown.id
			}"
		>
			<span class="date-dropdown__input-value">{{ getValue(dropdown) }}</span>
			<select
				class="date-dropdown__input"
				:id="dropdown.id"
				:name="dropdown.id"
				:required="required"
				v-model="date[dropdown.id]"
				@change="inputChangeHandler"
				@focus="inputFocusHandler"
				@blur="inputBlurHandler"
			>
				<option v-for="option in dropdown.options" :key="option.value" :value="option.value">
					{{ option.label }}
				</option>
			</select>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DateDropdown',

	props: {
		id: String,
		name: String,
		label: {
			type: String,
			default: 'Birthdate'
		},

		isSmall: Boolean,
		placeholder: String,
		required: Boolean,
		initialDate: Object,
		options: Array,

		force: Boolean
	},

	data() {
		return {
			date: {
				day: 0,
				month: 0,
				year: 0
			},
			isActive: false,
			isPasswordVisible: false,

			isInvalid: false
		};
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'] || null;
		},

		dropdowns() {
			const days = {
				id: 'day',
				placeholder: this.$t('form.day'),
				options: []
			};

			const months = {
				id: 'month',
				placeholder: this.$t('month'),
				options: []
			};
			const years = {
				id: 'year',
				placeholder: this.$t('year'),
				options: []
			};

			for (let day = 1; day <= 31; day++) {
				days.options.push({
					value: day,
					label: day
				});
			}

			for (let month = 1; month <= 12; month++) {
				months.options.push({
					value: month,
					label: this.months[month - 1]
				});
			}

			const currentYear = new Date().getFullYear();
			const minimumAge = 10;
			const maximumAge = 100;
			for (let year = currentYear - minimumAge; year >= currentYear - maximumAge; year--) {
				years.options.push({
					value: year,
					label: year
				});
			}

			return [days, months, years];
		},

		months() {
			return [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			];
		}
	},

	methods: {
		forceValueCheck() {
			if (this.force) {
				const { day, month, year } = this.date;
				if (!day || !month || !year) {
					this.isInvalid = true;
				} else {
					this.isInvalid = false;
				}
			} else {
				this.isInvalid = false;
			}
		},

		getValue(dropdown) {
			const value = this.date[dropdown.id];

			switch (dropdown.id) {
				case 'month':
					return this.months[value - 1] || dropdown.placeholder;
			}

			return value > 0 ? value : dropdown.placeholder;
		},

		inputChangeHandler() {
			this.forceValueCheck();
			this.$forceUpdate();

			const year = this.date.year != 0 ? this.date.year : undefined;

			const month = this.date.month.toString().padStart(2, '0');
			const day = this.date.day.toString().padStart(2, '0');

			const date = {
				value: `${year}-${month}-${day}`
			};

			this.$emit('input', date, 'dateOfBirth');
		},

		inputFocusHandler() {
			this.isActive = true;
		},

		inputBlurHandler() {
			this.isActive = false;
		},

		/**
		 * @return {'Yes, currently 18 years' | 'No' | 'Yes, 18 at event start'}
		 */
		getAgeRequirement() {
			const value = this.shopConfig.shopSettings?.fields?.ageRequirements ?? [];

			if (value.length > 0) {
				return value[0];
			}

			return 'No';
		}
	},

	mounted() {
		this.date = this.initialDate;

		// this.forceValueCheck();
		this.$nextTick(this.inputChangeHandler);
	}
};
</script>

<style lang="scss">
// @import '~scss/vars/index';

.date-dropdown {
	position: relative;

	display: flex;
	align-items: center;

	height: 50px;
	width: 100%;

	padding-left: 12px;

	// font-family: Helvetica, sans-serif;
	font-size: 12px;
	color: $color-grey;

	background-image: linear-gradient(
		180deg,
		var(--content-background-dark) 0%,
		var(--content-background-light-2) 100%
	);
	border: 1px solid #5e5e66;
	border-radius: 2px;
	box-shadow: inset 0px 0px 0px 2px $color-black;

	&.is-small {
		@media (min-width: 768px) {
			display: inline-flex;
			width: 50%;
		}
	}

	&__input-wrapper {
		position: relative;
		margin-right: 10px;

		@include md-lg {
			width: percentage(calc(1 / 3));
		}

		@include sm {
			flex: auto;
		}

		&:after {
			pointer-events: none;
			position: absolute;

			content: '';

			display: block;
			position: absolute;

			right: 10px;
			top: 48%;
			transform: translateY(-50%) rotate(45deg);

			border: solid var(--primary-body-color);
			border-width: 0 1px 1px 0;
			padding: 3px;

			content: '';
		}

		&:not(:last-of-type) {
			border-right: 1px solid rgba($color-grey, 0.3);
		}
	}

	&__input-value {
		display: block;
		width: 100%;
		padding: 6px 0;

		color: var(--primary-body-color);
		background-color: transparent;

		html.no-touchevents & {
			transition: color 0.3s;
		}
	}

	&__input {
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		opacity: 0;

		font-size: 16px;
	}
}
</style>
