<template>
	<div class="transportation-item">
		<div class="transportation-item__content">
			<div class="transportation-item__heading">
				<div class="transportation-item__icon">
					<Icon-bus />
				</div>

				<div class="transportation-item__location-details">
					<span class="transportation-item__country">{{ $t('page.transportation.netherlands') }}</span>

					<span class="transportation-item__city">{{ data.meta.city }}</span>

					<span class="transportation-item__pickup-location">{{ data.meta.location }}</span>
				</div>
			</div>

			<div class="transportation-item__content-block">
				<div class="transportation-item__block">
					<span class="transportation-item__country">
						{{ $t('page.transportation.to') }} {{ shopConfig.festivalData.title }}
					</span>
					{{ data.meta.departureDateTime | dateBus }}*
				</div>

				<div class="transportation-item__block">
					<span class="transportation-item__country">
						{{ $t('page.transportation.from') }} {{ shopConfig.festivalData.title }}
					</span>
					{{ data.meta.returnDateTime | dateBus }}*
				</div>
			</div>

			<div class="transportation-item__content-block">
				<div class="transportation-item__block">
					<span class="transportation-item__people">{{ `Ticket${value > 1 ? 's' : ''}` }}</span>
					{{ value }}
				</div>
			</div>

			<div class="transportation-item__price" v-if="showsoldoutWithPrice">{{ data.price | formatPrice }} p.p</div>

			<div class="transportation-item__price-disclaimer">
				<p>{{ $t('page.transportation.fee') }}</p>

				<p>{{ $t('page.transportation.departure_time') }}</p>
			</div>

			<div class="transportation-item__cta">
				<Button
					class="button--alternative"
					@click.native="addToCart(value)"
					:isDisabled="isShopUpdatePending || isSoldout"
					v-if="!isSelected"
				>
					<span class="transportation-item__button" v-if="!isSoldout">
						{{ $t('page.transportation.add') }} {{ value }} {{ $t('page.transportation.tickets') }}
					</span>

					<span class="transportation-item__button" v-else>{{ $t('page.transportation.sold_out') }}</span>
				</Button>

				<Button
					class="button--alternative"
					@click.native="addToCart(0, value)"
					:isDisabled="isShopUpdatePending"
					v-else
				>
					<span class="transportation-item__button">{{ $t('page.transportation.remove') }}</span>
				</Button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TransportationItem',

	props: {
		data: null,
		value: {
			type: Number,
			default: 1
		}
	},

	computed: {
		isSoldout() {
			return this.data.soldOut || this.data.stockAvailable <= 0;
		},

		showsoldoutWithPrice() {
			if (this.data.soldOut) {
				if (!this.shopConfig.soldoutPrice) {
					return false;
				}
				return true;
			}
			return true;
		},
		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		isShopUpdatePending() {
			return this.$store.getters['shopapi/getIsShopUpdatePending'];
		},

		isSelected() {
			return (this.shopCart && this.shopCart.products[this.data.id]) || false;
		}
	},

	methods: {
		addToCart(val, oldvalue = 0) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,

				params: {
					productId: this.data.id,
					quantity: val
				}
			};

			const tempData = {
				name: this.data.name,
				price: this.data.price,
				productId: this.data.id,
				amount: val ? val : oldvalue,
				shop: this.$store.getters['shopconfig/getData'].titleFull,
				type: this.data.mappedType
			};
			// if (val && oldvalue == 0) {
			// 	this.addToDataLayer({
			// 		products: [tempData]
			// 	});
			// } else {
			// 	this.removeFromDataLayer({
			// 		products: [tempData]
			// 	});
			// }

			this.$store
				.dispatch(`shopapi/putRequest`, payload)
				.then(response => {
					// console.log('api', response.data.data);
				})
				.catch(error => {
					this.apiErrorMsg =
						error.response &&
						error.response.data &&
						error.response.data.error &&
						error.response.data.error.message;

					if (this.apiErrorMsg) {
						this.$store.commit('setModalMessage', error.response.data.error.message); // message that gets shown by 8000 error modal
						this.$fireErrorEvent(8000);
					} else {
						const errorText = this.$t('generalError.body');
						const formatedError = this.apiErrorMsg
							? `${error.response.data.error.message}, cartId:${this.shopCart.id}`
							: `${errorText} cartId:${this.shopCart.id}`;
						this.$fireErrorEvent(formatedError);
					}
				});
		}
	}
};
</script>

<style lang="scss">
$component: 'transportation-item';

.#{$component} {
	display: block;

	background-color: var(--card-bg-color);
	// border: 2px solid $color-white;

	// color: $color-black;
	color: var(--primary-body-color);

	// box-shadow: inset 0px 0px 0px 2px #c5c5c5;

	padding: 4px;
	margin-bottom: 30px;

	&__content {
		padding: 40px 20px 30px;
	}

	&__heading {
		display: flex;
		align-items: center;

		// overflow: hidden;

		padding-bottom: 20px;
	}
	&__icon {
		width: 27px;
		margin-right: 17px;

		> svg {
			path {
				fill: #171722;
			}
		}
	}

	&__location-details {
		overflow: hidden;
	}

	&__country {
		display: block;
		font-family: $font-bold;
		font-size: 14px;
		text-transform: uppercase;
	}

	&__city {
		position: relative;
		display: block;
		font-family: $font-bold;
		font-size: 24px;
		line-height: 30px;
		text-transform: uppercase;

		width: 100%;

		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__pickup-location {
		display: block;
		font-size: 14px;
		height: 50px;
	}

	&__content-block {
		display: flex;
		justify-content: space-between;

		padding-bottom: 25px;
	}

	&__people {
		display: block;
	}

	&__price {
		font-family: $font-bold;
		padding-top: 10px;
	}

	&__cta {
		padding-top: 38px;
	}

	&__button {
		font-family: $font-bold;
		font-size: 16px;
	}

	&__disclaimer {
		padding-top: 20px;

		font-family: $font-regular-italic;
		font-size: 14px;
	}

	&__price-disclaimer {
		font-size: 14px;
		padding-top: 10px;
	}
}
</style>
