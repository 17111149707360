<template>

	<transition name="fade">

		<div class="upsell-notification" v-show="showUpsell">

			<!-- <div class="upsell-notification"> -->

			<div class="upsell-notification__box">

				<div class="upsell-notification__top">

					<close class="upsell-notification__close" @click.native="onClickClose" />

					<h2 class="upsell-notification__title"> {{this.$t('upsell.title') }} </h2>

					<p class="upsell-notification__body">{{ $t('upsell.body') }}</p>

					<div class="upsell-notification__products-slider">

						<div class="upsell-notification__nav" v-show="showArrows">

							<iconArrow class="upsell-notification__arrow" @click.native="goPrev" />

							<iconArrow
								class="upsell-notification__arrow upsell-notification__arrow--right"
								@click.native="goNext"
							/>

						</div>

						<div class="upsell-notification__slider" ref="slider">

							<div class="upsell-notification__slide" v-for="item in sliderItems" :key="item.id">

								<component :is="componentsData(item.type)" :data="item" @close="onClickClose" />

							</div>

						</div>

					</div>

				</div>

				<div class="upsell-notification__bottom">

					<div class="upsell-notification__buttons">

						<Button class="upsell-notification__button button--small" @click.native="onClickContinue">
							 {{ $t('upsell.cta') }}
						</Button>

						<Button
							class="upsell-notification__button button--alternative button--small"
							@click.native="onClickCheckout"
						>
							 {{ goToCart }}
						</Button>

					</div>

				</div>

			</div>

			<div class="upsell-notification__background" @click="onClickClose"></div>

		</div>

	</transition>

</template>

<script>
let Flickity;
import MerchandiseItem from '@/components/merchandise/MerchandiseItem';
import ExtraItem from '@/components/extras/ExtraItem';
import TicketItem from '@/components/tickets/TicketItem';
import UpsellTile from './UpsellTile';
import arraySort from 'array-sort';

export default {
	name: 'UpsellNotification',

	data() {
		return {
			expandNotification: false,
			filterdProducts: [],
			sliderWidth: 0
		};
	},

	components: {
		MerchandiseItem,
		ExtraItem,
		TicketItem,
		UpsellTile
	},

	computed: {
		showUpsell() {
			return (
				!this.getShowCheckoutForm &&
				!this.getShowCheckout &&
				this.filterdProducts.length &&
				this.expandNotification
			);
		},
		getShowCheckout() {
			return this.$store.getters.getShowCheckout;
		},

		getShowCheckoutForm() {
			return this.$store.getters.getShowCheckoutForm;
		},

		goToCart() {
			return this.shopConfig.preregistration
				? this.$t('upsellnotification.wishlist')
				: this.$t('upsellnotification.checkout');
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		ignoreOrderFee() {
			return this.$store.getters['shopconfig/getIgnoreOrderFee'];
		},

		totalPrice() {
			if (this.shopCart) {
				return this.ignoreOrderFee ? this.shopCart.totalAmountExcludingServiceCosts : this.shopCart.totalAmount;
			}
			return 0;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		products() {
			return this.shopConfig.upsellProducts;
		},

		sliderItems() {
			let items = [];

			if (this.filterdProducts.length) {
				this.filterdProducts.forEach(item => {
					if (item.tileName) {
						items.push({
							...item,
							type: 'upsellTile',
							highLighted: 1,
							isCart: false
						});
					} else {
						let product = this.productMetaData[item.type].find(meta => meta.id === item.id);

						if (product) {
							if (product.type === 'merchandise') {
								const groupId = product.id.split('_')[1];
								product = this.merchandiseGroupedBySizs[groupId];
							}

							if (!items.find(prod => prod.id === product.id)) {
								// No duplicates (from grouped merchandise)

								items.push({
									...product,
									isUpsell: true,
									isHighlight: !!item.highLighted,
									isCart: false
								});
							}
						}
					}
				});

				// arraySort(items, 'highLighted'); //array sorting
			}

			return [...items.filter(item => item.isHighlight), ...items.filter(item => !item.isHighlight)];
		},

		productMetaData() {
			return this.$store.getters['shopapi/getProducts'];
		},

		merchandiseData() {
			let products = [];

			const merchandiseProducts = this.filterdProducts.filter(item => item.type === 'merchandise');

			if (this.productMetaData && this.productMetaData.merchandise) {
				merchandiseProducts.forEach(item => {
					const product = this.productMetaData.merchandise.find(merch => merch.id === item.id);
					product && products.push(product);
				});
			}

			return products;
		},

		merchandiseGroupedBySizs() {
			/*
			 * We need to map the prducts with different sizes into one object with different sizes.
			 * The products comming for the API are not grouped so every prodycts need to be grouped.
			 * The first part of the product ID is uniek for the product, the second part is uniek for the product size (item.id.split('_')[1])
			 */
			let merchandiseProducts = {};

			this.merchandiseData.forEach((item, index) => {
				const id = item.id.split('_')[1];
				const product = merchandiseProducts[id] || { ...item };

				let size;

				if (item.meta && item.meta.vendorVariantTitle && item.meta.vendorVariantTitle != 'Default') {
					size = item.meta.vendorVariantTitle;
				}

				if (size.includes('/')) {
					size = size.replace(/\//g, ':');
				}
				let sizes = {
					id: item.id,
					stock: item.stockAvailable,
					size: size.toUpperCase() != 'ONE SIZE' ? size.split(':')[1].replace(/\s/g, '') : size
				};

				if (product.sizes) {
					product.sizes.push(sizes);
				} else {
					product.sizes = [sizes];
				}

				merchandiseProducts[id] = product;
			});

			Object.values(merchandiseProducts).forEach(product => {
				arraySort(product.sizes, 'id');
			});

			return merchandiseProducts;
		},

		routes() {
			return this.$store.getters.getShopRoutingFlow;
		},

		currRouteIndex() {
			return this.routes.findIndex(item => item.name === this.$route.name);
		},
		nextRoute() {
			return this.currRouteIndex < this.routes.length - 1 ? this.routes[this.currRouteIndex + 1] : null;
		},

		showArrows() {
			const numItems = this.sliderItems.length;
			return this.sliderWidth < numItems * 200 + (numItems - 1) * 10;
		}
	},

	watch: {
		totalPrice(val, oldVal) {
			if (
				val > oldVal &&
				// this.$route.name !== this.expBuilderRouteName &&
				this.$route.name === 'slug-tickets' &&
				!this.getShowCheckoutForm &&
				!this.getShowCheckout
			) {
				if (!this.expandNotification || this.filterdProducts.length === 0) {
					this.setFilterdProducts();
				}

				if (!this.flickity) {
					this.$nextTick(() => {
						this.flickity = new Flickity(this.$refs.slider, {
							contain: true,
							cellAlign: 'left',
							prevNextButtons: false,
							pageDots: false,
							dragThreshold: 10,
							initialIndex: 0
						});
					});
				}

				this.expandNotification = true;
			}
		},

		viewport() {
			this.sliderWidth = this.$refs.slider.offsetWidth;
		},

		showUpsell() {
			this.$nextTick(() => {
				this.sliderWidth = this.$refs.slider.offsetWidth;
			});
		}
	},

	methods: {
		componentsData(type) {
			const data = {
				merchandise: 'MerchandiseItem',
				ticket: 'TicketItem',
				upsellTile: 'UpsellTile'
			}[type];
			return data;
		},

		onClickContinue() {
			this.expandNotification = false;

			if (this.flickity) {
				this.$nextTick(() => {
					this.flickity.destroy();
					this.flickity = null;
				});
			}

			if (this.nextRoute) {
				this.$router.push({ path: this.nextRoute, query: this.$route.query });
			} else if (
				this.productMetaData &&
				this.productMetaData.merchandise &&
				this.productMetaData.merchandise.length
			) {
				this.$router.push({
					name: 'slug-merchandise',
					query: this.$route.query
				});
			}
		},

		onClickClose() {
			this.expandNotification = false;

			setTimeout(() => {
				if (this.flickity) {
					this.$nextTick(() => {
						this.flickity.destroy();
						this.flickity = null;
					});
				}
			}, 400);
		},

		onClickCheckout() {
			if (this.flickity) {
				this.flickity.destroy();
				this.flickity = null;
			}

			this.expandNotification = false;
			this.$store.commit('setShowCheckout', true);
		},

		goPrev() {
			this.flickity.previous();
		},

		goNext() {
			this.flickity.next();
		},

		setFilterdProducts() {
			if (this.shopCart && this.shopCart.products) {
				const addedMerchandiseIds = Object.keys(this.shopCart.products).map(id => id.split('_')[1]);

				this.filterdProducts = this.products.filter(item => {
					if (item.type === 'merchandise') {
						const merchItemId = item.id.split('_')[1];

						return !addedMerchandiseIds.includes(merchItemId); // If one size of this product is in cart, hide the whole product. Not just the size in the cart.
					} else {
						return !this.shopCart.products[item.id];
					}
				});
			} else {
				this.filterdProducts = this.products;
			}
		}
	},

	mounted() {
		Flickity = require('flickity');

		this.setFilterdProducts();

		if (!this.flickity) {
			this.$nextTick(() => {
				this.flickity = new Flickity(this.$refs.slider, {
					contain: true,
					cellAlign: 'left',
					prevNextButtons: false,
					pageDots: false,
					dragThreshold: 10,
					initialIndex: 0
				});
			});
		}
	}
};
</script>

<style lang="scss">
$component: 'upsell-notification';

.#{$component} {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 99999;

	top: 0;
	left: 0;

	width: 100vw;
	height: 100%;

	overflow-y: auto;

	&__background {
		position: absolute;

		z-index: -1;

		top: 0;
		left: 0;

		width: 100vw;
		height: 100%;

		background: rgba(var(--content-background-rgb), 0.9);
	}

	&__box {
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;

		padding: 30px;

		text-align: left;
		width: 90%;
		height: 600px;

		max-width: 1000px;

		background: var(--content-background-dark);
	}

	&__top {
		flex: 2;
	}

	&__bottom {
		align-self: flex-end;
		width: 100%;
	}

	&__title {
		padding-bottom: $global-spacing;
	}

	&__body {
		padding-bottom: $global-spacing;

		&:after {
			content: '';
			display: block;
			position: relative;

			width: 100%;
			height: 1px;

			margin-top: $global-spacing;

			background: rgba(var(--primary-color), 1);
		}
	}

	&__buttons {
		display: flex;
		justify-content: flex-end;
	}

	&__button {
		max-width: 200px;

		&:first-of-type {
			margin-right: $global-spacing;
		}
	}

	&__slider {
		overflow: hidden;
		position: relative;
		width: 100%;

		min-height: 200px;

		margin-bottom: $global-spacing;
	}

	&__slide {
		width: 200px;
		margin-right: 10px;

		display: inline-block;
	}

	&__products {
		padding-bottom: $global-spacing;
	}

	&__nav {
		display: flex;
		justify-content: flex-end;

		margin-bottom: $global-spacing;
	}

	&__arrow {
		cursor: pointer;

		width: 29px;
		height: 26px;

		padding: 5px 10px;

		&--right {
			transform: rotate(180deg);
		}

		html.no-touchevents &:hover {
			opacity: 0.4;
		}
	}

	&__close {
		position: absolute;
		cursor: pointer;
		right: calc($global-spacing / 2);
		top: calc($global-spacing / 2);

		height: 16px;
	}

	@include sm {
		width: 100%;
		height: 100%;

		&__box {
			width: 100%;
			height: auto;
		}

		&__top {
			padding-top: $global-spacing;
		}

		&__buttons {
			display: flex;
			flex-direction: column;
		}

		&__button {
			max-width: 100%;

			&:first-of-type {
				margin-right: 0;
				margin-bottom: calc($global-spacing / 2);
			}
		}
	}
}
</style>

