import countryPhone from './countryPhone.json';
import countryCodes from './countryCodes.json';

export default {
	data: [
		{
			type: 'Persoonlijke informatie',
			fields: [
				{
					type: 'SelectField',
					label: 'Geslacht *',
					name: 'gender',
					model: 'gender',
					slot: [
						{
							type: 'Man',
							value: 'male'
						},
						{
							type: 'Vrouw',
							value: 'female'
						},
						{
							type: 'Anders',
							value: 'other'
						}
					]
				},
				{
					type: 'InputField',
					slot: 'Voornaam *',
					name: 'firstname',
					model: 'firstName'
				},
				{
					type: 'InputField',
					slot: 'Achternaam *',
					name: 'lastname',
					model: 'lastName'
				},
				{
					type: 'SelectFieldCode',
					label: 'Landcode *',
					name: 'countrycode',
					model: 'countryCode',
					slot: countryPhone
				},
				{
					type: 'InputField',
					slot: 'Telefoonnummer *',
					name: 'phonenumber',
					model: 'phoneNumber'
				},
				{
					type: 'InputField',
					slot: 'E-mail *',
					name: 'email',
					model: 'email'
				},
				{
					type: 'DateField',
					label: 'Geboortedatum *',
					slot: 'Birthday',
					name: 'birthDay',
					model: 'dateOfBirth'
				}
			]
		},
		{
			type: 'Je Adres',
			fields: [
				{
					type: 'InputField',
					slot: 'Straatnaam *',
					name: 'streetName',
					model: 'address'
				},
				{
					type: 'InputField',
					slot: 'Huisnummer *',
					name: 'streetNumber',
					model: 'streetNumber'
				},
				{
					type: 'InputField',
					slot: 'Toevoeging',
					name: 'extension',
					model: 'streetNumberExtension'
				},
				{
					type: 'InputField',
					slot: 'Postcode *',
					name: 'postalCode',
					model: 'postalCode'
				},
				{
					type: 'InputField',
					slot: 'Plaats *',
					name: 'city',
					model: 'city'
				},
				{
					type: 'SelectFieldCountry',
					label: 'Land *',
					name: 'country',
					model: 'country',
					slot: countryCodes
				},
				{
					type: 'SelectFieldState',
					label: 'Provincie *',
					name: 'state',
					model: 'state'
				}
			]
		}
	]
};
