var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"grid-container",class:{
		'is-centered': _vm.centered,
		'is-fluid': _vm.fluid,
		'full-width': _vm.fullWidth,
		'no-margins': _vm.noMargins,
		's-no-margins': _vm.sNoMargins,
		'm-no-margins': _vm.mNoMargins,
		'l-no-margins': _vm.lNoMargins
	}},[_c('div',{ref:"innerGrid",staticClass:"grid",style:({
			flexDirection: _vm.reversed ? 'row-reverse' : 'row'
		})},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }