/**
 * @class
 * A language model for internationalisation
 * @param {object} item
 */
export default class LocaleLanguage {
	constructor(lang) {
		if (!lang) {
			// throw new Error('Language: lang is missing');
			console.error('Language: lang is missing');
			return null;
		}

		this.code =
			LocaleLanguage.LANGUAGES_CODES[lang] || LocaleLanguage.LANGUAGES_CODES[LocaleLanguage.DEFAULT_LANGUAGE];

		if (lang in LocaleLanguage.LANGUAGES_CODES === false) {
			this.language = 'unknown';
		} else {
			this.language = lang;
		}

		// Make this class read-only
		Object.freeze(this);
	}

	/* @static */
	static LANGUAGES_CODES = {
		English: 'en',
		Nederlands: 'nl',
		Español: 'es'
	};

	static DEFAULT_LANGUAGE = 'Nederlands';
}
