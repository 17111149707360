<template>
	<footer class="footer"></footer>
</template>

<script>
export default {
	name: 'Footer'
};
</script>

<style lang="scss">
$component: 'footer';

.#{$component} {
	display: block;
}
</style>
