export default {
	install(Vue) {
		Vue.mixin({
			computed: {
				cdnAssetsUrl() {
					return {
						mysteryland: '//d34p2ssrkxrp93.cloudfront.net'
						// qdance: '//shop-q-dance-com-cdn-images.akamaized.net'
					}[process.env.SHOPBRAND];
				}
			},

			methods: {
				cdnUrl(url) {
					if (this.cdnAssetsUrl) {
						return url.replace('//images.ctfassets.net', this.cdnAssetsUrl);
					}

					return url;
				}
			}
		});
	}
};
