import moment from 'moment';

export default {
	methods: {
		valDate(val) {
			return moment(val).isValid();
		},

		valString(val) {
			const isValidString = /^[0-9a-zA-Z\s-+]+$/gi.test(val);
			return val !== '' && val !== undefined && isValidString;
		},

		valNumber(val) {
			const regex = /^[-+]?\d+$/;
			return regex.test(val);
		},

		valEmail(val) {
			const regex =
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regex.test(String(val).toLowerCase());
		}
	}
};
