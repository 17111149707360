<template>
	<div class="basket-extras">
		<BasketItem
			v-for="(ticket, index) in basketItemsData"
			:data="ticket"
			:key="`ticket${index}`"
			@input="onInputChange"
		/>
	</div>
</template>

<script>
import BasketItem from './BasketItem';

export default {
	name: 'BasketExtras',

	components: { BasketItem },

	data() {
		return {
			extraCategoryLabels: {}
		};
	},

	computed: {
		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		extrasInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'].extras || [];
		},

		basketItemsData() {
			return this.extrasInBasket.map(extra => {
				return {
					id: extra.type.id,
					title: extra.type.name || '',
					price: extra.type.price,
					numberOfProducts: extra.numproducts,
					stock: extra.type.stockAvailable,
					maxAmount: extra.type.maxQuantity,
					image: extra.type.meta.image,
					icon: this.getIcon(extra.type.meta && extra.type.meta.category && extra.type.meta.category.slug),
					type: 'extra',
					data: extra
				};
			});
		},

		/* extrasCategoryData() {
			const extraCategories = {};

			this.extrasInBasket.forEach(extra => {
				const catSlug = (extra.type.meta.category && extra.type.meta.category.slug) || 'misc';

				if (!extraCategories[catSlug]) {
					extraCategories[catSlug] = [];
					this.extraCategoryLabels[catSlug] = extra.type.meta.category.title || 'Other';
				}

				extraCategories[catSlug].push(extra);
			});

			return extraCategories;
		}, */

		icon() {}
	},

	methods: {
		getIcon(cat) {
			if (cat) {
				const icon = {
					lockers: 'icon-lockers',
					'parking-tickets': 'icon-parking'
				}[cat];

				if (icon) {
					return icon;
				}
			}
			return 'IconBuilderExtras';
		},

		async onInputChange({ productId, amount }) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: productId,
					quantity: amount
				}
			};

			const addTicketsResult = await this.$store.dispatch(`shopapi/putRequest`, payload);

			if (!addTicketsResult || !addTicketsResult.status || addTicketsResult.status !== 200) {
				if (addTicketsResult.data && addTicketsResult.data.error && addTicketsResult.data.error.message) {
					this.$store.commit('setModalMessage', addTicketsResult.data.error.message); // message that gets shown by 8000 error modal
					this.$fireErrorEvent(8000);
				} else {
					console.log('Unknown error response:', addTicketsResult);
				}
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-extras';

.#{$component} {
}
</style>
