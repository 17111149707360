<template>
	<transition>
		<div class="shop-modal-addon" :class="{ 'shop-modal-addon--animated': delayed }" v-show="showModal">
			<div class="shop-modal-addon__header">
				<div class="shop-modal-addon__header-wrapper">
					<div class="shop-modal-addon__header-copy">
						<transition name="fade-fast" mode="out-in">
							<h1 :key="`title_${cartCopy.key}`">{{ cartCopy.title }}</h1>
						</transition>

						<transition name="fade-fast" mode="out-in">
							<p class="shop-modal-addon__body" :key="`body_${cartCopy.key}`" v-html="cartCopy.body"></p>
						</transition>
					</div>

					<!-- <button class="shop-modal-addon__close" v-if="sortedProductAddon.length > 0" @click="closeModal">
						<Close />
					</button> -->
				</div>
			</div>

			<Grid class="shop-modal-addon__inner">
				<Column :sNoMargin="true">
					<div class="shop-modal-addon__wrapper" v-if="delayed && sortedProductBasket">
						<div v-if="sortedProductAddon.length > 0">
							<div
								class="shop-modal-addon__order-wrapper"
								v-for="(order, orderKey) in sortedProductBasket"
								:key="orderKey"
							>
								<div class="shop-modal-addon__category-wrapper">
									<span>
										Order Number: #{{ sortedProductAddon[orderKey].reference['reference'] }}
									</span>

									<br />
									<br />

									<template v-if="order['addon_flag']">
										<span class="shop-modal-addon__warning">{{ $t(`addon.changedorder`) }}</span>
									</template>

									<div v-for="(item, key) in order" :key="key">
										<div v-if="item.length" class="shop-modal-addon__category">
											<Grid :fullWidth="true">
												<Column :s="9" :m="6" noMargin>
													<h3 class="shop-modal-addon__title">
														{{ reformateBusTransferTitle(key) | UCFirst }}
													</h3>
												</Column>

												<Column :sHide="true" :m="2">
													<h3 class="shop-modal-addon__title">#</h3>
												</Column>

												<Column :sHide="true" :m="2">
													<h3 class="shop-modal-addon__title">Price</h3>
												</Column>

												<Column :s="3" :m="2">
													<h3 class="shop-modal-addon__title">Total</h3>
												</Column>
											</Grid>

											<AddonCartItem
												v-for="(data, index) in item"
												ref="prefillitem"
												:key="index"
												:data="{
													data: data,
													key: key,
													sortedProductAddon: sortedProductAddon[index]
												}"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-else>
							<label class="shop-modal-addon__reference-label" for="orderReference">
								{{ $t(`addon.referenceLabel`) }}*
							</label>

							<Input
								class="shop-modal-addon__reference"
								type="text"
								id="orderReference"
								v-model="orderId"
							/>

							<span
								class="shop-modal-addon__message"
								v-if="lookUpState == 'failed' || lookUpState == 'invalid' || lookUpState == 'no-order'"
							>
								{{ $t(`addon.lookup.${lookUpState}`) }}
							</span>

							<Button
								class="shop-modal-addon__button"
								@click.native="rebootShopWithOrderId"
								:regular="true"
							>
								<span v-if="lookUpState != 'running'"> {{ $t('label.find_order') }} </span>

								<Spinner v-else />
							</Button>
						</div>
					</div>

					<div class="shop-modal-addon__wrapper shop-modal-addon__wrapper--center" v-else>
						<br />

						<br />

						<Spinner />
					</div>

					<Button
						class="shop-modal-addon__button shop-modal-addon__button--right"
						is-orange
						:regular="true"
						@click.native="closeModal"
						v-if="sortedProductAddon.length > 0"
					>
						<span> {{ $t('label.complete_your_experience') }} </span>
					</Button>
				</Column>
			</Grid>
		</div>
	</transition>
</template>

<script>
import AddonCartItem from '@/components/ui/AddonCartItem';
import WhishListTotalOverview from '@/components/ui/WhishListTotalOverview';
import Input from '@/components/ui/filter/Input';

export default {
	name: 'ShopModalAddon',

	data() {
		return {
			delayed: false,
			allSoldOut: true,
			orderId: '',
			validation: {}
		};
	},

	components: {
		AddonCartItem,
		WhishListTotalOverview,
		Input
	},

	computed: {
		cartCopy() {
			if (this.delayed) {
				if (this.sortedProductAddon.length != 0) {
					return {
						title: this.$t('page.shop_addon.title'),
						body: this.$t('page.shop_addon.lead'),
						key: 1
					};
				} else {
					return {
						title: this.$t('page.shop_addon.no_order.title'),
						body: this.$t('page.shop_addon.no_order.lead'),
						key: 2
					};
				}
			} else {
				return {
					title: this.$t('page.shop_addon.loading.title'),
					body: this.$t('page.shop_addon.loading.lead'),
					key: 3
				};
			}
		},

		validationCopy() {
			return {};
		},

		sortedProductAddon() {
			const originalOrders = this.purchasedProductData && this.purchasedProductData['original_orders'];
			const upsellData = this.purchasedProductData && this.purchasedProductData['upsell'];
			const dataArr = [];

			this.allSoldOut = true;
			// Add products
			!!this.shopProducts &&
				!!originalOrders &&
				originalOrders.forEach((order, orderN) => {
					const data = {
						reference: {
							reference: order['reference'],
							external_reference: order['external_reference'],
							consumer: order['consumer']
						}
					};

					Object.keys(this.shopProducts).forEach(key => {
						/*
						 * Exclude the ticket_init it is only used to map the correct data to extras and tickets
						 */
						if (key != 'ticket_init') {
							const itemData = [];

							const { purchases } = order;
							purchases &&
								purchases.forEach(purchasedItem => {
									const product = this.shopProducts[key].find(item => item.id === purchasedItem.id);

									if (product) {
										itemData.push({
											numproducts: purchasedItem.quantity,
											type: product
										});
									} else {
										if (key === purchasedItem.type) {
											const product = this.$store.getters['shopapi/getRawProducts'].find(
												item => item.id === purchasedItem.id
											);
											if (product) {
												itemData.push({
													numproducts: purchasedItem.quantity,
													type: product
												});
											} else {
												itemData.push({
													numproducts: purchasedItem.quantity,
													type: {
														name: purchasedItem.title,
														price: purchasedItem.price,
														id: purchasedItem.id,
														type: purchasedItem.type,
														categories: [{ en: '', nl: '' }]
													}
												});
											}
										}
									}
								});
							if (itemData.length) {
								data[key] = itemData;
							}
							/*
							 * This is needed for the user only, the order fee is not specified anywhere else in the API.
							 * So we need to show the user the price of an booking.
							 * No other logic is needed. The price is already accounted for by the API.
							 */
							if (
								key === 'transportation' &&
								data.transportation &&
								data.transportation[0].type.id.split('_')[0] != 'paylogic'
							) {
								itemData.push({
									numproducts: 1,
									type: {
										name: 'Order fee',
										price: 5,
										slug: 'orderfee'
									}
								});
							}
						}
					});
					// Add travel products
					if (originalOrders) {
						data.travel = order.purchases
							.filter(purchase => purchase.title.toLowerCase().search('travel:') !== -1)
							.map(item => {
								let newTitle = item.title;
								return {
									...item,
									title: newTitle.split('(').slice(0, 2).join('(').split('Travel:').join('').trim()
								};
							});
						data.insurance = order.purchases.filter(
							purchase => purchase.id.toLowerCase().search('insurance') !== -1
						);
						data.addons = order.purchases.filter(
							purchase => purchase.id.toLowerCase().search('addon_') !== -1
						);

						if (upsellData) {
							const upsellForOrder =
								upsellData.hospitality['add_on_available'] &&
								upsellData.hospitality['add_on'].find(addon => addon.reference === order['reference']);
							upsellForOrder && upsellForOrder.orderIndexNumber != 1 && (data['addon_flag'] = true);
						}
					}
					console.log('add on ', data);
					dataArr.push(data);
				});
			return dataArr;
		},

		sortedProductBasket() {
			/*
			 * Remove the travel tickets from the ticket array
			 * The price is added to the travelproduct
			 */
			const sorted = [];
			// console.log('addon', this.sortedProductAddon);
			this.sortedProductAddon.forEach(product => {
				let ticketsWithOutTravelTypes = product.ticket || null;
				if (product.ticket) {
					ticketsWithOutTravelTypes = product.ticket.filter(item => {
						const tickets =
							item.type.categories &&
							!item.type.categories.find(
								ticket => ticket.en === 'TRAVEL TICKETS' || ticket.nl === 'TRAVEL TICKETS'
							);
						return tickets;
					});
				}
				console.log(ticketsWithOutTravelTypes, product.ticket);
				let tempSorted = {
					ticket: ticketsWithOutTravelTypes,
					travel: product.travel || null,
					transportation: product.transportation || null,
					comparetransport: product.comparetransport || null,
					merchandise: product.merchandise || null,
					extras: product.extras || null,
					insurance: product.insurance || null,
					addons: product.addons || null,
					addon_flag: product.addon_flag || false
				};
				// console.log('help me:',tempSorted.insurance)
				/*
				 * Remove all falsy values, ( "", 0, false, null, undefined )
				 * Magic :-)
				 */
				sorted.push(Object.entries(tempSorted).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {}));
			});
			// console.log('sorted', sorted);
			return sorted;
		},

		purchasedProductData() {
			return (
				this.$store.getters['shopapi/getPurchasedProducts'] &&
				this.$store.getters['shopapi/getPurchasedProducts'].data.data
			);
		},

		shopProducts() {
			return this.$store.getters['shopapi/getProducts'];
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		isAddonShop() {
			if (this.shopConfig) {
				return this.shopConfig.shopInit && this.shopConfig.shopInit.isAddOnShop;
				// return this.shopConfig.shopInit && true
			} else {
				return false;
			}
		},

		showModal() {
			return this.isAddonShop && this.$store.getters['getModalPrefillCartActive'];
		},

		isWishlistMode() {
			return this.shopConfig && this.shopConfig.preregistration;
		},

		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		},

		travelCart() {
			return this.$store.getters['shopconfig/getTravelCart'];
		},

		basketHasProducts() {
			return Object.keys(this.productsInBasket)[0];
		},

		showDetailedServiceFees() {
			return this.shopConfig.showDetailedServiceFees;
		},

		lookUpState() {
			return this.$store.getters['getLookUpState'];
		},
		shopRoutingFlow() {
			return this.$store.getters['getShopRoutingFlow'];
		}
	},

	watch: {
		purchasedProductData(val) {
			// console.log(val)
		},
		showModal(val) {
			if (val && !this.delayed) {
				this.$store.commit('setShowCheckout', true);

				/*
				 * Reduce API load
				 */
				const timeOut = process.env.ENV === 'dev' ? 0 : Math.floor(Math.random() * 10 + 1) * 2000;

				setTimeout(() => {
					this.delayTheWishList();
				}, timeOut);
			}
		}
	},

	methods: {
		addAll() {
			/* this.$refs.prefillitem.forEach(item => {
				if (item.data.key === 'travel') {
					item.loadTravel();
					return;
				}

				item.addToCart();
			}); */
		},

		reformateBusTransferTitle(key) {
			key = key === 'transportation' ? this.$t('page.shop.experience_builder.bus.label') : key;
			key = key === 'travel' ? this.$t('page.shop.experience_builder.travel.label') : key;
			key = key === 'comparetransport' ? this.$t('page.shop.experience_builder.transportation.label') : key;
			return key === 'extras' ? this.$t('page.shop.experience_builder.extras.label') : key;
		},

		closeModal() {
			this.$store.commit('setModalPrefillCartActive', false);
			this.$store.commit('setShowCheckout', false);
		},

		delayTheWishList() {
			this.delayed = true;
		},

		expandCart() {
			this.$store.commit('setModalPrefillCartActive', false);
			this.$store.commit('setShowCheckout', true);
		},

		rebootShopWithOrderId() {
			if (!!this.orderId) {
				const str = this.orderId.value.trim().replace('#', '');

				let referenceObj = {};

				// Check if input matches BrazeID or Paylogic ID
				const regexBID =
					/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
				const regexPL = /^[0-9]{9}$/;

				if (regexBID.test(str)) {
					referenceObj['consumer_external_reference'] = str;
				} else if (regexPL.test(str)) {
					referenceObj['reference'] = str;
				} else {
					this.$store.commit('setLookUpState', 'invalid');
				}

				if (referenceObj['reference'] || referenceObj['consumer_external_reference']) {
					this.$store.commit('setLookUpState', 'running');
					this.$store
						.dispatch(`getOrder`, {
							namespace: this.shopConfig.apiNamespace,
							reference: referenceObj,
							cartToken: this.shopConfig.shopToken
						})
						.then(response => {
							let error = !!response;
							this.$store.commit('shopapi/setPurchasedProducts', response);
							// Check for faulty response
							if (!error) {
								this.$store.commit('setLookUpState', 'failed');
								this.$store.commit('shopapi/setHasPreviousOrder', !response);
								// console.log('failed order request')
							} else {
								// Check if original_orders has items
								if (!!response.data.data['original_orders'][0]) {
									this.$store.commit('setLookUpState', 'success');
									this.$store.commit('shopapi/setHasPreviousOrder', !!response);
									this.$store.commit('shopapi/setPurchasedProducts', response);

									this.$store.commit('setReadytoShop', true);

									this.$store
										.dispatch(`getShopproducts`, {
											namespace: this.shopConfig.apiNamespace,
											cartToken: this.shopConfig.shopToken
										})
										.then(response => {
											this.$store.commit('shopapi/setProducts', response.data.data);
										});

									if (this.shopConfig.noCookieNeeded) {
										const option =
											this.shopConfig.salesOptions[0].option === 'ticket'
												? 'tickets'
												: this.shopConfig.salesOptions[0].option;
										// console.log(this.shopConfig.salesOptions,option)
										if (
											!response.data.data['upsell'].hospitality['add_on_available'] &&
											option === 'traveladdon'
										) {
											// console.log('toTravel')
											const filteredOptions = this.shopConfig.salesOptions.filter(
												optionOjb => optionOjb.option !== 'traveladdon'
											);
											if (this.$route.params && this.$route.params.slug) {
												this.$router.push(
													`/${this.$route.params.slug}/${filteredOptions[0].option}`
												);
											}
										} else {
											// console.log('toTravelAddon')
											if (this.$route.params && this.$route.params.slug) {
												this.$router.push(`/${this.$route.params.slug}/${option}`);
											}
										}
									}

									// console.log('successful order request')
								} else {
									this.$store.commit('setLookUpState', 'no-order');
									// console.log('no order found')
								}
							}
						});
				}
			} else {
				this.$store.commit('setLookUpState', 'invalid');
				// console.log('invalid order reference')
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'shop-modal-addon';

.#{$component} {
	display: block;

	position: fixed;
	z-index: $layer-checkout;

	width: 100vw;
	height: 100vh;

	overflow-y: scroll;
	overflow-x: hidden;

	background: var(--content-background);

	top: 0;
	left: 0;

	z-index: 1000;

	&--animated {
		&.v-enter,
		&.v-leave-to {
			opacity: 0;

			.#{$component}__header,
			.#{$component}__inner {
				transform: translateY(20%);
			}
		}

		&.v-enter-active {
			transition: opacity 0.4s $ease-in-out-sine;

			.#{$component}__header,
			.#{$component}__inner {
				transition: transform 0.5s $ease-out-cubic;
			}
		}

		&.v-leave-active {
			transition: opacity 0.4s $ease-in-out-sine;

			.#{$component}__header,
			.#{$component}__inner {
				transition: transform 0.5s $ease-in-cubic;
			}
		}
	}

	&__category {
		padding: 0 10px 40px;
	}

	// &__category-wrapper {

	// }

	&__header {
		position: fixed;

		width: 100%;
		height: 167px;

		z-index: 10;
	}

	&__header-wrapper {
		position: relative;

		display: flex;
		justify-content: space-between;
		align-items: center;

		width: calc(100% - #{$global-spacing * 4 + 5px}); // 5px scrollbar compensation
		max-width: calc(1180px - 80px);
		left: -3px;
		height: 100%;

		margin: 0 auto;
		padding: 20px 45px;

		background-color: var(--content-background-light);
	}

	&__header-copy {
		flex: auto;
		padding-right: 5px;
	}

	&__body {
		padding-top: 20px;
		font-size: 12px;
		max-width: 75%;
	}

	&__message {
		display: block;
		font-size: 12px;
		max-width: 75%;
	}

	&__close {
		position: relative;
		right: 0;
	}

	&__title {
		font-family: $font-regular;
		font-size: 20px;

		padding: 0 0 15px;
	}

	&__wrapper {
		position: relative;
		// margin-top: calc(167px + 2px);
		margin-top: 180px;

		width: 100%;

		&--center {
			text-align: center;
		}
	}
	&__warning {
		display: block;
		padding: 1rem 2rem;
		margin-bottom: 2rem;
		font-family: $font-regular;
		font-size: 14px;
		background-color: var(--content-background-light);
	}

	&__button {
		cursor: pointer;
		max-width: 165px;

		&:not(:last-of-type) {
			margin-right: 10px;
		}

		.spinner {
			position: absolute;
			top: calc(50% + 0.25rem);
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__button-wrapper {
		text-align: right;
	}

	&__reference {
		display: inline-block;
		width: 100%;
		max-width: 30rem;
	}

	&__reference-label {
		display: inline-block;
		width: 100%;
	}

	&__button {
		display: block;
		margin-top: 1rem;
		max-width: 10rem;
		&--right {
			margin-left: auto;
			max-width: 17.5rem;
		}
	}

	@include sm {
		&__body {
			padding-top: 20px;
			font-size: 12px;
			max-width: 100%;
		}

		&--has-couter {
			margin-top: 40px;
		}

		&__category {
			padding: 0 5px 40px;
		}

		&__header {
			position: relative;
			height: auto;
		}

		&__wrapper {
			margin-top: 0;
		}

		&__header-wrapper {
			width: calc(100% - (20px + 5px));
			padding: 20px 20px;
			align-items: flex-start;
		}

		&__category-wrapper {
			padding-top: 20px;
		}
		&__reference-label {
			margin-top: 2rem;
		}
		&__reference {
			max-width: 100%;
		}
		&__button {
			max-width: 100%;

			&:not(:last-of-type) {
				margin-bottom: 20px;
			}
		}
	}
}
</style>
