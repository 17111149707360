<template>
	<transition name="fade">
		<div class="counter" :class="{ 'counter--hurry': hurryUp }" v-if="showCounter">
			<Grid v-if="cartData && cartData.timeout" :fluid="true">
				<Column :noMargin="true">
					<div class="counter__content">
						<div class="counter__wrapper">
							<Icon-time />

							<span class="counter__time">{{ timeLeft.time }}</span>
						</div>

						<span class="counter__copy">{{ timerCopy }}</span>
					</div>
				</Column>
			</Grid>
		</div>
	</transition>
</template>

<script>
import moment from 'moment';
import preserveUTMParams from '@/utils/preserveUTMParams';

export default {
	name: 'Counter',

	data() {
		return {
			timeLeft: 0,
			currentDate: null,
			isCounting: false,
			hurryUp: false,
			timerCopy: ''
		};
	},

	computed: {
		cartData() {
			return this.$store.getters['shopconfig/getCart'];
		},

		cartStatus() {
			return !!this.cartData && this.cartData.status;
		},

		cartTimeOut() {
			return moment(new Date()).add(this.cartData.timeout, 'seconds').unix();
			// return moment(new Date).add(10, 'seconds').unix();
		},

		showCounter() {
			return this.$store.getters.getShowCounter;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		hasUpgradeModule() {
			return this.$store.getters['shopconfig/getHasUpgradeModule'];
		}
	},

	watch: {
		cartData(val) {
			if (val && val.timeout && !this.isCounting) {
				// console.log(this.cartTimeOut);
				this.currentDate = this.cartTimeOut;
				this.isCounting = true;

				this.cartCountDown();
			}
			if (val && val.timeout <= 0) {
				this.$router.push(`/${this.$route.params.slug}`);
			}
		},

		cartStatus(val) {
			if (val === 'confirmed' || val === 'in_payment') {
				this.timerCopy = this.$t('alert.complete_payment_in_time');
				this.currentDate = moment(new Date()).add(this.cartData.timeout, 'seconds').unix();
			}
		},
		getPaymentProvider() {
			return this.$store.getters['shopconfig/getPaymentProvider'];
		}
	},

	methods: {
		cartCountDown() {
			const diff = this.currentDate - moment().unix();

			let minutes = Math.floor(diff / 60);
			let seconds = diff - minutes * 60;

			this.timeLeft = {
				diff: diff,
				time: `${minutes.toString().padStart(2, '0')} ${this.$tc('time.minute', minutes)}, ${seconds
					.toString()
					.padStart(2, '0')} ${this.$tc('time.second', seconds)} ${this.$t('remaining')}`
			};
			// console.log(diff, this.currentDate);
			if (diff <= 420 && !this.showCounter) {
				// Show when 7 minutes left
				this.$store.commit('setShowCounter', true);
			}

			if (diff <= 200 && !this.hurryUp) {
				this.hurryUp = true;
			}

			if (diff <= 0) {
				if (this.hasUpgradeModule) {
					//User needs an orderId so shop can only be accessed from link in email
					this.$fireErrorEventWithPromis(4004);
				} else {
					//SHOW POPUP REDIRECT TO QUEUE
					this.$fireErrorEventWithPromis(6002).then(response => {
						this.$cookies.remove('shop_deeplink');

						if (this.shopConfig.queueUrl) {
							window.location.replace(preserveUTMParams(this.shopConfig.queueUrl));
						} else {
							window.location.replace(
								preserveUTMParams(`${window.location.origin}${window.location.pathname}`)
							);
						}
						this.$store.commit('shopconfig/setResetShop');
						this.$store.commit('shopapi/setResetShop');
						this.$store.commit('setShowCounter', false);
					});
				}

				this.$store.commit('setShowCounter', false);
			} else {
				setTimeout(() => {
					this.cartCountDown();
				}, 1000);
			}
		}
	},

	mounted() {
		if (this.cartData && this.cartData.timeout <= 0) {
			this.$router.push(`/${this.$route.params.slug}`);
		}
		this.timerCopy = this.$t('alert.complete_order_in_time');
	}
};
</script>

<style lang="scss">
$component: 'counter';

.#{$component} {
	position: fixed;
	background: $color-green;
	border: 2px solid $color-black;

	top: 0;
	left: 0;

	width: 100%;

	z-index: 999;

	&--hurry {
		background: $color-red;
	}

	&__content {
		font-family: $font-medium;
		font-size: 14px;
		line-height: 1;
		color: $color-white;

		display: flex;
		justify-content: space-between;
		align-items: center;

		width: 100%;
		height: $counter-height;
	}
	&__copy {
		display: block;
	}

	&__time {
		display: block;
		font-family: $font-medium-italic;
		padding-left: 14px;
	}

	&__wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		> svg {
			height: 20px;
		}
	}

	@include sm {
		&__copy {
			display: none;
		}
	}
}
</style>
