var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDisabled)?_c('button',{staticClass:"button-small button-small--disabled",class:{
		'button-small--orange': _vm.isOrange,
		'button-small--extra': _vm.isExtraSmall
	},attrs:{"target":"_blank","disabled":""}},[_c('div',[_vm._t("default")],2)]):(_vm.to)?_c('nuxt-link',{staticClass:"button-small",class:{
		'button-small--orange': _vm.isOrange,
		'button-small--extra': _vm.isExtraSmall
	},attrs:{"to":{ path: _vm.route, query: _vm.$route.query }}},[_vm._t("default")],2):(_vm.external)?_c('a',{staticClass:"button-small",class:{
		'button-small--orange': _vm.isOrange,
		'button-small--extra': _vm.isExtraSmall
	},attrs:{"href":_vm.external,"target":"_blank"}},[_vm._t("default")],2):_c('button',{staticClass:"button-small",class:{
		'button-small--orange': _vm.isOrange,
		'button-small--extra': _vm.isExtraSmall
	},attrs:{"target":"_blank"}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }