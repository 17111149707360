<template>
	<section class="error-page">
		<div class="error-page__content">
			<h1 v-if="error.statusCode === 404">{{ $t('error.page_not_found') }}</h1>
			<h1 v-else-if="error.message" v-html="error.message"></h1>
			<h1 v-else>{{ $t('error.undefined') }}</h1>
			<br />
			<a href="/">{{ $t('label.return_to_homepage') }}</a>
		</div>
	</section>
</template>

<script>
export default {
	props: ['error'] //eslint-disable-line
};
</script>

<style lang="scss">
$component: 'error-page';

.#{$component} {
	display: flex;

	justify-content: center;
	align-content: center;

	width: 100%;
	height: calc(100vh - 75px);

	&__content {
		text-align: center;

		height: 100px;
	}
}
</style>
