export default {
	mounted() {
		document.documentElement.classList.add('no-touchevents'); // Add class here and not through nuxt-config. Otherwise class gets added on every navigation.

		const elm = typeof window !== 'undefined' ? window : document.body;

		elm.addEventListener(
			'touchstart',
			function onFirstTouch() {
				this.$store.commit('setIsTouch', true);
				document.documentElement.classList.remove('no-touchevents');
				document.documentElement.classList.add('touchevents');
			}.bind(this),
			{ once: true }
		);
	}
};
