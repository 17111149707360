import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

import Grid from '@/components/layout/Grid';
import Column from '@/components/layout/Column';
import Page from '@/components/layout/Page';
import Button from '@/components/ui/Button';
import ButtonSmall from '@/components/ui/ButtonSmall';
import Spinner from '@/components/ui/Spinner';

Vue.component('Grid', Grid);
Vue.component('Column', Column);
Vue.component('Page', Page);
Vue.component('Button', Button);
Vue.component('ButtonSmall', ButtonSmall);
Vue.component('Spinner', Spinner);

const requireComponent = require.context(
	// The relative path of the components folder
	'../components/svg',
	// Whether or not to look in subfolders
	false,
	/[\w-]+.vue$/
);

requireComponent.keys().forEach(fileName => {
	const componentConfig = requireComponent(fileName);

	const componentName = upperFirst(
		camelCase(
			fileName
				.split('/')
				.pop()
				.replace(/\.\w+$/, '')
		)
	);

	Vue.component(componentName, componentConfig.default || componentConfig);
});
