import normalizeWheel from 'normalize-wheel';
import { TweenLite, Cubic } from 'gsap';

export default {
	install(Vue, options) {
		options = options || {};

		const $eventBus = new Vue();
		const scrollObj = {
			scrollPosition: 0
		};
		let nextScrollY = 0;
		let maxScroll = 0;

		if (typeof window !== 'undefined') {
			window.addEventListener('scroll', () => {
				// Use undefined check because (window.scrollY || window.scrollTop) uses the last option when window.scrollY === 0
				// const scrollY = typeof(window.scrollY) !== 'undefined' ? window.scrollY : window.scrollTop;
				// $eventBus.$emit('scroll', typeof(scrollY) !== 'undefined' ? scrollY : document.documentElement.scrollTop);  // IE check

				const scrollY = window.scrollY || window.pageYOffset;

				$eventBus.$emit('scroll', scrollY);
			});

			window.addEventListener('resize', () => {
				maxScroll = document.body.clientHeight - window.innerHeight;

				$eventBus.$emit('resize', {
					width: window.innerWidth,
					height: window.innerHeight
				});
			});

			// window.addEventListener((/Firefox/i.test(navigator.userAgent)) ? 'DOMMouseScroll' : 'mousewheel', (e) => {

			//     e.preventDefault();

			//     const pixelY = normalizeWheel(e).pixelY;
			//     maxScroll = document.body.clientHeight - window.innerHeight;
			//     nextScrollY = Math.max(0, Math.min(nextScrollY + pixelY, maxScroll));

			//     $eventBus.$emit('scrollStart');

			//     TweenLite.to(scrollObj, 1, {
			//         scrollPosition: nextScrollY,
			//         ease: Cubic.easeOut,
			//         onUpdate: () => {

			//             window.scroll(0, scrollObj.scrollPosition);

			//         },
			//         onComplete: () => {

			//             $eventBus.$emit('scrollEnd');

			//         }
			//     });

			// }, { passive: false });

			Vue.prototype.$scrollContainer = window;
		}

		Vue.mixin({
			data() {
				return {
					scrollPosition: 0,
					isScrolling: false,
					viewport: {
						width: 0,
						height: 0
					},
					breakpoints: {
						medium: 768,
						large: 1024
					}
				};
			},

			computed: {
				isSmallScreen() {
					return this.viewport.width < this.breakpoints.medium;
				},

				isMediumScreen() {
					return (
						this.viewport.width >= this.breakpoints.medium && this.viewport.width < this.breakpoints.large
					);
				}
			},

			watch: {
				$route() {
					this.scrollPosition = scrollObj.scrollPosition = nextScrollY = 0;
				}
			},

			methods: {
				activateVueScroll() {
					$eventBus.$on('scroll', this.onScroll);
					$eventBus.$on('resize', this.onResize);
					$eventBus.$on('scrollStart', this.onScrollStart);
					$eventBus.$on('scrollEnd', this.onScrollEnd);

					this.$scrollContainer.dispatchEvent(new Event('scroll'));
					window.dispatchEvent(new Event('resize'));
				},

				deactivateVueScroll() {
					$eventBus.$off('scroll', this.onScroll);
					$eventBus.$off('resize', this.onResize);
					$eventBus.$off('scrollStart', this.onScrollStart);
					$eventBus.$off('scrollEnd', this.onScrollEnd);
				},

				scrollTo(pos, duration = 1, ease) {
					const scrollObj = {
						current: this.scrollPosition
					};

					ease = ease || Cubic.easeInOut;

					TweenLite.to(scrollObj, duration, {
						current: pos,
						ease,
						onUpdate: () => {
							this.$scrollContainer.scrollTo(0, scrollObj.current);
						}
					});
				},

				onScroll(scrollPosition) {
					this.scrollPosition = scrollPosition;
				},

				onResize(viewport) {
					this.viewport = viewport;
				},

				onScrollStart() {
					this.isScrolling = true;
				},

				onScrollEnd() {
					this.isScrolling = false;
				}
			},

			mounted() {
				this.activateVueScroll();
			},

			beforeDestroy() {
				this.deactivateVueScroll();
			}
		});
	}
};
