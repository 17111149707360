<template>
	<div class="checkbox">
		<input class="checkbox__option" type="checkbox" :id="data.value" :checked="value" @change="change" />

		<label class="checkbox__option-label" :for="data.value">
			<slot />
		</label>
	</div>
</template>

<script>
export default {
	name: 'Checkbox',

	props: ['value', 'data'],

	methods: {
		change(event) {
			this.$emit('input', event.target.checked);
		}
	}
};
</script>

<style lang="scss">
$component: 'checkbox';

.#{$component} {
	display: flex;

	width: 100%;

	margin-bottom: 16px;

	&__option {
		position: absolute;

		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;

		&:checked,
		&--checked {
			visibility: visible;
			opacity: 1;
		}

		&--checked + label,
		&:checked + label {
			color: var(--primary-body-color);

			&:before {
				display: block;
				background-color: rgba(var(--primary-color), 1);
				box-shadow: inset 0px 0px 0px 6px $color-black;
			}
		}
	}

	&__option-label {
		cursor: pointer;
		position: relative;

		display: flex;
		justify-content: flex-start;

		width: 100%;
		height: 100%;

		font-size: 16px;

		color: var(--primary-body-color);
		text-transform: capitalize;

		&:before {
			content: '';

			flex-shrink: 0;

			position: relative;
			display: block;

			width: 24px;
			height: 24px;
			top: 1px;
			left: 0;

			margin-right: 11px;

			background-color: rgba(var(--primary-color), 0);
			border: 1px solid var(--primary-body-color);
			border-radius: 4px;

			transition: all 0.5s $ease-in-out-cubic;
		}
	}
}
</style>
