<template>
	<div>
		<!-- <ShopStatus /> -->

		<ShopModal />

		<UpsellNotification v-if="shopCart && shopConfig && shopConfig.upsell === 'Yes'" />

		<transition name="fade">
			<ShowMessageForMissingProduct />
		</transition>

		<Counter v-if="shopConfig && !shopConfig.preregistration" v-show="showCounter" />

		<Header />

		<main id="main" class="main" v-if="ssoLoaded || !isSsoAvailable">
			<template v-if="shopConfig">
				<NavBar ref="navBar" v-show="showNavBar && shopConfig.stepflow === 'Yes'" />
			</template>

			<CheckOut />

			<ShopModalfillcart />

			<ShopModalAddon />

			<SaveTimeModal />

			<!-- <CartFooter v-if="showCart" /> -->

			<!--<Hero
				class="main__hero"
				:class="{ 'md-lg-only': brand !== 'thunderdome', 'main__hero--thunderdome': brand === 'thunderdome' }"
			/>-->

			<Hero class="main__hero" />

			<nuxt />
		</main>

		<div ref="sso"></div>

		<Footer />
	</div>
</template>

<script>
import CheckOut from '@/components/shop/CheckOut';
import Counter from '@/components/shop/Counter';
import ShopModal from '@/components/shop/ShopModal';
import ShopModalfillcart from '@/components/shop/ShopModalfillcart';
import ShopModalAddon from '@/components/shop/ShopModalAddon';
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import Hero from '@/components/layout/Hero';
import ShopStatus from '@/components/shop/ShopStatus';
import NavBar from '@/components/ui/NavBar';

import UpsellNotification from '@/components/shop/UpsellNotification';
import ShowMessageForMissingProduct from '@/components/shop/ShowMessageForMissingProduct';

import DetectTouchMixin from '@/mixins/DetectTouchMixin';
import ShopAuthenticationWatcher from '@/mixins/ShopAuthenticationWatcher.js';
import TransportationItem from '~/components/transportation/TransportationItem.vue';
import SaveTimeModal from '@/components/ui/SaveTimeModal';

export default {
	name: 'DefaultLayout',

	data() {
		return {
			ssoLoaded: false,
			addedProductWithMissingProduct: false
		};
	},

	head() {
		let head = {
			bodyAttrs: {
				class: this.brand
			}
		};

		if (
			!!this.shopConfig?.shopSettings?.fields?.vwo &&
			this.shopConfig?.shopSettings?.fields?.vwo?.fields?.isEnabled
		) {
			head = {
				...head,
				link: [{ rel: 'preconnect', href: 'https://dev.visualwebsiteoptimizer.com' }],
				script: [
					{
						id: 'vwoCode',
						src: '/js/vwo-smartcode.js',
						'data-account-id': this.shopConfig.shopSettings.fields.vwo.fields.accountId,
						type: 'text/javascript',
						body: false
					}
				]
			};
		}

		return head;
	},

	components: {
		CheckOut,
		// CartFooter,
		Hero,
		Header,
		Footer,
		Counter,
		ShopModal,
		ShopModalfillcart,
		ShopModalAddon,
		ShopStatus,
		NavBar,
		UpsellNotification,
		ShowMessageForMissingProduct,
		SaveTimeModal
	},

	mixins: [DetectTouchMixin, ShopAuthenticationWatcher],

	computed: {
		isSsoAvailable() {
			return this.$store.getters['sso/getIsSsoAvailable'];
		},

		ossConfig() {
			return this.$store.getters.getOssConfig;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'] || null;
		},

		customTranslations() {
			const shopTranslations = this.$store.getters['shopconfig/getCustomTranslations'];
			const ossTranslations = this.$store.getters.getCustomTranslations;
			const customTranslations = Object.assign({}, ossTranslations, shopTranslations);

			return customTranslations;
		},

		locale() {
			return this.$store.getters.getLocale;
		},

		brand() {
			return this.$store.getters.getBrand;
		},

		brandLogo() {
			return this.$store.getters.getBrandLogo;
		},

		showCounter() {
			return this.$store.getters.getShowCounter;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		showCart() {
			return !!this.shopCart;
		},

		cartErrors() {
			return !!this.shopCart && this.shopCart.checkoutErrors;
		},

		userAlreadyPlacedOrder() {
			return (
				!!this.cartErrors &&
				this.cartErrors.length &&
				!!this.cartErrors.find(error => error.code === 'validation.max.orders.per.account')
			);
		},

		showNavBar() {
			return this.shopConfig && !['index', 'slug', 'slug-paymentstatus'].includes(this.$route.name);
		},
		productsInBasket() {
			return this.$store.getters['shopapi/getMappedProductsInBasket'];
		}

		/* upgradeOrderId() {
			return this.$store.getters.getUpgradeOrderId;
		} */
	},

	watch: {
		isUserLoggedIn(val, oldVal) {
			if (!val) {
				this.$store.commit('sso/setMembershipStatus', 0);

				if (oldVal && this.shopConfig) {
					if (
						this.shopConfig.membersale ||
						this.shopConfig.preregistration ||
						this.shopConfig.travelsale ||
						this.shopConfig.loginRequired ||
						this.shopConfig.membershipRequired
					) {
						this.$store.commit('shopconfig/setResetShop');
						this.$store.commit('shopapi/setResetShop');
						this.$store.commit('setShowCounter', false);

						this.$router.push('/');
					}
				}
			}

			if (val) {
				this.setXuserToken();
				this.loadMembershipStatus();
			} else {
				this.setXuserToken();
			}
		},

		cartErrors(val) {
			const userAlreadyPlacedOrderError =
				!!val && val.length && val.find(error => error.code === 'validation.max.orders.per.account');

			if (!!userAlreadyPlacedOrderError) {
				this.$store.commit('setModalMessage', userAlreadyPlacedOrderError.message); // message that gets shown by 8000 error modal
				this.$fireErrorEvent(8001);
			}
		},

		shopCart() {
			this.$store.commit('setIsWishlistSaved', false);
		},

		$route(to, from) {
			if (to.query.lang && to.query.lang === 'debug') {
				console.info('Switching to i18n debug mode');
				this.$i18n.setLocale('debug');
				this.$i18n.fallbackLocale = 'debug';
			}

			// Removing query parameters usually goes well
			// but in the case of internally loosing query parameter set language to default
			if (from.query.lang && from.query.lang === 'debug' && to.query.lang === 'undefined') {
				this.setLocale();
			}

			this.$nextTick(() => {
				const hasTravel = this.productsInBasket.hasOwnProperty('travel');

				if (from.name === 'slug' && hasTravel) {
					const url = this.$store.getters.getFromTravelDetailPath;
					this.$refs.navBar.setTravelSlugPath(url);
				}
				if (from.name === 'slug' && !hasTravel) {
					this.$store.commit('setFromTravelDetailPath', null);
					this.$refs.navBar.setTravelSlugPath(null);
				}
			});
		}
	},

	methods: {
		getCookie(name) {
			return document.cookie.split('; ').reduce((r, v) => {
				const parts = v.split('=');
				return parts[0] === name ? decodeURIComponent(parts[1]) : r;
			}, '');
		},
		setXuserToken() {
			let xusertoken = null;
			try {
				const token = controller.getAccessTokens().idToken;
				xusertoken = token;
			} catch {
				const token = this.getCookie('_id-t-accounts-previous-id-token');
				xusertoken = token;
			}
			if (xusertoken) {
				this.$axios.defaults.headers.common['x-user-token'] = xusertoken;
			}
		},

		setLocale() {
			if (this.locale?.code) {
				this.$i18n.setLocale(this.locale.code);
			}
			if (this.customTranslations && this.locale?.code) {
				this.$i18n.mergeLocaleMessage(this.locale.code, this.customTranslations);
			}
		}
	},

	created() {
		this.setLocale();
	},

	mounted() {
		this.setXuserToken();

		if (this.isUserLoggedIn) {
			this.loadMembershipStatus();
		}

		this.$store.dispatch('sso/initSSO', this.$refs.sso).then(() => {
			this.ssoLoaded = true;
		});

		// This is needed to akamai can place a cookie for analytics tracking;
		if (window.location.hostname !== 'localhost') {
			const initCallForDataLayer = this.$axios
				.get(`${window.location.origin}/api/init`)
				.then(response => {
					console.log(response);
				})
				.catch(error => {
					console.log('init error');
				});
		}
	}
};
</script>

<style lang="scss">
$component: 'main';

.#{$component} {
	height: 100%;
	// min-height: 1500px;

	padding: $nav-height + 50 0 100px;

	&__hero {
		body.checkout-open & {
			padding-right: 5px;
		}
	}

	@include sm {
		padding: $nav-height-mob 0 60px;
		overflow-x: hidden;

		body.save-time-modal-open & {
			// Your styles here
			padding: ($nav-height-mob + $save-time-modal-height--mobile) 0 60px;
		}
	}
}
</style>
